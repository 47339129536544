import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class B2bAgentService {
  private isB2BSubject = new BehaviorSubject<boolean>(false);
  public isB2B$: Observable<boolean> = this.isB2BSubject.asObservable();

  private isB2BPage = new BehaviorSubject<boolean>(false);
  public isB2BPageObservable: Observable<boolean> = this.isB2BPage.asObservable();
  constructor() { }

  setIsB2B(isB2B: boolean): void {
    this.isB2BSubject.next(isB2B);
  }

  getIsB2B(): boolean {
    return this.isB2BSubject.value;
  }

  setIsB2BPage(isB2B: boolean): void {
    this.isB2BPage.next(isB2B);
  }

  validateNameInput(event: KeyboardEvent) {
    const char = event.key;
    const regex = /^[A-Za-z\s]+$/;
    if (!regex.test(char)) {
      event.preventDefault();
    }
  }
}
