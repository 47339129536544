import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app/app.constant';
import { GetDataService } from 'src/app/core/services/get-data.service';
import { PaymentSuccessService } from 'src/app/core/services/payment-success.service';
import { VisaProcessService } from 'src/app/core/services/visa-process.service';

declare var webengage: any; // Declare the webengage variable
declare var fbq: any; // Declare the FB pixel variable
@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
})
export class PaymentSuccessComponent implements OnInit {
  @ViewChild("cleintRating", { static: true }) cleintRating!: ElementRef;
  @ViewChild("cleintRatingCanvas", { static: true }) cleintRatingCanvas!: TemplateRef<any>;
  // @ViewChild("cleintRating",{static:true}) cleintRating!:ElementRef;

  applicationNo!: string | null;
  appConstants = new AppConstants();
  ratingStars: string[] = ['star_rate', 'star_rate', 'star_rate', 'star_rate', 'star_rate',];
  curSelectedStar: number = 0;
  isRatingSubmitCmplt: boolean = false;
  feedBack: string = '';
  successDataToShow: any[] = [];
  getByData: any = [];
  interactiveBanner: any;
  primaryCustomerId: any;

  constructor(
    public router: Router,
    private activeRoute: ActivatedRoute,
    private getDataService: GetDataService,
    private paysuccService: PaymentSuccessService,
    public visaProService: VisaProcessService,
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  ngOnInit(): void {
    this.getPrimaryCustomerId()
    this.webengageTriggerSuccess();
    this.getDataFromParams();
    this.sendLink();
    this.checkScreenWidth();
    this.getSuccessData();
    this.getDetails();
    this.getInteractiveBanner();
  }

  getPrimaryCustomerId() {
    this.primaryCustomerId = localStorage.getItem('primary_customer_id');
  }

  goToMyBooking() {
    webengage.track('go to bookings', 'user clicked go to bookings');
    this.router.navigate([this.appConstants.myProfile]);
  }

  getDataFromParams() {
    this.applicationNo =
      this.activeRoute.snapshot.queryParamMap.get('application_no');
  }

  webengageTriggerSuccess() {
    if (isPlatformBrowser(this.platformId)) {
      let paymentData = localStorage.getItem('payment_price_details');
      let StrCustomerData = localStorage.getItem('customer_data');

      console.log('paymentData', paymentData);
      console.log('StrCustomerData', StrCustomerData);

      let pardDataPaymentData;
      let parsedCustomerData;
      if (paymentData) {
        pardDataPaymentData = JSON.parse(paymentData);
      }
      if (StrCustomerData) {
        parsedCustomerData = JSON.parse(StrCustomerData);
      }

      let cusmData = { ...pardDataPaymentData, ...parsedCustomerData }
      webengage.track('Payment Sucess', cusmData);

      fbq('trackCustom', 'paymentSuccess', JSON.stringify(cusmData));
    }
  }

  sendLink() {
    if (isPlatformBrowser(this.platformId)) {
      const url = localStorage.getItem('fiMoneyUrlLink')
        ? localStorage.getItem('fiMoneyUrlLink')
        : '';
      const data = {
        fi_link: url,
      };
      this.getDataService.sendFiMoneyLink(data).subscribe({
        next: (res) => { },
      });
    }
  }

  open(content: any) {
    this.modalService.open(content, { windowClass: 'rating_popup', backdrop: 'static', keyboard: false }).result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);

      },
    );
  }

  starCtrl(starNumber: number) {
    if (this.curSelectedStar == starNumber) {
      this.curSelectedStar = this.curSelectedStar - 1;
    } else {
      this.curSelectedStar = starNumber;
    }

  }

  getSuccessData() {

    const queryTo = `
      query{
        paymentBenefit{
          id
          heading
          benefits
          tags
          icon
          link
          linkText
        }
      }
    `;

    let removeCache = false;
    this.getDataService.getDataWithoutVariable(queryTo, removeCache).then((res: any) => {
      this.successDataToShow = res?.data?.paymentBenefit;
      console.log(this.successDataToShow);

    }).catch(error => {
      console.log(error);
    });
  }


  getInteractiveBanner() {

    const queryTo = `
      query{
        interactiveBanners{
          id
          interactiveBanner
          interactiveBannerMob
          interactiveBannerLink
          
        }
      }
    `;

    let removeCache = false;
    this.getDataService.getDataWithoutVariable(queryTo, removeCache).then((res: any) => {
      this.interactiveBanner = res?.data?.interactiveBanners;
      console.log(this.interactiveBanner);
    }).catch(error => {
      console.log(error);
    });
  }

  submitComplt() {
    let dataToSend = {
      "rating": this.curSelectedStar,
      "feedback": this.feedBack,
      'primary_customer_id': this.primaryCustomerId
    }
    this.paysuccService.userRatingSubmission(dataToSend).subscribe({
      next: (result: any) => {
        console.log(result);
      },
      error: (error: any) => {
        console.log(error);
        webengage.track('Feedback submitted', 'API Fail Happen');
      },
      complete: () => {
        this.isRatingSubmitCmplt = true;
        webengage.track('Feedback submitted', dataToSend);
        if (isPlatformBrowser(this.platformId)) {
          // setTimeout(() => {
          //   this.modalService.dismissAll();
          //   this.offcanvasService.dismiss();
          // }, 5000);
        }
      }

    });

  }

  getDetails() {
    if (isPlatformBrowser(this.platformId)) {
      let stringDt = localStorage.getItem('dataForSuccPage');
      let parseDt = stringDt ? JSON.parse(stringDt) : '';

      if (parseDt) {
        this.visaProService.getApplicationDetails(parseDt?.cusId, parseDt?.prodId).subscribe(
          (result: any) => {
            this.getByData = result;
          },
          error => {
            console.log(error);
          }
        )
      }
    }
  }

  parseTag(data: any) {
    return JSON.parse(data || '[]');
  }

  mblPassportDataCanvas(content: TemplateRef<any>, formData?: any) {
    this.offcanvasService.open(content, { position: 'bottom', panelClass: 'mbl_amout_auto_height' });
  }

  checkScreenWidth(): void {
    if (isPlatformBrowser(this.platformId)) {
      let isMobileScreen = window.innerWidth < 992;
      if (isMobileScreen) {
        this.mblPassportDataCanvas(this.cleintRatingCanvas);

      } else {
        this.open(this.cleintRating);
      }
    }


  }

  trackLinkUse(curntData: any) {
    webengage.track('Promotional benefit', curntData);
  }

  interacBanrTrigger(crntBanData: any) {
    webengage.track('interactive banner', crntBanData);
  }
}
