import { Routes } from '@angular/router';

export const outsideRoutes: Routes = [
   //  {
   //     "path":"visa/usa-business-visa-online",
   //     "redirectTo":"visa/usa-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"usa-student-visa-online",
   //     "redirectTo":"visa/usa-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/usa-tourist-visa-online",
   //     "redirectTo":"visa/usa-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"usa-transit-visa-online",
   //     "redirectTo":"visa/usa-transit-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/japan-business-visa-online",
   //     "redirectTo":"visa/japan-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/japan-tourist-visa-online",
   //     "redirectTo":"visa/japan-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/japan-transit-visa-online",
   //     "redirectTo":"visa/japan-transit-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-student-visa-online",
   //     "redirectTo":"visa/thailand-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-business-visa-online",
   //     "redirectTo":"visa/thailand-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-tourist-visa_online",
   //     "redirectTo":"visa/thailand-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-transit-visa_online",
   //     "redirectTo":"visa/thailand-transit-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-transit-visa-online",
   //     "redirectTo":"visa/australia-transit-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-student-visa-online",
   //     "redirectTo":"visa/australia-student-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-tourist-visa-online",
   //     "redirectTo":"visa/australia-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-business-visa-online",
   //     "redirectTo":"visa/dubai-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-transit-visa-online",
   //     "redirectTo":"visa/dubai-transit-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/uk-business-visa-online",
   //     "redirectTo":"visa/uk-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/uk-tourist-visa-online",
   //     "redirectTo":"visa/uk-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/france-business-visa-online",
   //     "redirectTo":"visa/france-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/france-tourist-visa-online",
   //     "redirectTo":"visa/france-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/japan-business-visa-online",
   //     "redirectTo":"visa/japan-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/japan-tourist-visa-online",
   //     "redirectTo":"visa/japan-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/turkey-business-visa-online",
   //     "redirectTo":"visa/turkey-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/turkey-tourist-visa-online",
   //     "redirectTo":"visa/turkey-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/malaysia-business-visa-online",
   //     "redirectTo":"visa/malaysia-business-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/malaysia-tourist-visa-online",
   //     "redirectTo":"visa/malaysia-tourist-visa-online-india",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/usa-10-years-business-visa-online",
   //     "redirectTo":"visa/united-states-business-b1-b2-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/usa-10-years-tourist-visa-online",
   //     "redirectTo":"visa/united-states-tourist-b1-b2-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-1-year-multiple-entry-business-visa-online",
   //     "redirectTo":"visa/thailand-business-evisa-30-days-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-3-months-multiple-entry-tourist-visa-online",
   //     "redirectTo":"visa/thailand-tourist-evisa-30-days-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-3-year-multiple-entry-business-visa-online",
   //     "redirectTo":"visa/thailand-business-evisa-30-days-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-3-month-single-entry-business-visa-online",
   //     "redirectTo":"visa/thailand-business-evisa-30-days-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-30-days-express-business-visa-online",
   //     "redirectTo":"visa/thailand-business-evisa-30-days-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-30-days-tourist-visa-online",
   //     "redirectTo":"visa/thailand-tourist-evisa-30-days-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/thailand-30-days-express-tourist-visa-online",
   //     "redirectTo":"visa/thailand-tourist-evisa-30-days-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-12-months-business-visa-online",
   //     "redirectTo":"visa/australia-business-12-months-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-12-month-tourist-visa-online",
   //     "redirectTo":"visa/australia-tourist-12-months-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-3-months-business-visa-online",
   //     "redirectTo":"visa/australia-business-12-months-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-3-months-tourist-visa-online",
   //     "redirectTo":"visa/australia-business-12-months-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-6-months-business-visa-online",
   //     "redirectTo":"visa/australia-6-months-business-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/australia-6-months-tourist-visa-online",
   //     "redirectTo":"visa/australia-tourist-12-months-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-14-days-multiple-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-14-days-multiple-entry-business-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-14-days-multiple-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-14-days-multiple-entry-tourist-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-14-days-multiple-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-14-days-multiple-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-14-days-multiple-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-14-days-multiple-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-14-days-single-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-business-14-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-14-days-single-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-14-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-14-days-single-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-14-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-30-days-multiple-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-30-days-multiple-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-30-days-multiple-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-30-days-multiple-entry-express-tourist-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-30-days-multiple-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-business-30-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-30-days-multiple-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-30-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-30-days-single-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-business-30-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-30-days-single-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-30-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-30-days-single-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-30-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-30-days-single-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-30-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-48-hours-transit-visa-online",
   //     "redirectTo":"visa/dubai-48-hours-transit-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"dubai-96-hours-transit-visa-online",
   //     "redirectTo":"visa/dubai-96-hours-transit-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-14-days-multiple-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-14-days-multiple-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-14-days-multiple-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-14-days-multiple-entry-business-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-14-days-multiple-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-14-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-14-days-1-adult-1-child-multiple-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-14-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-14-days-multiple-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-14-days-multiple-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-14-days-single-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-business-14-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-14-days-single-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-14-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-14-days-single-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-14-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-30-days-single-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-business-30-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-30-days-single-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-30-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-30-days-single-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-30-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-30-days-single-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-30-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-30-days-multiple-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-tourist-30-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-30-days-multiple-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-30-days-multiple-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-30-days-multiple-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-business-30-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-30-days-multiple-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-30-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-60-days-single-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-business-60-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-60-days-single-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-60-days-single-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-60-days-single-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-60-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-60-days-single-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-60-days-single-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-60-days-multiple-entry-express-business-visa-online",
   //     "redirectTo":"visa/dubai-business-60-days-multiple-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-60-days-multiple-entry-express-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-60-days-multiple-entry-express-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-60-days-multiple-entry-business-visa-online",
   //     "redirectTo":"visa/dubai-business-60-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  },
   //  {
   //     "path":"visa/dubai-family-60-days-multiple-entry-tourist-visa-online",
   //     "redirectTo":"visa/dubai-tourist-60-days-multiple-entry-visa-apply-online",
   //     "pathMatch":"full"
   //  }
   {
      "path": "visa/usa-business-visa-online",
      "redirectTo": "visa/usa-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "usa-student-visa-online",
      "redirectTo": "visa/usa-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/usa-tourist-visa-online",
      "redirectTo": "visa/usa-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "usa-transit-visa-online",
      "redirectTo": "visa/usa-transit-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/japan-business-visa-online",
      "redirectTo": "visa/japan-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/japan-tourist-visa-online",
      "redirectTo": "visa/japan-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/japan-transit-visa-online",
      "redirectTo": "visa/japan-transit-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-student-visa-online",
      "redirectTo": "visa/thailand-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-business-visa-online",
      "redirectTo": "visa/thailand-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-tourist-visa_online",
      "redirectTo": "visa/thailand-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-transit-visa_online",
      "redirectTo": "visa/thailand-transit-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-transit-visa-online",
      "redirectTo": "visa/australia-transit-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-student-visa-online",
      "redirectTo": "visa/australia-student-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-tourist-visa-online",
      "redirectTo": "visa/australia-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-business-visa-online",
      "redirectTo": "visa/dubai-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-tourist-visa-online",
      "redirectTo": "visa/dubai-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-transit-visa-online",
      "redirectTo": "visa/dubai-transit-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/uk-business-visa-online",
      "redirectTo": "visa/uk-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/uk-tourist-visa-online",
      "redirectTo": "visa/uk-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/france-business-visa-online",
      "redirectTo": "visa/france-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/france-tourist-visa-online",
      "redirectTo": "visa/france-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/japan-business-visa-online",
      "redirectTo": "visa/japan-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/japan-tourist-visa-online",
      "redirectTo": "visa/japan-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/turkey-business-visa-online",
      "redirectTo": "visa/turkey-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/turkey-tourist-visa-online",
      "redirectTo": "visa/turkey-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/malaysia-business-visa-online",
      "redirectTo": "visa/malaysia-business-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/malaysia-tourist-visa-online",
      "redirectTo": "visa/malaysia-tourist-visa-online-india",
      "pathMatch": "full"
   },
   {
      "path": "visa/usa-10-years-business-visa-online",
      "redirectTo": "visa/usa-b1-b2-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/usa-10-years-tourist-visa-online",
      "redirectTo": "visa/usa-b1-b2-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-1-year-multiple-entry-business-visa-online",
      "redirectTo": "visa/thailand-30-days-business-evisa-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-3-months-multiple-entry-tourist-visa-online",
      "redirectTo": "visa/thailand-30-days-tourist-evisa-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-3-year-multiple-entry-business-visa-online",
      "redirectTo": "visa/thailand-30-days-business-evisa-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-3-month-single-entry-business-visa-online",
      "redirectTo": "visa/thailand-30-days-business-evisa-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-30-days-express-business-visa-online",
      "redirectTo": "visa/thailand-30-days-express-business-evisa-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-30-days-tourist-visa-online",
      "redirectTo": "visa/thailand-30-days-tourist-evisa-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/thailand-30-days-express-tourist-visa-online",
      "redirectTo": "visa/thailand-30-days-express-tourist-evisa-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-12-months-business-visa-online",
      "redirectTo": "visa/australia-12-months-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-12-month-tourist-visa-online",
      "redirectTo": "visa/australia-12-months-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-3-months-business-visa-online",
      "redirectTo": "visa/australia-12-months-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-3-months-tourist-visa-online",
      "redirectTo": "visa/australia-12-months-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-6-months-business-visa-online",
      "redirectTo": "visa/australia-business-12-months-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/australia-6-months-tourist-visa-online",
      "redirectTo": "visa/australia-12-months-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-14-days-multiple-entry-business-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-14-days-multiple-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-14-days-multiple-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-14-days-multiple-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-14-days-single-entry-business-visa-online",
      "redirectTo": "visa/dubai-14-days-single-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-14-days-single-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-14-days-single-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-14-days-single-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-14-days-single-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-30-days-multiple-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-30-days-multiple-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-30-days-multiple-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-30-days-multiple-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-30-days-multiple-entry-business-visa-online",
      "redirectTo": "visa/dubai-30-days-multiple-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-30-days-multiple-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-30-days-multiple-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-30-days-single-entry-business-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-30-days-single-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-30-days-single-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-30-days-single-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-48-hours-transit-visa-online",
      "redirectTo": "visa/dubai-48-hours-transit-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "dubai-96-hours-transit-visa-online",
      "redirectTo": "visa/dubai-96-hours-transit-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-14-days-multiple-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-14-days-multiple-entry-business-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-14-days-multiple-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-14-days-1-adult-1-child-multiple-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-14-days-multiple-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-14-days-single-entry-business-visa-online",
      "redirectTo": "visa/dubai-14-days-single-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-14-days-single-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-14-days-single-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-14-days-single-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-14-days-multiple-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-30-days-single-entry-business-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-30-days-single-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-30-days-single-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-30-days-single-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-30-days-multiple-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-30-days-single-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-30-days-multiple-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-30-days-multiple-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-30-days-multiple-entry-business-visa-online",
      "redirectTo": "visa/dubai-30-days-multiple-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-30-days-multiple-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-30-days-multiple-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-60-days-single-entry-business-visa-online",
      "redirectTo": "visa/dubai-60-days-single-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-60-days-single-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-60-days-single-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-60-days-single-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-60-days-single-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-60-days-single-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-60-days-single-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-60-days-multiple-entry-express-business-visa-online",
      "redirectTo": "visa/dubai-60-days-multiple-entry-express-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-60-days-multiple-entry-express-tourist-visa-online",
      "redirectTo": "visa/dubai-60-days-multiple-entry-express-tourist-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-60-days-multiple-entry-business-visa-online",
      "redirectTo": "visa/dubai-60-days-multiple-entry-business-visa-apply-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/dubai-family-60-days-multiple-entry-tourist-visa-online",
      "redirectTo": "visa/dubai-60-days-multiple-entry-tourist-visa-apply-online",
      "pathMatch": "full"
   },



   {
      "path": "visa/uk-visa-online",
      "redirectTo": "visa/united-kingdom-visa-online",
      "pathMatch": "full"
   },
   {
      "path": "visa/uk-tourist-visa-online",
      "redirectTo": "visa/united-kingdom-tourist-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/uk-business-visa-online",
      "redirectTo": "visa/united-kingdom-business-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/thailand-tourist-visa-online-india",
      "redirectTo": "visa/thailand-tourist-evisa-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/thailand-business-visa-online",
      "redirectTo": "visa/thailand-business-evisa-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/australia-business-visa-online",
      "redirectTo": "visa/australia-business-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/belgium-tourist-visa-online",
      "redirectTo": "visa/belgium-tourist-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/belgium-business-visa-online",
      "redirectTo": "visa/belgium-business-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/italy-tourist-visa-online",
      "redirectTo": "visa/italy-tourist-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/italy-business-visa-online",
      "redirectTo": "visa/italy-business-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/singapore-tourist-visa-online",
      "redirectTo": "visa/singapore-tourist-visa-online-india",
      "pathMatch": "full"
   }, {
      "path": "visa/singapore-business-visa-online",
      "redirectTo": "visa/singapore-business-visa-online-india",
      "pathMatch": "full"
   }
];

