<div *ngIf="navData">
    <div class="d-none d-md-block">
        <div class="row">
            <div class="col-md-3">
                <ul ngbNav #navLarge="ngbNav" [(activeId)]="activeItem" class="nav_parent nav " orientation="vertical">
                    <li class="nav_link_li" *ngFor="let item of navData;let i=index" ngbNavItem="vtab_faq_id_{{i}}">
                        <a ngbNavLink class="" (click)="displaFaq(item,i)">
                            <div class="selected_elem_border"></div>
                            <span class="nav_link_item_style posistio-relative ">

                                <span class="position-relative">
                                    <span class="material-icons-outlined arrow-forward" *ngIf="selectedIndex===i">
                                        arrow_forward
                                    </span>
                                    {{item?.name}}
                                </span>


                            </span>
                        </a>
                        <ng-template ngbNavContent>
                            <stp-faq [componentData]="faqQuestionAnswer"></stp-faq>
                        </ng-template>
                    </li>
                </ul>
            </div>

            <div class="col-md-9">
                <div [ngbNavOutlet]="navLarge" class="w-100"></div>
            </div>
        </div>
    </div>
    <div class="d-md-none d-block">
        <div class="row" *ngIf="tabView">
            <div class="col-md-3">
                <ul ngbNav #navLarge="ngbNav" [(activeId)]="activeItem" class="nav-tabs useful-tab hr_vr_tab"
                    orientation="vertical">
                    <li *ngFor="let item of navData;let i=index" ngbNavItem="vtab_faq_id_{{i}}">
                        <a ngbNavLink class="p-3 " (click)="displaFaq(item,i)">
                            <span class="">{{item?.name}}</span>
                        </a>
                        <ng-template ngbNavContent>
                            <stp-faq [componentData]="faqQuestionAnswer"></stp-faq>
                        </ng-template>
                    </li>
                </ul>
            </div>

            <div class="col-md-9">
                <div [ngbNavOutlet]="navLarge" class="w-100"></div>
            </div>
        </div>
        <div class="row" *ngIf="!tabView">
            <div class="col-md-3">
                <div *ngFor="let item of navData;let i=index" ngbNavItem="vtab_faq_id_{{i}}">

                    <div class="p-3 " (click)="displaFaq(item,i)">
                        <span class="black_color commissionerflair_semibold font_16">{{item?.name}}</span>
                    </div>
                    <div>
                        <stp-faq [componentData]="faqQuestionAnswer"></stp-faq>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center margin_top_20 margin_bottom_20" *ngIf="showReadMore">
        <button class="btn btn-primary read-more" (click)="readMore()">Read More</button>
    </div>
</div>