<div class="faq-accordion" (click)="faqWebengageTrigger()">
    <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" [destroyOnHide]="false">
        <ngb-panel *ngFor="let faq of componentData">
            <ng-template ngbPanelTitle>
                <span class="Required_img">
                    <img src="{{faq?.imageUrl}}" alt="">
                </span>
                <span class="commissionerflair_semibold converted_font_22">
                    <div *ngIf="faq?.question && faq?.heading">{{faq?.heading}}</div>
                    <div *ngIf="faq?.question && !faq?.heading">{{faq?.question}}</div>
                    <div *ngIf="!faq?.question && faq?.heading">{{faq?.heading}}</div>
                </span>

            </ng-template>
            <ng-template ngbPanelContent>
                <p class="mb-0" [innerHTML]="faq?.answer" *ngIf="faq?.answer && faq?.description"></p>
                <p class="mb-0" [innerHTML]="faq?.answer" *ngIf="faq?.answer && !faq?.description"></p>
                <p class="mb-0" [innerHTML]="faq?.description" *ngIf="faq?.description && !faq?.answer"></p>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>
</div>