import {
  Component,
  HostListener,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { isPlatformBrowser } from '@angular/common';
import { AppConstants } from './app.constant';
declare var webengage: any; // Declare the webengage variable
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isBackToTop: boolean = false;
  topPosToStartShowing = 700;
  appConstants = new AppConstants();

  constructor(
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {


  }

  ngOnInit(): void {
    // this.titleService.setTitle('Stamp The Passport');
    this.tokenExpiryTest();
    // this.addScript();
    // if (isPlatformServer(this.platformId) && webengage) {
    //   webengage.onReady(function () {
    //     let subscribed = webengage.webpush.isSubscribed();
    //     let isNotificationSuprtd = webengage.webpush.isPushNotificationsSupported();

    //     if (subscribed) {
    //       console.log('User is already subscribed');
    //     } else {
    //       console.log('User is not yet subscribed');
    //     }

    //     if (isNotificationSuprtd) {
    //       console.log('notification supported');
    //     } else {
    //       console.log('notification not supported');
    //     }
    //   });
    // }


    // this.router.events.subscribe(event => {

    //   if (event instanceof NavigationEnd) {
    //     const url = event.urlAfterRedirects;

    //     if (url == '/') {

    //       this.titleService.setTitle('Stamp The Passport');
    //     } else if (url == this.appConstants.home) {

    //       this.titleService.setTitle('Stamp The Passport');
    //     } else {

    //       let filteredName: string | any = url.split("/").pop()!;
    //       filteredName = filteredName.split("?").shift();

    //       let nameToShow = filteredName.replace(/-/g, ' ').trim();

    //       var capitalizedString = this.capitalizeString(nameToShow);
    //       this.titleService.setTitle(capitalizedString);
    //     }

    //   }
    // });
  }

  tokenExpiryTest() {
    if (isPlatformBrowser(this.platformId)) {
      let token = localStorage.getItem('token');
      if (token) {
        let ParsToken = JSON.parse(token);
        let dataTosend = { token: ParsToken };
        this.authService
          .isTokenAuthenticated(dataTosend)
          .subscribe((res: any) => {
            if (!res.token_valid) {
              this.authService.tokenClear();
            }
          });
      }
    }
  }

  capitalizeString(str: any) {
    return str.replace(/\b\w/g, function (match: any) {
      return match.toUpperCase();
    });
  }

  onActivate(e: any) {
    if (isPlatformBrowser(this.platformId)) {

      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });

    }
  }

  @HostListener('window:scroll')
  checkScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;

      if (scrollPosition >= this.topPosToStartShowing) {
        this.isBackToTop = true;
      } else {
        this.isBackToTop = false;
      }
    }
  }

  // addScript() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const script = this.renderer.createElement('script');
  //     script.text = `
  //     !function(e,t,a){var c=e.head||e.getElementsByTagName("head")[0],n=e.createElement("script");n.async=!0,n.defer=!0, n.type="text/javascript",n.src=t+"&config="+JSON.stringify(a),c.appendChild(n)}(document,"https://conversation.creyoface.com/files/?file=creyoface_widget.js",{bot_key:"NDEyNDcwNjIyNzUyMDcxNzQyOA==",pos:"right",icon:"https://creyoface-data.s3.ap-south-1.amazonaws.com/Production/412/data/botdata/launchicon.jpg?1709888321766",open:"false",delay:"10000",buttonLabel:false,label:"Chat with us"});
  //     `;
  //     this.renderer.appendChild(document.head, script);
  //   }

  // }
}
