<div class="modal-header">
  <div class="modal-title fw_600">{{ title }}</div>
  <!-- <button type="button" class="btn btn-danger" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button> -->
  <div aria-label="Close" (click)="dismiss()">
    <!-- <span aria-hidden="true">&times;</span> -->
    <span class="material-icons-outlined">
      close
    </span>
  </div>
</div>
<div class="modal-body">
  {{ message }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger text-uppercase" (click)="decline()" *ngIf="btnCancelText">{{ btnCancelText
    }}</button>
  <button type="button" class="btn btn-primary" (click)="accept()" *ngIf="btnOkText">{{ btnOkText }}</button>
</div>