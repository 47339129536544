import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAsMonth'
})
export class DateAsMonth implements PipeTransform {

  transform(value: any): any {
    let monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const dateParts = value?.split('-');
      const day = parseInt(dateParts?dateParts[0]:'', 10);
      const monthIndex = parseInt(dateParts?dateParts[1]:'', 10) - 1;
      const year = parseInt(dateParts?dateParts[2]:'', 10);
      let suffix = '';
      if (day % 10 == 1 && day != 11) {
        suffix = 'st';
      } else if (day % 10 == 2 && day != 12) {
        suffix = 'nd';
      } else if (day % 10 == 3 && day != 13) {
        suffix = 'rd';
      } else {
        suffix = 'th';
      }
      let Convrtday = day + suffix;
      const formattedDate = `${Convrtday} ${monthNames[monthIndex]} ${year}`;
      
    return formattedDate
}

}
