<div class="aside_caro_body position-relative h-100 w-100 d-flex align-items-stretch" *ngIf="componentData && isBrowser">
  <!-- <img class="carousel-img" src="assets/images/loginPage.webp"/> -->

  <!-- <div
  [ngStyle]="{background: 'url('+ componentData[0] + ')' + 'no-repeat left center / cover,#cccccc'}"
  class="h-100"></div> -->
  <owl-carousel-o [options]="customOptions" #owlCar (translated)="getPassedData($event)"
    class="h-100 w-100  login_owl_carousel">

    <ng-container *ngFor="let slide of componentData; let i = index" class="h-100 w-100">
      <ng-template class="slide" carouselSlide [id]="slide.id" class="h-100 w-100">
        <div [ngStyle]="{background: 'url('+ slide?.image + ')' + 'no-repeat center / cover,#cccccc'}"
          class="h-100 w-100 caro_height_set"></div>
      </ng-template>
    </ng-container>

  </owl-carousel-o>


  <!-- <div class="carousel_footer d-flex align-items-center justify-content-between" *ngIf="componentData">

    <div class="caro_navi d-none d-md-flex">
      <div (click)="owlCar.prev()"
        class="caro_navi_button d-flex  justify-content-center align-items-center pointer user-select-none">
        <div class="login_arrow_caro_box">
          <span class="material-icons-outlined">
            arrow_back
          </span>
        </div>
       
      </div>
      <div (click)="owlCar.next()"
        class="caro_navi_button d-flex justify-content-center align-items-center pointer user-select-none">
        <div class="login_arrow_caro_box">
          <span class="material-icons-outlined">
            arrow_forward
          </span>
        </div>
      
      </div>
    </div>
  </div> -->
</div>