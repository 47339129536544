import { APP_ID, Inject, NgModule, PLATFORM_ID } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
  TransferState,
} from '@angular/platform-browser';
// import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { ErrorCatchingInterceptor } from './core/interceptors/error-catching.interceptor';
import { PagesModule } from './pages/page.module';
import { enableProdMode } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MainMenuComponent } from './components/rvm/main-menu/main-menu.component';
import { HeaderInterceptor } from './core/interceptors/header.interceptor';
// import { RouteReuseStrategy } from '@angular/router';
// import { HomePageReuseStrategy } from './core/home-page-reuse-strategy';

@NgModule({
  declarations: [AppComponent],
  exports: [AppComponent],
  imports: [
    // BrowserModule,
    BrowserModule.withServerTransition({ appId: 'stp-server-id' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    MainMenuComponent,
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    PagesModule,
  ],

  providers: [
    provideClientHydration(),
    TransferState,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorCatchingInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderInterceptor,
      multi: true,
    },
    // { provide: RouteReuseStrategy, useClass: HomePageReuseStrategy }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string
  ) {
    enableProdMode();
    const platform = isPlatformBrowser(this.platformId)
      ? 'in the browser'
      : 'on the server';
    console.log(`Running ${platform} with appId=${this.appId}`);
  }
}

// export interface ExtendedEnvironmentProviders extends EnvironmentProviders {
//   provideClientHydration(): EnvironmentProviders;
// }
