<!-- <stp-main-menu></stp-main-menu> -->
<div class="suce_body">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="succe_main white_bg suce_pay">
                    <div class="suce_pay_img_sec text-center text-md-start">
                        <img src="./assets/images/rvmp/success-M6cycMppaO.svg" width="150px" alt="">
                    </div>
                    <div class="suce_pay_cont_sec">
                        <div
                            class="suce_pay_heading commissionerflair_bold rv_blue_color succe_font_24 margin_bottom_8 text-center text-md-start">
                            Payment Successful
                        </div>
                        <div class="suce_pay_description commissionerflair_medium font_16 text-center text-md-start">
                            We will proceed with your details & our expert will get in touch with you for additional
                            information
                        </div>
                        <div class="suce_date_line_sprn"></div>
                        <div class="suce_pay_date commissionerflair_medium font_16 text-center text-md-start">
                            You will get your Visa by
                            <span class="commissionerflair_bold" *ngIf="getByData">{{getByData?.get_by}}</span>
                        </div>
                    </div>
                    <div class="suce_pay_footer d-none d-md-block">
                        <div class="suce_pay_button pointer rv_blue_bg white_color font_14 commissionerflair_medium d-flex align-items-center justify-content-center"
                            (click)="goToMyBooking()">
                            Go to Bookings
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <div class="succe_main white_bg">
                    <div
                        class="suce_main_heading commissionerflair_bold rv_blue_color font_18 margin_bottom_16 text-center text-md-start">
                        Benefits you might like
                    </div>
                    <div class="suce_box_container" *ngFor="let succeData of successDataToShow">
                        <div
                            class="suce_color_box flex-md-nowrap flex-wrap d-flex justify-content-start align-items-start">
                            <div
                                class="suce_img_section mbl_suci_margin_b_16 white_bg d-flex justify-content-center align-items-center">
                                <!-- [ngStyle]="{background: 'url('+ './assets/images/rvmp/VERSION_2.jpg' + ')' + 'no-repeat center / contain,#ffffff'}" -->
                                <!-- <img src="./assets/images/rvmp/VERSION_2.jpg" width="50%" alt=""> -->
                                <img [src]="succeData?.icon" width="100%" height="100%" alt="">
                            </div>

                            <div class="suce_cont_section">
                                <div class="suce_cont_heading">
                                    <div class="commissionerflair_semibold font_16 margin_b_4 lh-1">
                                        <!-- Free Fi Forex Card -->
                                        {{succeData?.heading}}
                                    </div>
                                    <div class="d-flex justify-content-start flex-wrap align-items-start"
                                        *ngIf="succeData?.tags">
                                        <div class="suce_h_green_box d-flex align-items-center me-1"
                                            *ngFor="let tag of parseTag(succeData?.tags);let tagInde =index;">
                                            <!-- <img src="./assets/icons/rvmp/free_money.png" alt=""> -->
                                            <img src="{{tag?.icon}}" alt="" width="16px" height="16px">
                                            <span
                                                class="suce_h_green_box_cont font_10 commissionerflair_semibold text-uppercase ms-1">
                                                <!-- lifetime
                                                free -->
                                                {{tag?.text}}
                                            </span>
                                        </div>
                                        <!-- <div class="suce_h_green_box d-flex align-items-center">
                                            <img src="./assets/icons/rvmp/right_hand_tumb_up.svg" alt="">
                                            <span
                                                class="suce_h_green_box_cont font_10 commissionerflair_semibold text-uppercase ms-1">
                                                recommended
                                            </span>
                                        </div> -->
                                    </div>

                                </div>
                                <div class="suce_tick_content margin_top_16">
                                    <ng-container *ngFor="let benft of succeData?.benefits;let benftInd = index">
                                        <div class="d-flex justify-content-start align-items-center margin_bottom_12"
                                            *ngIf="benftInd<3">
                                            <span class="material-icons-outlined font_18 round_tick_color">
                                                check_circle
                                            </span>
                                            <span
                                                class="rv_blue_color font_14 commissionerflair_medium margin_l_8 lh-1">
                                                <!-- No
                                                minimum
                                                balance -->
                                                {{benft}}
                                            </span>
                                        </div>
                                    </ng-container>


                                    <!-- <div class="d-flex justify-content-start align-items-center margin_bottom_12">
                                        <span class="material-icons-outlined font_18 round_tick_color">
                                            check_circle
                                        </span>
                                        <span class="rv_blue_color font_14 commissionerflair_medium margin_l_8 lh-1">
                                            Zero Forex on Select Plans
                                        </span>
                                    </div>

                                    <div class="d-flex justify-content-start align-items-center margin_bottom_12">
                                        <span class="material-icons-outlined font_18 round_tick_color">
                                            check_circle
                                        </span>
                                        <span class="rv_blue_color font_14 commissionerflair_medium margin_l_8 lh-1">
                                            Withdraw from any ATM
                                        </span>
                                    </div> -->

                                    <div class="suce_extr_bnfits font_12 commissionerflair_medium d-inline-block user-select-none pointer"
                                        *ngIf="succeData?.benefits?.length>=4" placement="auto"
                                        [ngbPopover]="succeMoreBene" popoverClass="succ_more_ben"
                                        triggers="mouseenter:mouseleave">
                                        <!-- triggers="mouseenter:mouseleave" -->
                                        +{{succeData?.benefits?.length - 3}} benefits
                                    </div>
                                    <ng-template #succeMoreBene>
                                        <!-- <ol class="succ_more_ben_points">
                                            <ng-container
                                                *ngFor="let bnfit of succeData?.benefits.slice(3);let bnfitIndex= index;">
                                                <li>{{bnfit}}</li>

                                            </ng-container>
                                        </ol> -->
                                        <div class="succ_more_ben_points">
                                            <ng-container
                                                *ngFor="let bnfit of succeData?.benefits.slice(3);let bnfitIndex= index;">
                                                <div class="d-flex align-items-start margin_bottom_8">
                                                    <span class="material-icons-outlined font_18 round_tick_color">
                                                        check_circle
                                                    </span>
                                                    <span
                                                        class="rv_blue_color font_14 commissionerflair_medium margin_l_8 lh-1">
                                                        <!-- No
                                                        minimum
                                                        balance -->
                                                        {{bnfit}}
                                                    </span>
                                                </div>



                                            </ng-container>
                                        </div>


                                    </ng-template>

                                </div>
                            </div>
                        </div>
                        <div>
                            <a [href]="succeData?.link"
                                class="suce_box_main_footer d-flex justify-content-center align-items-center font_14  commissionerflair_bold" (click)="trackLinkUse(succeData)">
                                <span class="me-2">
                                    <!-- Claim Free Gift -->
                                    {{succeData?.linkText}}
                                </span>
                                <img src="./assets/icons/rvmp/Arrow-Up-Right--Streamline-Phosphor.jpg" width="16px"
                                    loading="lazy" alt="">
                            </a>
                        </div>
                    </div>
                    <ng-container *ngIf="interactiveBanner">
                        <div *ngFor="let banner of interactiveBanner" (click)="interacBanrTrigger(banner)">
                            <a href="{{banner?.interactiveBannerLink}}" target="_blank" class="w-100"
                                *ngIf="banner?.interactiveBanner || banner?.interactiveBannerMob">
                                <div class="d-none d-md-block mw-100" >
                                    <img [src]="banner?.interactiveBanner" class="w-100" alt="">
                                </div>
                                <div class="d-block d-md-none mw-100">
                                    <img [src]="banner?.interactiveBannerMob" class="w-100" alt="">
                                </div>
                            </a>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="mbl_btn_fixed_space d-block d-md-none"></div>
    <div class="d-block d-md-none succ_mbl_btn">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="suce_pay_footer ">
                        <div class="suce_pay_button pointer rv_blue_bg white_color font_14 commissionerflair_medium d-flex align-items-center justify-content-center"
                            (click)="goToMyBooking()">
                            Go to Bookings
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Start:- Modal -->
<ng-template #cleintRating let-modal>
    <div *ngIf="!isRatingSubmitCmplt" class="white_bg modal_width_set">
        <div class="modal-header">
            <div class="commissionerflair_semibold font_20_unchanged rv_blue_color line_h_modal_content">
                Your Feedback Matters
            </div>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
        </div>
        <div class="modal-body">
            <ng-container *ngTemplateOutlet="feedbackBody"></ng-container>
        </div>
    </div>
    <div *ngIf="isRatingSubmitCmplt" class="succe_final_msg_body d-flex justify-content-center align-items-end">
        <div class="padding_16 white_bg modal_width_set">
            <div class="text-center margin_bottom_12 user-select-none position-relative">
                <img src="./assets/images/rvmp/final_rating_tick.svg" width="93px" alt="">
                <button type="button" class="btn-close close_btn_succe_msg" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal_dilg_succe_msg">
                <div class="commissionerflair_semibold font_20 rv_blue_color text-center">Thanks for your feedback</div>
                <div class="succe_spl_gray_color font_16 commissionerflair_medium text-center">
                    We are constantly working hard to make our platform seamless for our users
                </div>

            </div>
        </div>

    </div>
    <!-- <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
</ng-template>
<!--End:- Modal -->

<!-- Start:- Canvas -->

<ng-template #cleintRatingCanvas let-offcanvas>
    <!-- <div class="offcanvas-header bg_f8f8f7">
        <h4 class="offcanvas-title"> <img *ngIf="isSaving" class="img-fluid" src="./assets/images/loader/1495.gif"
                loading="lazy" alt=""> Traveller’s Details</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
    </div> -->

    <div *ngIf="!isRatingSubmitCmplt" class="white_bg">
        <div class="offcanvas-header offcanvas_header_bottom">
            <div class="commissionerflair_semibold font_20_unchanged rv_blue_color line_h_modal_content">
                Your Feedback Matters
            </div>
            <button type="button" class="btn-close" aria-label="Close"
                (click)="offcanvas.dismiss('Cross click')"></button>
        </div>

        <div class="offcanvas-body calendar-section position-ralative">
            <div class="text-start m-auto mt-2 travel_sele_calendar calendar-section ">
                <ng-container *ngTemplateOutlet="feedbackBody"></ng-container>
            </div>
        </div>
    </div>

    <div *ngIf="isRatingSubmitCmplt" class="succe_final_msg_body d-flex justify-content-center align-items-end">
        <div class="padding_16 white_bg">
            <div class="text-center margin_bottom_12 user-select-none position-relative">
                <img src="./assets/images/rvmp/final_rating_tick.svg" width="93px" alt="">
                <button type="button" class="btn-close close_btn_succe_msg" aria-label="Close"
                    (click)="offcanvas.dismiss('Cross click')"></button>
            </div>
            <div class="modal_dilg_succe_msg">
                <div class="commissionerflair_semibold font_20 rv_blue_color text-center">Thanks for your feedback
                </div>
                <div class="succe_spl_gray_color font_16 commissionerflair_medium text-center">
                    We are constantly working hard to make our platform seamless for our users
                </div>

            </div>
        </div>

    </div>
</ng-template>
<!-- Start:- Canvas -->

<ng-template #feedbackBody>
    <div class="commissionerflair_medium font_16 rv_blue_color line_h_modal_content margin_bottom_8">
        How was your booking experience?
    </div>
    <div class="user-select-none margin_bottom_8">
        <span *ngFor="let star of ratingStars;let i = index" (click)="starCtrl(i+1)"
            class="material-icons font_24_unchanged margin_r_8 pointer {{(i+1) <= curSelectedStar ? 'star_filled_color':'star_empty_color'}} ">
            star_rate
        </span>
    </div>
    <div class="margin_bottom_16" *ngIf="curSelectedStar">
        <div class="commissionerflair_medium font_16 rv_blue_color line_h_modal_content margin_bottom_8">
            Your Feedback
        </div>
        <textarea class="form-control" rows="3" placeholder="Write Here..." [(ngModel)]="feedBack"></textarea>
    </div>
    <div class="rating_popup_footer">
        <button [disabled]="!curSelectedStar"
            class="line_h_modal_content rating_popup_footer_button pointer border-0  white_color font_14 commissionerflair_medium d-flex align-items-center justify-content-center w-100 {{curSelectedStar ? 'rv_blue_bg' : 'gray_bg_color'}}"
            (click)="submitComplt()">
            Submit
        </button>
    </div>
</ng-template>