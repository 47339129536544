import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SlidesOutputData, OwlOptions } from 'ngx-owl-carousel-o';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'stp-login-carousel',
  templateUrl: './login-carousel.component.html',
  styleUrls: ['./login-carousel.component.scss'],
})
export class LoginCarouselComponent implements OnChanges {
  @Input() componentData: any = [];
  baseUrl: string = environment.imageUrl;
  currentSlide: any = 0;
  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    margin: 20,
    items: 1,
    slideBy: 1,
    autoplayTimeout: 4000,
    // mouseDrag: false,
    touchDrag: true,
    pullDrag: false,
    // lazyLoad: true,
    dots: true,
    navSpeed: 500,
    autoHeight: true,
    autoWidth: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
        dots: true,
      },
      400: {
        items: 1,
        dots: true,
      },
      740: {
        items: 1,
      },
      940: {
        items: 1,
        dots: false,
      },
    },
    nav: false,
  };

  slidesStore: any[] = [];
  expandedStates: boolean[] = [];

  constructor() {
    // setTimeout(()=>{
    //   console.log(this.componentData)
    // })
  }

  ngOnChanges(changes: SimpleChanges) { }

  counter(n: number): number[] {
    return Array(n)
      .fill(0)
      .map((_, i) => i + 1);
  }

  getPassedData(data: SlidesOutputData) {
    this.currentSlide = data.startPosition;
  }
  toggleReadMore(index: number) {
    this.expandedStates[index] = !this.expandedStates[index];
  }
}
