import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { retry } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class VisaProcessService {
  token: any;
  header: any;
  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object) { }

  private travellerSubject = new BehaviorSubject<any>(null);
  public travellers$: Observable<any> = this.travellerSubject.asObservable();

  private priceSubject = new BehaviorSubject<any>(null);
  public pricedata$: Observable<any> = this.priceSubject.asObservable();

  updateTravellers(value: any): void {
    this.travellerSubject.next(value);
  }

  getCurrentTravellers(): any {
    return this.travellerSubject.getValue();
  }

  updatePrices(value: any): void {
    this.priceSubject.next(value);
  }

  getCurrentPrices(): any {
    return this.priceSubject.getValue();
  }

  getHeader() {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token');

      this.header = new HttpHeaders({
        Authorization: `Token ${JSON.parse(this.token)}`,
        'Cache-Control': 'no-cache'
      });
    }
  }

  getCountry(): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(`${environment.adminApiUrl}/country/?_=${timestamp}`, { headers: this.header })
      .pipe(retry(2));
  }

  getCountryForms(countryId: number): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(`${environment.adminApiUrl}/country-forms/?country=${countryId}&_=${timestamp}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  getCountryQuestions(paxId: number): Observable<any> {
    // formId
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(
        `${environment.adminApiUrl}/country-questions/get-country-questions/?pax_id=${paxId}&_=${timestamp}`,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  getVisaApplicationProcess(_status: string): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(
        `${environment.adminApiUrl}/customer-visaprocess/?status=${_status}&_=${timestamp}`,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  getPaxVisaProcess(id: number): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(`${environment.adminApiUrl}/pax-visaprocess/?visa_process=${id}&_=${timestamp}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  getSavedVisaImg(paxId: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(`${environment.adminApiUrl}/signzy-user-details/?pax_id=${paxId}&_=${timestamp}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  postPaxDetailsUpdate(data: any): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http.post(
      `${environment.adminApiUrl}/pax-visaprocess/pax-details-update/?_=${timestamp}`,
      data,
      { headers: this.header }
    );
  }

  getMyVisaDetails(id: string) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(`${environment.adminApiUrl}/my-visa/?visa_process_id=${id}&_=${timestamp}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  // ongoingVisaDetails() {

  //   return this.http.get(`${environment.adminApiUrl}/my-visa/?status=IN-PROGRESS`, { headers: this.getHeader() }).pipe(retry(2));
  // }

  // completedVisaDetails() {

  //   return this.http.get(`${environment.adminApiUrl}/my-visa/?status=COMPLETED`, { headers: this.getHeader() }).pipe(retry(2));
  // }

  getConsulateDetails(paxId: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(
        `${environment.adminApiUrl}/consulate-form/view-form-filling/?pax_id=${paxId}&_=${timestamp}`,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  getPaxUserPermission(paxId: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(`${environment.adminApiUrl}/user-permissions/?pax_id=${paxId}&_=${timestamp}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  getDocument(paxId: number) {
    this.getHeader();
    // &doc_type=${docType}
    const timestamp = new Date().getTime();
    return this.http
      .get(`${environment.adminApiUrl}/documents-upload/?pax_id=${paxId}&_=${timestamp}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  getScheduleDetails(paxId: number) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(`${environment.adminApiUrl}/user-schedule/?pax_id=${paxId}&_=${timestamp}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  getCotravellers(data: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    // return this.http
    //   .get(`${environment.adminApiUrl}/users/my-profile/?_=${timestamp}`, {
    //     headers: this.header,
    //   })
    //   .pipe(retry(2));
    return this.http
      .get(`${environment.adminApiUrl}/co-traveller-list/?visa_process_id=${data.visa_process_id}&_=${timestamp}`, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  addTravlrFromPrfl(data: any) {
    this.getHeader();
    return this.http.post(`${environment.adminApiUrl}/co-traveller-details/`, data, {
      headers: this.header,
    });
  }

  postScheduleDates(data: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http.post(`${environment.adminApiUrl}/schedule-dates/?_=${timestamp}`, data, {
      headers: this.header,
    });
  }

  postConsulateFailureAction(paxId: any, questions: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .post(
        `${environment.adminApiUrl}/consulate-form/user-verification-failure/?pax_id=${paxId}&_=${timestamp}`,
        questions,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  postConsulateSuccessAction(paxId: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .post(
        `${environment.adminApiUrl}/consulate-form/user-verification-success/?pax_id=${paxId}&_=${timestamp}`,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  postVisaProcessSteps(data: any): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http.post(
      `${environment.adminApiUrl}/customer-data-collection/?_=${timestamp}`,
      data,
      { headers: this.header }
    );
  }

  postVisaProcessStepsEnd(paxId: boolean): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http.post(
      `${environment.adminApiUrl}/master-formfill-end/?pax_id=${paxId}&_=${timestamp}`,
      { headers: this.header }
    );
  }

  postLinkedIn(data: any): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http.post(`${environment.adminApiUrl}/linkedln/?_=${timestamp}`, data, {
      headers: this.header,
    });
  }

  uploadUserUpdate(data: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http.post(
      `${environment.adminApiUrl}/data-update/user-update/?_=${timestamp}`,
      data,
      { headers: this.header }
    );
  }

  viewUserUpdate(paxId: number) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(
        `${environment.adminApiUrl}/data-update/user-updated-data/?pax_id=${paxId}&_=${timestamp}`,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  uploadSchedulDate(data: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .post(`${environment.adminApiUrl}/schedule-dates/&_=${timestamp}`, data, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  getloadSchedulDate(paxId: number, docType: string) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(
        `${environment.adminApiUrl}/schedule-dates/?pax_id=${paxId}&role=agent&doc_type=${docType}&_=${timestamp}`,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  getUpdateData(paxId: number) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .get(
        `${environment.adminApiUrl}/data-update/get-update-data/?pax_id=${paxId}&_=${timestamp}`,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  uploadSignzyProcess(data: any): Observable<any> {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .post(`${environment.adminApiUrl}/signzy/?_=${timestamp}`, data, { headers: this.header })
      .pipe(retry(2));
  }

  uploadDocument(data: any) {
    this.getHeader();
    const timestamp = new Date().getTime();
    return this.http
      .post(`${environment.adminApiUrl}/documents-upload/?_=${timestamp}`, data, {
        headers: this.header,
      })
      .pipe(retry(2));
  }

  uploadImgFile(data: any): Observable<any> {
    const timestamp = new Date().getTime();
    // const headers = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });, { headers }
    return this.http.post(
      `${environment.adminApiUrl}/cms-admin/common/file-upload/?_=${timestamp}`,
      data,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  getCountryQuestionsByPax(id: any) {
    const timestamp = new Date().getTime();
    return this.http.get(
      `${environment.adminApiUrl}/country-questions/get-country-questions/?pax_id=${id}&_=${timestamp}`
    );
  }

  userVerificationStatus(id: any) {
    const timestamp = new Date().getTime();
    return this.http.get(
      `${environment.adminApiUrl}/consulate-form/user-verification-reject/?pax_id=${id}&_=${timestamp}`
    );
  }

  unblockCountryQuestions(data: any, pax_id: any) {
    const timestamp = new Date().getTime();
    return this.http.post(
      `${environment.adminApiUrl}/country-questions/unblock-country-questions/?pax_id=${pax_id}&_=${timestamp}`,
      data
    );
  }

  getLinkedInUrl(id: any) {
    this.getHeader()
    const timestamp = new Date().getTime();
    return this.http.get(`${environment.adminApiUrl}/linkedln/?pax_id=${id}&_=${timestamp}`, {
      headers: this.header,
    });
  }

  getInsuranceDetails(country: number, primary: number) {
    this.getHeader();
    return this.http
      .get(
        `${environment.adminApiUrl}/get-addon-insurance/?product_id=${country}&primary_customer_id=${primary}`,
        { headers: this.header }
      )
      .pipe(retry(2));
  }

  addNomineeDetails(data: any) {
    return this.http.post(`${environment.adminApiUrl}/nominee-details/`, data);
  }

  getNomineeDetails(id: any) {
    return this.http.get(`${environment.adminApiUrl}/nominee-details/?primary_customer_id=${id}`);
  }

  getSetNomineeDetails(id: any) {
    return this.http.get(`${environment.adminApiUrl}/user-nominee-details/${id}/`);
  }

  getApplicationDetails(primary_customer_id: any, product_id?: any) {
    let url = `${environment.adminApiUrl}/get-application-details/`;
    if (primary_customer_id || product_id) {
      url += `?${primary_customer_id ? `primary_customer_id=${primary_customer_id}` : ''}${primary_customer_id && product_id ? '&' : ''}${product_id ? `product_id=${product_id}` : ''}`;
    }
    return this.http.get(url);
  }

  addTraveller(data: any) {
    return this.http.post(`${environment.adminApiUrl}/add-traveler/`, data);
  }

  getCountryWarninng(id: any) {
    return this.http.get(
      `${environment.adminApiUrl}/get-country-warning/?product_id=${id}`
    );
  }

}
