<div class="footer_section bg-primary position-relative">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
        <div class="col-md-12">
          <img src="/assets/images/footer_logo.webp" loading="lazy" class="img-fluid footer_logo" alt="STP" />
        </div>
        <div class="col-md-12 row d-block d-md-none list-data custom-accordion">
          <ngb-accordion #acc="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <b>COUNTRY</b>
              </ng-template>
              <ng-template ngbPanelContent>
                <ul class="col-md-3 g-0 country_names">
                  <li class="pointer" *ngFor="let country of getCountryWithLimit(limit)">

                    <a [routerLink]="['/visa/' + country.countryUrl + '-visa-online']"
                      class="text-reset text-decoration-none">
                      {{ country.name }}
                    </a>

                    <!-- [routerLink]="['/visa/'+ country.countryUrl +'-visa-online']" -->
                  </li>
                  <div class="mt-2 color_a1 pointer" *ngIf="footerCountryList?.length > 15 && limit == 15"
                    (click)="limit = footerCountryList?.length">
                    Read More
                  </div>
                </ul>
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <b>COMPANY</b>
              </ng-template>
              <ng-template ngbPanelContent>
                <ul class="col-md-3 g-0">
                  <li class="pointer">
                    <a [routerLink]="[aboutUs]" class="text-reset text-decoration-none">
                      About Us
                    </a>
                  </li>
                  <!-- <li class="pointer" [routerLink]="['']">Online Application</li> -->
                  <li class="pointer">
                    <a [routerLink]="[appConstants.consultantUsUrl]" class="text-reset text-decoration-none">
                      Visa Pro
                    </a>
                  </li>
                  <li class="pointer">
                    <a [routerLink]="[appConstants.home]"
                      class="text-reset text-decoration-none">Partners</a>
                  </li>
                  <li class="pointer">
                    <a [routerLink]="[appConstants.blogUrl]" class="text-reset text-decoration-none">
                      Blog
                    </a>

                  </li>
                  <li class="pointer" [routerLink]="[appConstants.newsUrl]">
                    <a [routerLink]="[appConstants.blogUrl]" class="text-reset text-decoration-none">
                      News
                    </a>
                  </li>
                  <li class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase">
                    <!-- (click)="tripPlannerServc()" -->
                    <a href="https://holidays.stampthepassport.com/" class="text-reset text-decoration-none"
                      target="_blank">
                      TRIP PLANNER
                    </a>
                  </li>
                  <li class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase">
                    <!-- (click)="airportSrvc()" -->
                    <a href="https://stampthepassport.airportzo.com/" class="text-reset text-decoration-none"
                      target="_blank">
                      AIRPORT SERVICES
                    </a>
                  </li>
                </ul>
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <b>HELP</b>
              </ng-template>
              <ng-template ngbPanelContent>
                <ul class="col-md-3 g-0">
                  <li class="pointer">
                    <a href="{{ siteUrl + appConstants.termsConditions }}" target="_blank">Terms</a>
                  </li>
                  <li class="pointer">
                    <a href="{{ siteUrl + appConstants.privacyPolicyUrl }}" target="_blank">Privacy Policy</a>
                  </li>
                  <li class="pointer">
                    <a href="{{ siteUrl + appConstants.userAgreement }}" target="_blank">User Agreement</a>
                  </li>
                  <li class="pointer" [routerLink]="[appConstants.contactUsUrl]">
                    Contact Us
                  </li>
                  <li class="pointer" [routerLink]="[appConstants.faqUrl]">
                    FAQs
                  </li>
                </ul>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
        <!-- <div class="col-md-6 d-md-none d-block">
          <div class="d-flex align-items-center color_black_75 font_14">
            <div>
              <p class="mb-0">
                <a href="tel:+91 73048 57959">+91 73048 57959</a>
              </p>
              <p class="mb-0">
                <a href="mailto:connect@stampthepassport.com" target="_blank"
                  >connect@stampthepassport.com</a
                >
              </p>
            </div>
            <img
              src="/assets/images/foot_line.webp"
              loading="lazy"
              class="img-fluid mx-2"
              alt=""
            />
            <div>
              <p class="mb-0">
                Ground Floor, Deccan House, behind DDecor Showroom, Patkar
                Blocks, Bandra West, Mumbai, Maharashtra 400050
              </p>
            </div>
          </div>
        </div> -->
        <div class="col-md-12">
          <div class="heading_h2 commissionerflair_semibold foot_h2 black_color">
            Stay in touch!
          </div>
          <p class="content">
            Important updates, exciting offers and the occasional inspiration to
            help you plan your next trip.
          </p>
          <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribeSubmit(subscribeForm)">
            <div class="mail-submit w-100 position-relative">
              <input type="email" class="form-control mail_submit bg-white" placeholder="Email id"
                formControlName="email" />
              <button [disabled]="buttonDisable || formControls?.email?.invalid" type="submit"
                class="btn btn-primary btn-with-loader bg-white">
                SUBSCRIBE
                <div class="ms-2 me-0 position-static" *ngIf="loaderShow">
                  <img class="img-fluid" src="./assets/images/loading-gif.gif" alt="" />
                </div>
              </button>
            </div>
            <small class="text-danger" *ngIf="
                formControls?.email?.errors?.email &&
                !formControls?.email?.errors?.required
              ">
              Invalid email
            </small>
            <div class="mb-4">
              I agree to receive email updates from StampThePassport
            </div>
          </form>
        </div>
      </div>
      <div class="col-md-7 offset-md-1">
        <div class="row list-data d-none d-md-flex">
          <div class="col-md-6 gx-3">
            <ul class="row ps-3 me-3 mb-0">
              <li><b>Country</b></li>
            </ul>
            <ul class="row ps-3 me-3 country_names">
              <li class="col-md-6 pointer" *ngFor="let country of getCountryWithLimit(limit)">
                <a class="text-reset text-decoration-none"
                  [routerLink]="['/visa/' + country.countryUrl + '-visa-online']" (click)="reloadCountryPage()">
                  {{ country.name }}
                </a>
              </li>
            </ul>
            <div class="mt-2 color_a1 pointer" *ngIf="footerCountryList?.length > 15 && limit == 15"
              (click)="limit = footerCountryList?.length">
              Read More
            </div>
          </div>
          <!-- <ul class="col-md-3 gx-3">
                        <li><b>OUR SERVICES</b></li>
                        <li class="pointer">Tourist Visa</li>
                        <li class="pointer">Business Visa</li>
                        <li class="pointer">Reapplication</li>
                        <li class="pointer">Insurance</li>
                        <li class="pointer">Flight & Hotel</li>
                        <li class="pointer">Holiday</li>

                    </ul>
                    <ul class="col-md-3 gx-3">
                        <li><b>RESOURCES</b></li>
                        <li class="pointer">Blog</li>
                        <li class="pointer">News</li>
                        <li class="pointer" [routerLink]="[appConstants.faqUrl]">FAQs
                        </li>

                    </ul> -->
          <ul class="col-md-3 gx-3">
            <li><b>COMPANY</b></li>
            <li class="pointer">
              <a [routerLink]="[aboutUs]" class="text-reset text-decoration-none">
                About Us
              </a>
            </li>
            <!-- <li class="pointer" [routerLink]="['']">Online Application</li> -->
            <li class="pointer">
              <a [routerLink]="[appConstants.consultantUsUrl]" class="text-reset text-decoration-none">
                Visa Pro
              </a>
            </li>
            <li class="pointer">
              <a [routerLink]="[appConstants.home]">Partners</a>
              <!-- fragment="partnersSection" -->
            </li>
            <li class="pointer">
              <a [routerLink]="[appConstants.blogUrl]">Blog</a>
            </li>
            <li class="pointer">
              <a [routerLink]="[appConstants.newsUrl]" class="text-reset text-decoration-none">
                News
              </a>
            </li>
            <li class="pointer">
              <!-- (click)="tripPlannerServc()" -->
              <a href="https://holidays.stampthepassport.com/" class="text-reset text-decoration-none" target="_blank">
                TRIP PLANNER
              </a>
            </li>
            <li class="pointer" (click)="airportSrvc()">
              <a href="https://stampthepassport.airportzo.com/" class="text-reset text-decoration-none" target="_blank">
                AIRPORT SERVICES
              </a>
            </li>
          </ul>
          <ul class="col-md-3 gx-3">
            <li><b>HELP</b></li>
            <li class="pointer">
              <a href="{{ siteUrl + appConstants.termsConditions }}" target="_blank">Terms</a>
            </li>
            <li class="pointer">
              <a href="{{ siteUrl + appConstants.privacyPolicyUrl }}" target="_blank">Privacy Policy</a>
            </li>
            <li class="pointer">
              <a href="{{ siteUrl + appConstants.userAgreement }}" target="_blank">User Agreement</a>
            </li>
            <li class="pointer">
              <a [routerLink]="[appConstants.contactUsUrl]" class="text-reset text-decoration-none">
                Contact Us
              </a>

            </li>
            <li class="pointer">
              <a [routerLink]="[appConstants.faqUrl]" class="text-reset text-decoration-none">
                FAQs
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-5">
        <div class="d-block d-md-flex align-items-center">
          <!-- <div class="mb-3 mb-md-0">
                        <a class="me-3 me-md-2" href="#">
                            <img src="/assets/icons/google_play.webp" class="img-fluid" alt="">
                        </a>
                        <a class="me-3 me-md-2" href="#">
                            <img src="/assets/icons/app_store.webp" class="img-fluid" alt="">
                        </a>
                    </div> -->
          <div>
            <a class="me-3 me-md-2" href="https://fb.watch/lMFnoaZZa0/?mibextid=RUbZ1f" target="_blank"
              (click)="socialButtonClicked('fb')">
              <img src="/assets/icons/facebook.webp" loading="lazy" class="icon_wb_mb" alt="" />
            </a>
            <a class="me-3 me-md-2" href="https://twitter.com/stampthepasport/status/1681608026921521152?s=20"
              target="_blank" (click)="socialButtonClicked('twitter')">
              <img src="/assets/icons/twitter.webp" loading="lazy" class="icon_wb_mb" alt="" />
            </a>
            <a class="me-3 me-md-2"
              href="https://www.instagram.com/p/Cuy04MSIpq6/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
              target="_blank" (click)="socialButtonClicked('instagram')">
              <img src="/assets/icons/instagram.webp" loading="lazy" class="icon_wb_mb" alt="" />
            </a>
            <a class="me-3 me-md-2" href="https://www.linkedin.com/company/stampthepassport/?viewAsMember=true"
              target="_blank" (click)="socialButtonClicked('linkedin')">
              <img src="/assets/icons/linkedin.webp" loading="lazy" class="icon_wb_mb p-1" alt="" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-7 d-none d-md-block">
        <!-- <div
          class="d-flex align-items-center justify-content-end color_black_75 mt-3"
        > -->
        <!-- <div> -->
        <!-- <p class="mb-0">
              <a href="https://wa.me/919370566863?text=Hello" target="_blank"
                >+91 9370566863</a
              >
            </p> -->
        <!-- <p class="mb-0">
              <a href="mailto:connect@stampthepassport.com" target="_blank"
                >connect@stampthepassport.com</a
              >
            </p> -->
        <!-- </div> -->
        <!-- <img
            src="/assets/images/foot_line.webp"
            class="img-fluid mx-3"
            alt=""
          /> -->
        <!-- <div> -->
        <!-- <p class="mb-0">
              Ground Floor, Deccan House, behind DDecor Showroom, Patkar Blocks,
              Bandra West, Mumbai, Maharashtra 400050
            </p> -->
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
  <!-- <div class="footer_bottom_fixed d-flex align-items-center text-center d-md-none position-fixed bottom-0 w-100"
        *ngIf="isShowApply">
        <div class="bottom_aply_btn w-100" (click)="applyNowModal()">
            Apply Now
        </div>
        <div class="bootom_othr_btn d-flex align-items-center justify-content-center w-100">
            <a class="d-flex align-items-center" href="https://wa.me/919370566863?text=Hello" target="_blank">
                Chat
                <span class="material-icons-outlined ps-2">
                    chat
                </span>
            </a>
        </div>
        <div class="bootom_othr_btn bootom_share d-flex align-items-center justify-content-center w-100">
            Share
            <span class="material-icons-outlined ps-2">
                share
            </span>
            <div class="share_div">
                <div>
                    <a href="https://wa.me/?text=https://stampthepassport.com/" target="_blank">
                        <img class="img-fluid" src="./assets/images/social/WhatsApp_icon.webp" loading="lazy" alt="">
                    </a>
                </div>
                <div>
                     <a href="https://www.linkedin.com/shareArticle?mini=true&url=https://stampthepassport.com&title=LinkedIn%20Developer%20Network&summary=My%20favorite%20developer%20program&source=LinkedIn"
                        target="_blank">
                        <img class="img-fluid" src="./assets/images/social/linkedin.webp" loading="lazy" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div> -->
</div>