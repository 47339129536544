import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  constructor(private cookieService: CookieService) {}

  setItem(key: string, value: string) {
    this.cookieService.set(key, value);
  }

  getItem(key: string): string {
    return this.cookieService.get(key);
  }

  removeItem(key: string) {
    this.cookieService.delete(key);
  }
}
