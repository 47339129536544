
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Apply Now</h4>
		<button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="modal" (click)="closeModal()"></button>
	</div>
	<div class="modal-body">
		<form [formGroup]="applyNowForm" (ngSubmit)="onApplyNowSubmit(applyNowForm)">
            <div class="row mb-sm-2">
                <div class="col-sm-12 mb-3">
                    <label for="fullName" class="form-label font_16 color_20">
                        Full Name
                    </label>
                    <input type="text" class="form-control rounded-0" id="fullName" placeholder="Enter Full Name" formControlName="full_name"
                        required>
                </div>
                <div class="col-sm-12 mb-3">
                    <label for="emailId" class="form-label font_16 color_20">
                        Email ID
                    </label>
                    <input type="email" class="form-control rounded-0" id="emailId" placeholder="Enter Email Address" formControlName="email">
                </div>
                <div class="col-sm-12 mb-3">
                    <label for="mobileNumber" class="form-label font_16 color_20">
                        Mobile Number
                    </label>
                    <input type="tel" class="form-control rounded-0" id="mobileNumber"
                        placeholder="Enter Mobile Number" formControlName="mobile_no">
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-end">
                    <button [disabled]="loaderShow" class="btn btn-primary btn-round" type="submit">
                      <div *ngIf="loaderShow"><img class="img-fluid" src="./assets/images/loading-gif.gif" loading="lazy" alt=""></div>  Submit
                    </button>
                </div>
            </div>
        </form>
	</div>