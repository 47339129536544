<div class="header_dynamic_mgn_set"></div>
<div class="rv_blue_bg header-inverse d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="header justify_space_between w-100 ">
                    <div class="display_flex justify_space_between flex_align_center width_perc_100_mbl">
                        <a [routerLink]="appConstants.home" class="text-reset text-decoration-none"
                            (click)="homeTrigger()">
                            <div class="logo_section pointer">
                                <img src="/assets/images/logo.webp" class="logo_img_width" alt="STP">
                            </div>
                        </a>

                        <div class="d-flex align-items-center">
                            <div class="d-none pointer pe-3">
                                <div class="input-group">
                                    <ng-select *ngIf="toggleSearch" class="width_ngselect" [multiple]="false"
                                        [(ngModel)]="selectedCountryUrl" (change)="countryOnchange()"
                                        [disabled]="!countryList" [placeholder]="'Search Country'">
                                        <ng-option class="white_bg" *ngFor="let country of countryList"
                                            [value]="country.countryUrl" [disabled]="false">
                                            {{country.name}}
                                        </ng-option>
                                    </ng-select>
                                    <span class="input-group-append" (click)="search();"
                                        [ngClass]="{'inside': toggleSearch}">
                                        <div class="white_color serach_icon">
                                            <span class="material-icons-outlined">
                                                search
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <a (click)="goToLogin()"
                                class="text-reset text-decoration-none btn btn-primary rounded-0 d-md-none d-inline-block margin_right_12"
                                *ngIf="(!userName || !token) && isBrowser">
                                <div class="pointer commissionerflair_bold text-capitalize">
                                    Login
                                </div>
                            </a>
                            <!-- <a 
                                class="text-reset text-decoration-none rounded-0 d-md-none d-inline-block margin_right_12"
                                *ngIf="isLogin">
                                <div class="pointer commissionerflair_bold">
                                    Hi {{userName}} 
                                </div>
                            </a> -->
                            <!-- <div ngbDropdown class="d-inline-block" *ngIf="isLogin">
                                <span class="d-flex align-items-center white_color" id="dropdownBasic1"
                                    ngbDropdownToggle>
                                    Hi {{(userName.length < 7) ? userName : userName.slice(0,6)}} </span>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                            <button ngbDropdownItem>Action - 1</button>
                                            <button ngbDropdownItem>Another Action</button>
                                            <button ngbDropdownItem>Something else is here</button>
                                        </div>
                            </div> -->



                            <div class="me-2 pointer margin_lr_3 d-lg-none d-flex align-items-center"
                                *ngIf="(userName || token) && isBrowser">

                                <div ngbDropdown class="d-inline-block">
                                    <div class="d-flex align-items-center" id="dropdownBasic1" ngbDropdownToggle>
                                        <!-- <span class="material-icons-outlined">
                                            person_outline
                                        </span> -->
                                        <img src="./assets/icons/rvmp/yellow_person.svg" alt="" class="w_24">
                                        <span class="ms-2 fs-6 commissionerflair_medium white_color"
                                            style="vertical-align: super;">
                                            Hi {{(userName.length < 7) ? userName : userName.slice(0,6)}} </span>
                                    </div>
                                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" placement="bottom-left">

                                        <button *ngIf="isLogin" ngbDropdownItem [routerLink]="[myProfileUrl]"
                                            queryParamsHandling="preserve">
                                            My Profile
                                        </button>
                                        <button ngbDropdownItem class="danger" (click)="goToLogin()"
                                            *ngIf="!isLogin && showLoginBtn">
                                            Login/Signup
                                        </button>
                                        <button ngbDropdownItem class="danger" (click)="Logout()"
                                            *ngIf="isLogin">Logout</button>
                                    </div>
                                </div>
                            </div>






                            <div class="menu_icon white_color mt-2">
                                <span class="material-icons-outlined pointer" *ngIf="!showMenu" (click)="displayMenu()">
                                    menu
                                </span>
                                <span class="material-icons-outlined pointer" *ngIf="showMenu" (click)="closeMenu()">
                                    close
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="menu_container menu_width_none md_flex_align_center" id="menu">
                        <div class="overlay" (click)="closeMenu()"></div>
                        <div class="menu_body rv_blue_bg">

                            <div class="menu_content white_color">
                                <div class="d-none padding_lr_15 pointer margin_lr_3">
                                    <div class="input-group">
                                        <ng-select class="width_ngselect" [multiple]="false"
                                            [(ngModel)]="selectedCountryUrl" (change)="countryOnchange()"
                                            [disabled]="!countryList" [placeholder]="'Search Country'">
                                            <ng-option class="white_bg" *ngFor="let country of countryList"
                                                [value]="country.countryUrl" [disabled]="false">
                                                {{country.name}}
                                            </ng-option>
                                        </ng-select>
                                        <span class="input-group-append">
                                            <div class="serach_icon" (click)="searchMob();">
                                                <span class="material-icons-outlined">
                                                    search
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <div *ngFor="let mItem of menuItems">
                                    <a class="text-reset text-decoration-none" [href]="redirectionUrl+'/'+mItem?.router"
                                        *ngIf="!mItem?.isExternal">
                                        <!-- [routerLink]="mItem?.router" -->
                                        <div
                                            class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium font_16 mb-4 mb-md-0">
                                            {{mItem?.name}}
                                        </div>
                                    </a>
                                    <a class="text-reset text-decoration-none" [href]="mItem?.router"
                                        *ngIf="mItem?.isExternal" target="_blank">
                                        <!-- [routerLink]="mItem?.router" -->
                                        <div
                                            class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium font_16 mb-4 mb-md-0">
                                            {{mItem?.name}}
                                        </div>
                                    </a>
                                </div>

                                <!-- <a [routerLink]="appConstants.newsUrl" class="text-reset text-decoration-none"
                                    (click)="aboutUsTrigger()">
                                    <div
                                        class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium font_16 mb-4 mb-md-0">
                                        News
                                    </div>
                                </a>
                                <a [routerLink]="appConstants.flightUrl" class="text-reset text-decoration-none"
                                    (click)="aboutUsTrigger()">
                                    <div
                                        class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium font_16 mb-4 mb-md-0">
                                        Flights
                                    </div>
                                </a>
                                <a [routerLink]="appConstants.hotelUrl" class="text-reset text-decoration-none"
                                    (click)="aboutUsTrigger()">
                                    <div
                                        class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium font_16 mb-4 mb-md-0">
                                        Hotels
                                    </div>
                                </a>
                                <a [routerLink]="appConstants.consultantUsUrl" class="text-reset text-decoration-none"
                                    (click)="aboutUsTrigger()">
                                    <div
                                        class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium font_16 mb-4 mb-md-0">
                                        Visa Pro
                                    </div>
                                </a> -->

                                <div class="d-none padding_lr_15 pointer margin_lr_3">
                                    <div class="input-group">
                                        <ng-select *ngIf="toggleSearch" class="width_ngselect" [multiple]="false"
                                            [(ngModel)]="selectedCountryUrl" (change)="countryOnchange()"
                                            [disabled]="!countryList" [placeholder]="'Search Country'">
                                            <ng-option class="white_bg" *ngFor="let country of countryList"
                                                [value]="country.countryUrl" [disabled]="false">
                                                {{country.name}}
                                            </ng-option>
                                        </ng-select>
                                        <span class="input-group-append" (click)="search();"
                                            [ngClass]="{'inside': toggleSearch}">
                                            <div class="serach_icon">
                                                <span class="material-icons-outlined">
                                                    search
                                                </span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                                <a (click)="goToLogin()"
                                    class="text-reset text-decoration-none btn btn-primary rounded-0 d-none d-md-inline-block padding_lr_15 pointer margin_lr_3 margin_for_lgn_btn"
                                    *ngIf="(!userName || !token) && isBrowser">
                                    <div class="text-capitalize  commissionerflair_bold rv_blue_color fs-6">
                                        Login
                                    </div>
                                </a>

                                <div class=" padding_lr_15 pointer margin_lr_3 d-lg-flex d-xl-flex aling-items-center  d-none"
                                    *ngIf="(userName || token) && isBrowser">

                                    <div ngbDropdown class="d-flex align-items-center">
                                        <div class="d-flex align-items-center" id="dropdownBasic1" ngbDropdownToggle>
                                            <!-- <span class="material-icons-outlined">
                                                person_outline
                                            </span> -->
                                            <img src="./assets/icons/rvmp/yellow_person.svg" alt="" class="w_24">
                                            <span class="ms-2 me-2 fs-6 commissionerflair_medium white_color"
                                                style="vertical-align: super;">Hi {{userName}}</span>
                                        </div>
                                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" placement="bottom-left">

                                            <button *ngIf="isLogin" ngbDropdownItem [routerLink]="[myProfileUrl]"
                                                queryParamsHandling="preserve">
                                                My Profile
                                            </button>
                                            <button ngbDropdownItem class="danger" (click)="goToLogin()"
                                                *ngIf="!isLogin && showLoginBtn">
                                                Login/Signup
                                            </button>
                                            <button ngbDropdownItem class="danger" (click)="Logout()"
                                                *ngIf="isLogin">Logout</button>
                                        </div>
                                    </div>
                                </div>
                                <div class=" padding_lr_15 pointer margin_lr_3 d-lg-none d-xl-none" *ngIf="userName">

                                    <div ngbDropdown class="d-inline-block">
                                        <button type="button" class="btn log-btn" id="dropdownBasic1">
                                            <span class="material-icons-outlined">
                                                person_outline
                                            </span>
                                            <span class="ms-3 me-2" style="vertical-align: super;">{{userName}}</span>
                                        </button>
                                    </div>
                                </div>

                                <div class=" padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase d-lg-none d-xl-none"
                                    [routerLink]="[myProfileUrl]" queryParamsHandling="preserve" *ngIf="userName">
                                    MY PROFILE</div>
                                <div class=" padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase d-lg-none d-xl-none"
                                    [routerLink]="[appConstants.contactUsUrl]" (click)="Logout()" *ngIf="userName">
                                    LOGOUT</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="menu_modal">
    <ng-template #content let-modal>
        <div class="modal-body">
            <p>Please select the country to proceed furthure</p>
            <button type="button" class="btn btn-primary read-more converted_font_16 white_color" aria-label="Close"
                (click)="modal.dismiss()">
                Select The Country
            </button>
        </div>
    </ng-template>
</div>