import { UrlSegment, UrlMatchResult } from '@angular/router';

export function matcherFn(url: UrlSegment[], index: number) {
  const urlPath = url.join('/');
  const regex = /^([a-zA-Z0-9]+)(-([a-zA-Z0-9]+))*$/;
  if (regex.test(urlPath)) {
    // const segments: UrlSegment[] = [];
    const pathSegments = urlPath.split('-');
    let return_path = '';
    if (index == 2) return_path = `${pathSegments[pathSegments.length - 2]}-${pathSegments[pathSegments.length - 1]}`;
    if (index == 3) return_path = `${pathSegments[pathSegments.length - 3]}-${pathSegments[pathSegments.length - 2]}-${pathSegments[pathSegments.length - 1]}`;
    return return_path
  }
  return null
}

export function matcherFn2(url: UrlSegment[], index: number): string | null {
  const urlPath = url.map(segment => segment.path).join('/');
  const regex = /^([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*)\/([\w\-\/:.?&=+#%]+)$/;

  if (regex.test(urlPath)) {

    const urlSegments = urlPath.split('/');
    const pathSegments = urlSegments[0].split('-');
    let returnPath = '';

    if (index === 2) {
      returnPath = `${pathSegments[pathSegments.length - 2]}-${pathSegments[pathSegments.length - 1]}`;
    } else if (index === 3) {
      returnPath = `${pathSegments[pathSegments.length - 3]}-${pathSegments[pathSegments.length - 2]}-${pathSegments[pathSegments.length - 1]}`;
    }

    return returnPath;
  }

  return null;
}

// export function countryUrlCheck(url: UrlSegment[]): UrlMatchResult | null {
//   if (matcherFn(url, 2) == 'visa-online') return { consumed: url }
//   return null;
// }

// export function categoryUrlCheck(url: UrlSegment[]): UrlMatchResult | null {
//   if (matcherFn(url, 3) == 'visa-online-india') return { consumed: url }
//   return null;
// }

export function productUrlCheck(url: UrlSegment[]): UrlMatchResult | null {
  if (matcherFn(url, 3) == 'visa-apply-online') return { consumed: url }
  return null;
}

export function countryAllUrlCheck(url: UrlSegment[]): UrlMatchResult | null {
  if (matcherFn(url, 2) == 'visa-online') return { consumed: url }
  return null;
}

export function countryUrlCheck(url: UrlSegment[]): UrlMatchResult | null {

  if (matcherFn2(url, 2) === 'visa-online') {
    const typeSegment = url[2]?.path; // Capture the segment after 'visa-online', e.g., 'tourist'
    return {
      consumed: url.slice(0, 3), // Consume up to the 'tourist' segment
      posParams: {
        type: new UrlSegment(typeSegment || '', {}) // Add 'tourist' as 'type' parameter
      }
    };
  }
  return null;
}