import { Injectable } from '@angular/core';
import { ApolloClient, InMemoryCache } from '@apollo/client/core';

import { gql } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {

  environmentUrl: string = environment.apiUrl;
  testing: any;

  private apolloClient: ApolloClient<any>;

  constructor(private httpLink: HttpLink) {

    // this.apollo.create({
    //   link: this.httpLink.create({
    //     uri: this.environmentUrl,
    //   }),
    //   cache: new InMemoryCache(),
    // });

    this.apolloClient = new ApolloClient({
      link: this.httpLink.create({
        uri: this.environmentUrl,
      }),
      cache: new InMemoryCache(),
    });

  }






  getData(queryData: string, endPoint: string) {

    const gqlQuery = gql`${queryData}`;

    return this.apolloClient.query({
      query: gqlQuery,
      variables: {
        pageName: '/' + endPoint
      }
    })


  }





}

