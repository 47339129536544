import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SubmitService } from 'src/app/core/services/submit.service';

@Component({
  selector: 'stp-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent {
  applyNowForm: any = FormGroup;

  loaderShow: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private api: SubmitService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.resetForm();
  }

  closeModal() {
    this.activeModal.close('close');
  }
  resetForm(data?: any) {
    this.applyNowForm = this.fb.group({
      full_name: [data ? data.full_name : '', [Validators.required]],
      mobile_no: [data ? data.mobile_no : '', [Validators.required]],
      email: [data ? data.email : '', [Validators.email, Validators.required]],
    });
  }

  onApplyNowSubmit(form: any) {
    this.loaderShow = true;
    if (form.valid) {
      this.api.setApplyNowForm(form.value).subscribe((result: any) => {
        this.toastr.success('Apply Now Form Submitted Successfully!');
        this.loaderShow = true;
        this.closeModal();
      },
        error => {
          console.log(error.error);
          let error_text = '';
          for (const [key, value] of Object.entries(error.error)) {
            key;
            error_text = error_text + '\n' + value;
          }
          this.toastr.warning(error_text);
          this.loaderShow = false;
        });
    } else {
      this.toastr.warning('Please Fill And Submit!');
      this.loaderShow = false;
    }
  }
}
