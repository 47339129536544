export const GET_HOME_DESCRIPTIONS = `
  query {
    homeDescriptions{
        id
        mobileBannerImageUrl
        bannerImageUrl
        homeBannerImageUrl
        homeMobBannerImageUrl
        homeBannerButtonUrl
        countOnUsUrl
        faqUrl
        testimonialRating
        heading
        subHeading
    
   
    }
  }
`;

export const GET_ABOUTUS_ABOUT = `
    query {
        aboutUsAbout {
            id
            heading
            description
        }
    }
`;

export const GET_USP_DESCRIPTIONS = `
    query {
        uspFeatures {
          id
          heading
          description
          iconUrl
        }
    }
`;

// export const GET_POPULAR_DESTINATIONS_DATA = `
//     query {
//         productDetails{
//           id
//           heading
//           description
//           priceLabel
//           price
//           tax
//           imageUrl
//           keyValues
//           buttonUrl
//           buttonLabel
//           sideButtonUrl
//           sideButtonLabel
//           isStarred
//         }
//       }
// `;

export const GET_POPULAR_DESTINATIONS_DATA = `
query productDetails($variable1: Int!,$variable2: Int!)
{
  productDetails(limit:$variable1,offset:$variable2){
    id
    heading
    description
    priceLabel
    price
    tax
    imageUrl
    description
    buttonUrl
    buttonLabel
    keyValues
    sideButtonUrl
    sideButtonLabel
    priority
    isStarred
    countryPriority
    countryStarred
    categoryPriority
    categoryStarred 
  }
}
`;

export const GET_APPLI_STEPS_DATA = `
    query {
        homeApplicationSteps{
            id
            heading
            imageUrl
        }
    }
`;

export const GET_VISACATEGORY_DATA = `
    query {
        visaCategory {
            id
            name
            description
            countries{
                id
                name
                flag
                code
                countryUrl
                redirectUrl
                imageUrl
            }
        }
    }
`;

export const GET_HOME_FAQ_DATA = `
    query {
        faqGeneral{
            subCategories{
                id
                name
            }
    
        }
    }
`;

export const GET_USEFUL_MEDIA_DATA = `
    query {
        usefulMedias(limit:4,offset:0){
            id
            mediaType
            heading
            description
            datePublished
            linkUrl
            imageUrl
            authorImageUrl
            author{
                id
                name
            }
        }
    }
`;

export const GET_HOME_MEDIA_DATA = `
    query {
        homeMedias(limit:4,offset:0){
            id
            mediaType
            heading
            description
            datePublished
            imageUrl
            linkUrl
            authorImageUrl
            usefulMediaUrl
            author{
                id
                name
            }
        }
    }
`;

export const GET_PARTNERS_DATA = `
    query {
        trustedPartners{
            id
            iconUrl
            isStarred
            priority
        }
    }
`;

export const GET_TESTIMONIALS_DATA = `
    query {
        testimonials(limit:3,offset:0) {
            count
            id
            imageUrl
            heading
            description 
            author 
            stars
            isStarred
            priority
            country {
                name
                code
            }
        }
    }
`;

export const GET_COUNTRY_LIST = `
    query {
        country{
            id
            name
            countryUrl
            isStarred
            code
            searchKeyWords
        }
    }
`;

export const GET_SEO = `
    query {
        homeSeo {
            id
            seo{
              id
               title
               description
               canonicalUrl
               robotsIndex
               robotsFollow
               ogType
               ogSiteName
               ogLocale
               schema
               twitterCardType
               twitterSite
               twitterCreator
               twitterTitle
               twitterDescription
               twitterImageUrl
               twitterImageAlt
               facebookOgType
               facebookOgUrl
               facebookOgTitle
               facebookOgDescription
               facebookOgImageUrl
               facebookOgImageSecureUrl
               facebookOgImageType
               facebookOgImageWidth
               facebookOgImageHeight
               instagramTitle
               instagramImageUrl
               instagramImageWidth
               instagramImageHeight
               h1
               h2
               keywords
               data
            }
         }
    }
`;

export const GET_SEM_PAGES = `
  query semPages($urlParams: String!) {
    semPages(urlParams: $urlParams) {
      mainBannerUrl
      mobileBannerUrl
     positions {
      sectionName
      position
      sectionId
    }
       
      urlParams
      sections {
        section {
          heading
        }
         
        mainHeading
       additionalInformationHeading
        additionalInformationText
        subsections {
          id
          imageUrl
          heading
          text
          text1
          text2
          description
          buttonLabel
          buttonUrl
        }
      }
    }
  }
`;


//Start:- Revamping

export const GET_CATEGORY = `
    query {
        categoriesList{
            id,
            categoryName
            countries {
            id
            name
            code
            searchKeyWords
            countryUrl
            categoryUrl
            type
        }
    }
}`;

export const GET_YOUTUBE_DATA = `
    query {
        youtubeLinks{
          id,
          name,
          description,
          link,
          linkId
          
        }
      }`;




export const GET_PRIORITY_PARTNERS_DATA = `

    query trustedPartners($variable: String!){
        trustedPartners(type:$variable) {
            id
            iconUrl
            isStarred
            priority
            __typename
        }
    }
`;

export const GET_FOREX_CAROUSEL_DATA = `
    query carousel($variable: String!) {
    carousel(type: $variable) {
        id
        image
        mobileImage
        linkUrl
      __typename
    }
  }
`;

export const GET_COUNTRY_CAROUSAL_DATA = `
    query countryCarousel($countryName: String!) {
    countryCarousel(country: $countryName) {
        id
        image
        mobileImage
        __typename
    }
}`

export const GENERAL_FAQ = `
   query {
        generalFaqs {
            faqs {
                name
                faq {
                    question
                    answer
                }
            }
        }
    }`;


export const GET_DESTINATION_DATA = `
    query {
        productDetails{
            id
            heading
            description
            priceLabel
            price
            tax
            imageUrl
            description
            buttonUrl
            buttonLabel
            keyValues
            sideButtonUrl
            sideButtonLabel
            priority
            isStarred
            countryPriority
            countryStarred
            categoryPriority
            categoryStarred
            getBy
            country{
                id 
                name
                code
            }
        }
      }
`;








//End:- Revamping