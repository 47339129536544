
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DateHandlingService {

  curDateSetting() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    return yyyy + '/' + mm + '/' + dd;
    // return dd + '/' + mm + '/' + yyyy;
  }
}
