import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Injectable, OnInit, PLATFORM_ID,Inject } from '@angular/core';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';
import { AuthStateService } from './auth-state.service';
declare var webengage: any; // Declare the webengage variable

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  headers: any
  constructor(private http: HttpClient, private authStateSrvc: AuthStateService, public toastr: ToastrService, @Inject(PLATFORM_ID) private platformId: Object) { }


  getHeader() {
    if (isPlatformBrowser(this.platformId)) {
      let token = localStorage.getItem('token');
      if (token) {
        token = JSON.parse(token);

        this.headers = new HttpHeaders({
          'Content-Type': 'application/json',

          // Authorization: `Token ${token}`
          Authorization: `Token ${token}`
        });
      }
    }


  }

  isLoggedIn() {
    if (isPlatformBrowser(this.platformId)) {
      let token = localStorage.getItem('token');
      if (token != null && token != undefined && token != '') {
        this.authStateSrvc.setAuthenticated(true);
        return true;
      } else {
        this.authStateSrvc.setAuthenticated(false);
        return false
      }
    }
    return true;
  }

  isTokenAuthenticated(data: any) {

    return this.http.post(`${environment.adminApiUrl}/users/user-token-expiry/`, data).pipe(retry(2));
  }

  otpGeneration(data: any): Observable<any> {

    return this.http.post(`${environment.adminApiUrl}/users/customers/send-otp/`, data).pipe(retry(2));
  }

  verifyOtp(data: any): Observable<any> {

    return this.http.post(`${environment.adminApiUrl}/users/customers/verify-otp/`, data).pipe(retry(2));
  }

  createUser(data: any): Observable<any> {

    return this.http.post(`${environment.adminApiUrl}/users/customers/`, data).pipe(retry(2));
  }

  loginOtpGeneration(data: any): Observable<any> {

    return this.http.post(`${environment.adminApiUrl}/users/customers/send-login-otp/`, data).pipe(retry(2));
  }

  loginOtpForVerify(data: any): Observable<any> {
    return this.http.post(`${environment.adminApiUrl}/users/customers/otp-login/`, data).pipe(retry(2));
  }

  loginAndSignUpOtpForVerify(data: any): Observable<any> {

    return this.http.post(`${environment.adminApiUrl}/users/customers/otp-login/?is_register=true`, data).pipe(retry(2));
  }

  logout(): Observable<any> {
    this.getHeader();
    this.tokenClear();
    if (isPlatformBrowser(this.platformId)) {
      webengage.user.logout();
    }
    return this.http.post(`${environment.adminApiUrl}/logout/`, {}, { headers: this.headers }).pipe(retry(2));
  }

  logoutWithoutReload(): Observable<any> {
    this.getHeader();
    this.tokenClearWithoutReload();
    if (isPlatformBrowser(this.platformId)) {
      webengage.user.logout();
    }
    return this.http.post(`${environment.adminApiUrl}/logout/`, {}, { headers: this.headers }).pipe(retry(2));
  }

  tokenClear() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('token', '');
      localStorage.setItem('user_details', '');
      location.reload();
      this.toastr.success("You are successfully logged out");
    }


  }

  tokenClearWithoutReload() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('token', '');
      localStorage.setItem('user_details', '');
      // this.toastr.success("You are successfully logged out");
    }


  }


  checkValidToken(token: any) {
    return this.http.post(`${environment.adminApiUrl}/users/user-token-expiry/`, { "token": token }).pipe(retry(2));
  }

  phoneNumberVerify(phoneNum: string) {
    return this.http.get(`${environment.adminApiUrl}/users/customers-detail/?phone_no=${phoneNum}`,).pipe(retry(2));
  }


  // #region :- revamp

  emailPhoneOtpGeneration(data:any) {
    return this.http.post(`${environment.adminApiUrl}/users/customers/send-one-time-password/`,data).pipe(retry(2));
    
  }

  emailPhoneOtpVerification(data:any) {
    return this.http.post(`${environment.adminApiUrl}/users/customers/verify-one-time-password/`,data).pipe(retry(2));
    
  }

  emailPhoneSignUp(data:any) {
    return this.http.post(`${environment.adminApiUrl}/users/customers/create-new-user/`,data).pipe(retry(2));
    
  }




  // #endregion :- revamp

    // agent start

    verifyUser(data:any){
      return this.http.post(`${environment.adminApiUrl}/users/customers/verify-user/`,data);      
    }
  

    agentRegister(data:any): Observable<any>{
      return this.http.post(`${environment.adminApiUrl}/users/agent-register/`,data);      
    }
  
  
    // agent-end    
    

}
