import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from './confirmation.component';

@Injectable()
export class ConfirmationService {

  constructor(private modalService: NgbModal) { }

  public confirm(
    title: string,
    message: string,
    redText: string,
    yellowText: string,
    dialogSize: string): Promise<boolean> {
    const modalRef = this.modalService.open(ConfirmationComponent, { size: dialogSize,centered:true });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.btnOkText = yellowText;
    modalRef.componentInstance.btnCancelText = redText;

    return modalRef.result;
  }

}
