<div class="modal-body p-0 sign-in-modal">
  <div class="sign-in user-select-none position-relative" *ngIf="!isLoginSuccess">
    <div class="d-flex flex-wrap justify-content-center align-items-stretch" *ngIf="carouselImage">

      <div class="sign_in_width_ctrl position-relative">
        <div class="close_button_for_mbl d-md-none">
          <button type="button" class="btn-close" aria-label="Close" data-bs-dismiss="modal" (click)="close()"></button>
        </div>
        <!-- d-none d-md-block -->
        <stp-login-carousel [componentData]="carouselImage" class="h-100" *ngIf="showCarousel"></stp-login-carousel>
      </div>
      <div class=" sign_in_width_ctrl d-flex justify-content-center align-items-center flex-wrap position-relative">
        <div class="w-100">

          <div>
            <div class="signin_form_outer_cont">
              <div class="d-flex justify-content-between mb-4">
                <div class="blue_color">
                  <span class="commissionerflair_medium rv_blue_color  font_12 fst-italic me-1">Secured with</span>

                  <img src="./assets/images/aws.png" loading="lazy" alt="loader" loading="lazy" />
                </div>
                <div>
                  <button type="button" class="btn-close d-none d-md-block" aria-label="Close" data-bs-dismiss="modal"
                    (click)="close()"></button>
                </div>
              </div>
              <div *ngIf="!isOtpGenerated && !isDateRange && !isStatusShow">
                <div class="rv_blue_color">
                  <div class="fs-4 commissionerflair_semibold">Welcome Traveller!</div>
                  <div class="font_14 commissionerflair_medium color_506263">Build your global visa profile</div>
                </div>
              </div>
              <div *ngIf="!isOtpGenerated && !isDateRange && !isStatusShow && !showVisaPro" class="mt-4">
                <!-- <div class="text-center">
                <div class="heading_h2 secondary_color text-center mb-3">
                  Sign up / Login
                </div>
              </div> -->
                <form [formGroup]="otpGenerationForm" (ngSubmit)="generateOtp()">
                  <div class="form-outline mb-1" *ngIf="!isSignUp">
                    <label class="font_14 color_20 commissionerflair_medium" for="form2Example1">
                      {{isEmail ? 'Email ID' : ' Phone Number'}}
                    </label>
                    <div class="d-flex align-items-stretch position-relative">

                      <!-- #region  Note:- section for showing prefix of the phone number -->
                      <div *ngIf="!isEmail"
                        class="text-muted d-flex justify-content-center align-items-center user-select-none input_prefix_align h-100">
                        <img src="./assets/images/phone_flag_India.svg" alt="Loading....">
                        <span class="prefix_num_margin black_color commissionerflair_regular font_14">+91</span>

                        <div class="h-100 pre_border_set"></div>
                      </div>
                      <!-- #endregion Note:- section for showing prefix of the phone number -->

                      <input [ngClass]="{ prefix: showPrefix || otpGenerationForm.value.phone_no }" type="number"
                        id="signin_phone_id" placeholder="Enter Your Number"
                        class="form-control padding_left_for_pref rounded-0 blue_color commissionerflair_medium input_h_48 font_14"
                        formControlName="phone_no" (blur)="showPrefix = false" autocomplete="no" *ngIf="!isEmail"
                        [ngStyle]="{'border-color' : (otpGenerationForm?.controls?.phone_no?.errors?.pattern && otpGenerationForm?.value?.phone_no?.toString().length>=10) ? '#EF4141':'#CED3D4'}" />
                      <!-- ^((\\+91-?)|0)?[0-9]{10}$  -->
                      <!-- ^(?:\\+91|0)?[5-9]\\d{9}$ -->

                      <input [ngClass]="{
                        prefix: showPrefix || otpGenerationForm.value.phone_no
                      }" type="text" id="signin_email_id" placeholder="Enter Your Email ID"
                        class="form-control  rounded-0" formControlName="phone_email" *ngIf="isEmail"
                        [ngStyle]="{'border-color' : (otpGenerationForm?.controls?.phone_email?.errors?.pattern) ? '#EF4141':'#CED3D4'}"
                        (input)="domainSelector('phone_email')" />

                      <div class="email_domains overflow-hidden {{domainsToShow.length ? '':'w_h_zero'}}"
                        *ngIf="isEmail && domainsToShow">
                        <ul class="domain_parent_group">
                          <li *ngFor="let domain of domainsToShow;let i=index" class="pointer domain_parent_item"
                            (click)="insertAtAfter(domain,i,'phone_email')">
                            {{domain}}
                          </li>
                        </ul>
                      </div>

                    </div>

                    <div class="red_color">
                      <!-- class="min_h_24" -->
                      <!-- <small class="text-warning mt-1" *ngIf="!isRegistered">{{isEmail ? 'Email is' : 'Mobile number is'}}
                    not registered!</small> -->
                      <!-- <div *ngIf="otpGenerationForm.controls.phone_no.errors?.required">Mobile Number is required.</div> -->
                      <div
                        *ngIf="(otpGenerationForm?.controls?.phone_no?.errors?.pattern && otpGenerationForm?.value?.phone_no?.toString().length>=10) || otpGenerationForm?.value?.phone_no?.toString().length>=11">
                        Please enter the correct phone number
                      </div>
                      <div *ngIf="otpGenerationForm?.controls?.phone_email?.errors?.pattern">
                        Please enter the correct email ID
                      </div>
                      <!-- <small class="text-warning mt-1" *ngIf="!isRegistered">Mobile number not registered! <a
                                    class="fw-bolder pointer blue_link text-warning" (click)="signUp()">Register</a> to
                                continue.</small> -->
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <div
                          class="d-flex justify-content-end commissionerflair_medium blue_color font_12  text-decoration-underline">
                          <span class="pointer" (click)="useEmail()">
                            {{isEmail ? 'Use Phone Number' : ' Use Email ID'}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div *ngIf="isSignUp">
                    <div class="form-outline mb-4">
                      <label class="form-label font_14 rv_blue_color commissionerflair_medium" for="form2Example1">
                        Full Name
                      </label>
                      <input type="text" id="form2Example1" placeholder="Enter Full Name"
                        class="form-control font_14 commissionerflair_medium input_h_48" formControlName="name"
                        [ngStyle]="{'border-color' : (otpGenerationForm.controls['name'].errors?.minlength && otpGenerationForm.controls['name'].touched) ? '#EF4141':'#CED3D4'}" />
                      <small class="text-warning mt-1"
                        *ngIf="otpGenerationForm.controls['name'].errors?.minlength && otpGenerationForm.controls['name'].touched">
                        Name must be at least 3 characters long.
                      </small>
                    </div>
                    <!-- Email input -->
                    <div class="form-outline mb-4 position-relative" *ngIf="!isEmail">
                      <label class="form-label font_14 rv_blue_color commissionerflair_medium" for="form2Example1">
                        Email ID
                      </label>
                      <input type="email" id="form2Example1" placeholder="Enter Email"
                        class="form-control font_14 commissionerflair_medium input_h_48" formControlName="email"
                        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$" (input)="domainSelector('email')" />
                      <small class="text-warning mt-1"
                        *ngIf="otpGenerationForm.controls.email.invalid && (otpGenerationForm.controls.email.dirty || otpGenerationForm.controls.email.touched)">Please
                        enter the
                        correct email ID</small>
                      <div class="email_domains overflow-hidden {{domainsToShow.length ? '':'w_h_zero'}}"
                        *ngIf="!isEmail && domainsToShow">
                        <ul class="domain_parent_group">
                          <li *ngFor="let domain of domainsToShow;let i=index" class="pointer domain_parent_item"
                            (click)="insertAtAfter(domain,i,'email')">
                            {{domain}}
                          </li>
                        </ul>
                      </div>
                    </div>


                    <div class="form-outline mb-4" *ngIf="isEmail">
                      <label class="form-label font_16 color_20" for="form2Example1">
                        Phone Number
                      </label>
                      <div class="d-flex align-items-stretch position-relative">
                        <!-- <div
                        class="text-muted me-2 d-flex justify-content-center align-items-center px-4 pre_border_set user-select-none">
                        +91
                      </div> -->
                        <div
                          class="text-muted d-flex justify-content-center align-items-center user-select-none input_prefix_align h-100">
                          <img src="./assets/images/phone_flag_India.svg" alt="Loading...." loading="lazy">
                          <span class="prefix_num_margin">+91</span>

                          <div class="h-100 pre_border_set"></div>
                        </div>

                        <input
                          [ngStyle]="{'border-color' : ((otpGenerationForm?.controls?.phone?.errors?.pattern && otpGenerationForm?.value?.phone?.toString().length>=10) || otpGenerationForm?.value?.phone?.toString().length>=11) ? '#EF4141':'#CED3D4'}"
                          type="number" id="phone_for_sign_up" placeholder="Enter Your Number"
                          class="form-control padding_left_for_pref rounded-0 input_h_48 commissionerflair_medium font_14    "
                          formControlName="phone" (focus)="showPrefix = true" (blur)="showPrefix = false"
                          autocomplete="no" />

                      </div>
                      <small
                        *ngIf="(otpGenerationForm?.controls?.phone?.errors?.pattern && otpGenerationForm?.value?.phone?.toString().length>=10) || otpGenerationForm?.value?.phone?.toString().length>=11"
                        class="text-warning mt-1">
                        Please enter the correct phone number
                      </small>

                    </div>

                  </div>


                  <div class="row">
                    <div class="col-md-12 mt-3 mb-2 text-center">
                      <button type="submit"
                        class="py-2 btn btn-blue btn_height_set read-more w-100 font_14 commissionerflair_medium {{!otpGenerationForm.valid ? 'disable_text_color' : 'white_color'}}"
                        *ngIf="!isSignUp" [disabled]="!otpGenerationForm.valid || otpGenerationLoading">
                        <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif"
                          loading="lazy" alt="loader" />

                        Send OTP
                      </button>
                      <button type="button"
                        class="py-2 btn btn-blue read-more w-100 btn_height_set commissionerflair_medium font_14 "
                        *ngIf="isSignUp" [disabled]="!otpGenerationForm.valid" (click)="signUp()">
                        <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif"
                          loading="lazy" alt="loader" />
                        Create Profile
                      </button>
                    </div>
                    <!-- <div class="font_18 color_20 mb-3 ">
                        Continue without&nbsp;<u (click)="goToPage()"
                            class="fw-bold blue_link pointer text-decoration-none">Login</u>
                    </div>
                    <div class="col-md-12 d-flex justify-content-center mt-4">
                        New to Stampthepassport
                        <span class="ps-2 pointer fw-bold blue_link" (click)="signUp()">
                            Register Now
                        </span>
                    </div> -->
                    <div class="mb-5 commissionerflair_regular">
                      <div class="form-check form-check-inline">
                        <input class="form-check-input cons_check rounded-0 font_16 pointer" type="checkbox"
                          id="allowed_consent" (change)="consentCheck($event)" [checked]="allowedConsent">
                        <label class="form-check-label pointer mt-1 font_12 inpt_text_botom_color"
                          for="allowed_consent">Send me visa related offers on
                          Whatsapp/Email</label>
                      </div>

                    </div>
                    <div class="text-center px-2" *ngIf="!isOtpGenerated && !isDateRange && !isStatusShow">
                      <div class="mx-5 font_10 inpt_text_botom_color">
                        <div class="commissionerflair_regular ">
                          *By proceeding, you agree to STP's
                          <span class="signin-privacy ">
                            <!-- (click)="gotoPrivacyPolicy()" -->
                            <a [routerLink]="[appConstants.privacyPolicyUrl]" target="_blank"
                              class="text-reset text-decoration-none pointer">
                              Privacy Policy
                            </a>

                          </span>
                          <span class="signin-privacy ">
                            <!-- (click)="gotoUserAgreement()" -->
                            <a [routerLink]="[appConstants.userAgreement]" target="_blank"
                              class="text-reset text-decoration-none pointer">
                              User Agreement
                            </a>
                          </span>
                          and
                          <span class="signin-privacy ">
                            <!-- (click)="gotoTandC()" -->

                            <a [routerLink]="[appConstants.termsConditions]" target="_blank"
                              class="text-reset text-decoration-none pointer">
                              T&Cs
                            </a>
                          </span>
                        </div>
                        <!-- <a class="signin-privacy pointer" href="/privacy-policy">
                          *By proceeding, you agree to STP's Privacy Policy, User
                          Agreement and T&Cs
                      </a> -->

                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div *ngIf="isOtpGenerated && !isDateRange">
                <!-- <div class="text-center">
                <div class="heading_h2 secondary_color text-center mb-0">
                  Mobile Number
                </div>
              </div> -->
                <div class="secondary_color pb-4">
                  <div class="fs-4 commissionerflair_semibold">OTP Verification</div>
                  <div class="font_14 commissionerflair_medium d-flex align-items-center color_506263">
                    OTP sent on
                    <div class=" d-flex align-items-center ms-2 rv_blue_color commissionerflair_semibold">
                      <span class="text-decoration-underline">{{ isEmail ? this.otpGenerationForm.value.phone_email :
                        otpGenerationForm.value.phone_no
                        }}</span>
                      <span class="material-icons-outlined ms-2 pointer fs-6" (click)="goBackToPhoneEnter()">
                        edit
                      </span>
                    </div>
                  </div>
                </div>

                <!-- <div class="commissionerflair_regular fs-5 my-3 text-center">
                OTP is shared on Whatsapp and Email
              </div> -->
                <form [formGroup]="otpVariForm" (ngSubmit)="otpVerify()">
                  <div class="form-outline mb-3">
                    <div class="inputfield" formArrayName="otpFormArray">
                      <input type="number" maxlength="1" min="0" max="9" required
                        *ngFor="let input of otpInputs.controls; let i = index" autocomplete="no" [formControlName]="i"
                        (input)="nextElem($event, i)" id="otpNum{{ i }}" (paste)="onPasteOtp($event)" (focus)="curFocunHandl($event, i)" 
                        class="input me-2 "
                        [ngClass]="{'input_otp_border': otpInputs.controls[i].value?.toString().length > 0}" />
                    </div>
                  </div>

                  <div class="mt-4 mb-3 text-center">
                    <button type="submit" class="py-2 btn btn-blue read-more w-100" tabindex="0" id="proceed-button"
                      [disabled]="!otpVariEnblToProcd">
                      <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif"
                        loading="lazy" alt="loader" />
                      Verify OTP

                    </button>
                  </div>

                  <div
                    class="commissionerflair_regular text-center secondary_color font_14 mb-4 pt-3 d-flex align-items-center justify-content-center color_506263">
                    Didn’t get OTP?
                    <span class="d-flex align-items-center" (click)="resendOtp()">
                      <span class="pointer">Resend in <span class="commissionerflair_semibold font_14">{{ timeToShow
                          }}</span></span>
                      <img class="me-1" *ngIf="otpGenerationLoading" width="15" src="./assets/images/loading-gif.gif"
                        loading="lazy" alt="loader" />
                    </span>
                  </div>

                  <!-- <div class="row">
                            <div class="col-8">
                                Resend OTP in {{timeToShow}} Minutes
                            </div>
                            <div class="col-4 text-end">
                                <span (click)="resendOtp()" class="pointer blue_link" tabindex="-1">Resend
                                    OTP</span>
                            </div>

                        </div> -->

                  <div class="commissionerflair_regular text-center secondary_color font_10"
                    *ngIf="!isOtpGenerated && !isDateRange && !isStatusShow">
                    *By proceeding, you agree to StampThaPassport's Privacy Policy,
                    User Agreement and T&Cs
                  </div>
                </form>
              </div>

              <div *ngIf="isDateRange && curDateSet && !isStatusShow ">
                <!-- <div class="text-center">
                  <div class="heading_h2 secondary_color text-center mb-3">
                    Lets Get Started!
                  </div>
                </div> -->
                <div class="commissionerflair_regular fs-4 text-center">
                  <!-- Select your Intended Dates of Travel, from <b>Start</b> to <b>End</b> -->
                  Please select your intended dates of <b>Departure</b> and
                  <b>Return</b>
                </div>
                <div class="mb-3 text-center font_12">
                  *Share tentative dates if dates are not confirmed
                </div>
                <div class="text-center">
                  <!--If condition added-->
                  <ngx-daterangepicker-material *ngIf="curDateSet" type="text" matInput ngxDaterangepickerMd
                    [locale]="{ format: 'DD-MM-YYYY', displayFormat: 'DD-MM-YYYY' }" startKey="start" endKey="end"
                    [alwaysShowCalendars]="true" (choosedDate)="choosedDate($event)" name="daterange" [autoApply]="true"
                    [minDate]="curDateSet"></ngx-daterangepicker-material>
                </div>

                <div class="text-center px-3 commissionerflair_regular color_002024bf">
                  <div class="text-center fs-6">
                    <!-- {{this.paramsData?.country}} visa processing time is {{processingTimeColtn?.limit_day}}
                            days, you will get your visa by {{processingTimeColtn?.start_date}} *if
                            your DOT is
                            not
                            confirm, share your tentative date -->
                    <!-- <div>
                {{ this.paramsData?.country }} visa processing time :
                <span class="commissionerflair_semibold">
                  <b>{{ processingTimeColtn?.limit_day }} working days*</b>
                </span>
              </div> -->
                    <div>
                      Get your visa by :
                      <span class="commissionerflair_semibold">
                        <b>{{ processingTimeColtn?.start_date | dateAsMonth }}</b>
                      </span>
                    </div>

                    <div class="text-center font_12 mt-1">
                      *This timeline is subject to change under certain conditions.
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 mt-3 mb-2 text-center">
                    <div class="mx-3">
                      <button type="button" class="py-2 btn btn-primary read-more w-100 rounded-2"
                        (click)="dateRangeSubmit()">
                        <!-- [disabled]="
                    !this.selectedDateRange?.startDate &&
                    !this.selectedDateRange?.endDate
                  " -->
                        <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif"
                          loading="lazy" alt="loader" />
                        <span>
                          {{
                          selectedDateRange?.startDate ==
                          selectedDateRange?.endDate && selectedDateRange
                          ? "continue with same Dates"
                          : "continue"
                          }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isStatusShow && !showVisaPro">
                <div class="text-center">
                  <div class="heading_h2 secondary_color text-center mb-3">
                    We Are On Track!
                  </div>
                </div>
                <div class="posistio-relative">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                      <div
                        class="icon_visa_tick_width rounded-circle dark_yellow_bg d-flex justify-content-center align-items-center me-2">
                        <img src="assets/icons/tick.svg" />
                      </div>
                      <div>
                        <div class="commissionerflair_semibold black_color converted_font_20">
                          Date of Travel
                        </div>
                        <div class="green_color commissionerflair_semibold converted_font_16">
                          Completed
                        </div>
                      </div>
                    </div>
                    <div class="black_color converted_font_16 commissionerflair_semibold"></div>
                  </div>
                  <div class="position-relative pb-5 my-2">
                    <div class="vertical_line primary_bg_color h-100"></div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                      <div
                        class="icon_visa_tick_width rounded-circle gray_bg_color d-flex justify-content-center align-items-center me-2 converted_font_16 commissionerflair_semibold black_color">
                        <!-- [ngClass]="{
                  dark_yellow_bg: checkDiyOrVisaPro === 'false',
                  gray_bg_color: checkDiyOrVisaPro === 'true'
                }" -->
                        <!-- <span *ngIf="checkDiyOrVisaPro === 'true'"> 2 </span>
                  <span *ngIf="checkDiyOrVisaPro === 'false'">
                    <img src="assets/icons/tick.svg" />
                  </span> -->
                        <span>2</span>
                      </div>
                      <div>
                        <div class="commissionerflair_regular black_color converted_font_20">
                          {{
                          checkDiyOrVisaPro === "true"
                          ? "Share Details"
                          : "Number of Travellers"
                          }}
                        </div>
                        <div class="commissionerflair_medium converted_font_16 orange_color">
                          <!-- [ngClass]="{
                    green_color: checkDiyOrVisaPro === 'false',
                    orange_color: checkDiyOrVisaPro === 'true'
                  }" -->
                          <!-- <span *ngIf="checkDiyOrVisaPro === 'false'">
                      Completed
                    </span> -->
                          <span> Next </span>
                        </div>
                      </div>
                    </div>
                    <div class="black_color converted_font_16 commissionerflair_semibold">
                      {{ checkDiyOrVisaPro === "true"
                      ? "5 min"
                      : "1 Min"}}

                    </div>
                  </div>
                  <div class="position-relative pb-5 my-2">
                    <div class="vertical_line gray_bg_color h-100"></div>
                    <!-- [ngClass]="{
                primary_bg_color: checkDiyOrVisaPro === 'false',
                gray_bg_color: checkDiyOrVisaPro === 'true'
              }" -->
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                      <div
                        class="icon_visa_tick_width rounded-circle gray_bg_color d-flex justify-content-center align-items-center me-2 converted_font_16 commissionerflair_semibold black_color">
                        <span> 3 </span>
                        <!-- <span *ngIf="checkDiyOrVisaPro === 'false'">
                  <img src="assets/icons/tick.svg" />
                </span> -->
                      </div>
                      <div>
                        <div class="commissionerflair_regular black_color converted_font_20">
                          {{
                          checkDiyOrVisaPro === "true"
                          ? "Upload Documents"
                          : "Pay Online"
                          }}
                        </div>
                        <div class="commissionerflair_medium converted_font_16 gray_color">
                          <!-- [ngClass]="{
                    orange_color: checkDiyOrVisaPro === 'false',
                    gray_color: checkDiyOrVisaPro === 'true'
                  }" -->
                          <!-- <span *ngIf="checkDiyOrVisaPro === 'false'"> Next </span> -->
                          <span> Upcoming </span>
                        </div>
                      </div>
                    </div>
                    <div class="black_color converted_font_16 commissionerflair_semibold">
                      {{ checkDiyOrVisaPro === "true"
                      ? "5 min"
                      : "1 Min"}}
                    </div>
                  </div>
                  <div class="position-relative my-2 pb-5">
                    <div class="vertical_line gray_bg_color h-100"></div>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                      <div
                        class="icon_visa_tick_width rounded-circle gray_bg_color d-flex justify-content-center align-items-center me-2 converted_font_16 commissionerflair_semibold black_color">
                        4
                      </div>
                      <div>
                        <div class="commissionerflair_regular black_color converted_font_20">
                          {{
                          checkDiyOrVisaPro === "true"
                          ? "Pay Online"
                          : "Schedule-Apply with Expert"
                          }}
                        </div>
                        <div class="commissionerflair_medium converted_font_16 gray_color">
                          Upcoming
                        </div>
                      </div>
                    </div>
                    <div class="black_color converted_font_16 commissionerflair_semibold"></div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 mt-3 mb-2 text-center">
                    <div class="mx-3">
                      <button type="button" class="py-2 btn btn-primary read-more w-100 rounded-2"
                        (click)="goToPassport()">
                        <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif"
                          loading="lazy" alt="loader" />
                        <span>continue</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="showVisaPro" class="user-select-none">
                <div class="text-center">
                  <div class="heading_h2 secondary_color text-center mb-3 user-select-none">
                    Traveller
                  </div>
                </div>
                <div class="posistio-relative">
                  <div class="pax_adding_section commissionerflair_regular converted_font_16 secondary_color">
                    Add Number of Travellers
                  </div>
                  <div class="my-3">
                    <div
                      class="py-2 px-3 pax_add_input d-flex justify-content-between align-items-center commissionerflair_regular">
                      <div class="d-flex align-items-center pointer" (click)="visproPaxRemove()">
                        <span class="material-icons-outlined"> remove </span>
                      </div>
                      <div>{{ visaProPaxNumber }}</div>
                      <div class="d-flex align-items-center pointer" (click)="visproPaxAdd()">
                        <span class="material-icons-outlined"> add </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-center px-3 commissionerflair_regular color_002024bf d-none d-md-block">
                  <div class="text-center font_10">
                    <div class="text-center px-3 commissionerflair_regular color_002024bf">
                      <div class="text-center font_10">
                        Pay only for the Service Fee based on the number of travellers
                        and rest later after once experts verifies yours documents
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row d-none d-md-block">
                  <div class="col-md-12 mt-3 mb-2 text-center">
                    <div class="mx-3 d-flex justify-content-between align-items-center">
                      <div class="commissionerflair_bold fs-4 secondary_color">
                        &#8377; {{ visaProPrice }}
                      </div>
                      <button type="button" class="py-2 btn btn-primary read-more rounded-2"
                        (click)="uploadVisProPax()">
                        <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif"
                          loading="lazy" alt="loader" />
                        <span>Pay Now</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="showVisaPro"
                class="text-center px-3 commissionerflair_regular color_002024bf visa-pro-text  d-block d-md-none">
                <div class="text-center font_10">
                  <div class="text-center px-3 commissionerflair_regular color_002024bf">
                    <div class="text-center font_10  ">
                      Pay only for the Service Fee based on the number of travellers
                      and rest later after once experts verifies yours documents
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="showVisaPro">
                <div class="text-center visa-pro-paynow d-block d-md-none">
                  <div class="mx-3 d-flex justify-content-between align-items-center">
                    <div class="commissionerflair_bold fs-4 secondary_color">
                      &#8377; {{ visaProPrice }}

                      <span class="material-icons-outlined info-button"
                        (click)="mobilePaymentView(mblPaymeDetailsModal)">
                        info
                      </span>


                    </div>

                    <button type="button" class="py-2 btn btn-primary read-more rounded-2" (click)="uploadVisProPax()">
                      <img class="me-1" *ngIf="isLoading" width="15" src="./assets/images/loading-gif.gif"
                        loading="lazy" alt="loader" />
                      <span>Pay Now</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isLoginSuccess">
    <div class="position-relative">
      <img src="./assets/images/login_success_img.webp" alt="" class="w-100" loading="lazy">
      <div class="sign_up_gif text-center">
        <img src="./assets/images/logo_animation.gif" alt="" loading="lazy">
      </div>
    </div>


    <div class="d-flex justify-content-center">
      <div class="login_success_content">
        <div class="blue_color commissionerflair_bold fs-2 text-center">You are ready to fly!</div>
        <div class="gray_blue_color fs-5 commissionerflair_semibold text-center">
          You have successfully created your account
        </div>
        <button type="button" class="py-2 btn btn-blue read-more w-100 mt-5" (click)="close()">
          Continue
        </button>
      </div>
    </div>


  </div>
  <ng-template #mblPaymeDetailsModal let-modal>
    <div class="visa_details p-2">
      <div class="row">
        <div class="col-md-12">
          <div class="d-flex justify-content-between">
            <h3 class="commissionerflair_semibold color_20 font_24">
              Price Summary
            </h3>
            <button type="button" class="btn-close" aria-label="Close" (click)="close()"
              data-bs-dismiss="modal"></button>

          </div>
          <hr />
        </div>
      </div>
      <div class="row section_seperation">
        <div class="text-start">
          <div class="color_20 font_16 margin_bottom_24"
            *ngFor="let item of priceSummaryData?.fee_details; let i = index">
            <div *ngIf="item?.fee_category != 'Total'" class="d-flex justify-content-between">
              {{ item.fee_category }}
              <span class="commissionerflair_semibold">{{ item.currency_symbol }} {{ item.price }}</span>
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-between commissionerflair_bold">
              <span>Total</span>
              {{ priceSummaryData?.fee_details[0].currency_symbol }}
              {{ priceSummaryData?.total }}
            </div>
          </div>
        </div>
      </div>

      <div class="item_align_end" *ngIf="priceSummaryData">
        <div class="row">
          <div class="col-12">
            <div class="horizontal_line"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div>
              <div class="d-flex justify-content-between commissionerflair_bold">
                <span>Payable</span>
                {{ priceSummaryData?.fee_details[0].currency_symbol }}
                {{ priceSummaryData?.payable_amount }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>