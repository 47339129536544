export const environment = {
  production: false,
  apiUrl: 'https://dev-cms.stampthepassport.com',
  siteUrl: 'https://dev-web.stampthepassport.com/',
  _siteUrl: 'https://dev-web.stampthepassport.com',
  _siteUrl_none_hash: 'https://dev-web.stampthepassport.com/',
  adminApiUrl: 'https://dev-api.stampthepassport.com',
  imageUrl: '',
  countryUrl:
    'https://stp-data-bucket.s3.ap-south-1.amazonaws.com/general/countries/',
  useHash: false,
  s3BucketUrl: 'https://stp-data-bucket-staging.s3.ap-south-1.amazonaws.com/cms_dev',
  seos3BucketUrl: 'https://stp-prod-bucket.s3.ap-south-1.amazonaws.com/',

};
