
export class Constants {
  componentName: string = 'ContactUsComponent';
}

export const GET_GENERAL_FAQ_GQL = `
    query {
        faqGeneral{
            subCategories{
                id
                name
            }
    
        }
    }
`;

export const GET_CONTACT_DESCRIPTION_GQL = `
    query {
        contactUsDescription{
            id
            email
            phoneNo
            subHeading
            heading
        }
    }
`;
export const GET_CONTACT_SIDEINFO_GQL = `
    query {
        contactUsSideinfo{
            id
            address
            phoneNo
            subHeading
            heading
            createdAt
            addressUrl
        }
    }
`;
