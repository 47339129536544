import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubmitService {

  constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) { }

  setSubscription(data: any): Observable<any> {
    let windData;
    if (isPlatformBrowser(this.platformId)) {
       windData = window.location.hostname;

    }
    const dataToSend = { ...data, source_url: windData }
    return this.http.post(`${environment.adminApiUrl}/lead-collection/news-letter/`, dataToSend);
  }
  setContactForm(data: any): Observable<any> {
    return this.http.post(`${environment.adminApiUrl}/lead-collection/contact-us-leads/`, data);
  }
  setApplyNowForm(data: any): Observable<any> {
    return this.http.post(`${environment.adminApiUrl}/lead-collection/visa-lead/`, data);
  }
}
