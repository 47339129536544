import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { GetDataService } from 'src/app/core/services/get-data.service';
import { SubmitService } from 'src/app/core/services/submit.service';
import * as GraphQlConsta from './constants';
import { isPlatformBrowser } from '@angular/common';
declare var webengage: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  contactForm: any = FormGroup;

  componentName: any;
  generalFaq: any;
  contactDescription: any;
  contactUsSideinfo: any = [];

  loaderShow: boolean = false;
  buttonDisable: boolean = false;

  selectedHeading: string = '';
  selectedSideInfo: any;

  constructor(
    public cdr: ChangeDetectorRef,
    public getDataService: GetDataService,
    private fb: FormBuilder,
    private api: SubmitService,
    private toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}
  ngOnInit(): void {
    this.getGeneralFaq();
    this.getContactUsDescription();
    this.getContactUsSideInfo();
    this.resetForm();
  }

  setMapAddress(address: any) {
    let iframe: any = document.getElementById('contactus-map');
    let dangerousUrl = "https://www.google.com/maps?q=" + address + "&output=embed"
    iframe.src=dangerousUrl
    // iframe.src =
    //   'https://www.google.com/maps?amp;qembed?pb=!1m18!1m12!1m3!1d21333.55451277034!2d72.82639513435454!3d19.051631302157553!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c95afe3cec03%3A0x470ddcef310d17f7!2s' +
    //   encodeURI('Joyalukkas India Ltd.Fathima Nagar, Mission Quarters Thrissur, Kerala India-680005')+'!5e0!3m2!1sen!2sin!4v1689055822952!5m2!1sen!2sin';
  }

  getGeneralFaq() {
    this.getDataService
      .getData(GraphQlConsta.GET_GENERAL_FAQ_GQL, 'contact-us')
      .then((res: any) => {
        let data = res.data?.faqGeneral[0]?.subCategories;
        this.generalFaq = data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getContactUsDescription() {
    this.getDataService
      .getData(GraphQlConsta.GET_CONTACT_DESCRIPTION_GQL, 'contact-us')
      .then((res: any) => {
        let data = res.data.contactUsDescription[0];
        this.contactDescription = data;
      });
  }
  getContactUsSideInfo() {
    this.getDataService
      .getData(GraphQlConsta.GET_CONTACT_SIDEINFO_GQL, 'contact-us')
      .then((res: any) => {
        let data = res.data.contactUsSideinfo;
        this.contactUsSideinfo = data;
        this.selectedSideInfo = data.length > 0 ? data[0] : {};
        this.setMapAddress(this.selectedSideInfo?.address);
      });
  }

  setAddress(){
    this.setMapAddress(this.selectedSideInfo?.address);
  }

  resetForm(data?: any) {
    this.contactForm = this.fb.group({
      full_name: [data ? data.full_name : '', [Validators.required]],
      mobile_no: [data ? data.mobile_no : '', [Validators.required]],
      purpose: [data ? data.purpose : '', [Validators.required]],
      email: [data ? data.email : '', [Validators.required]],
      message: [data ? data.message : '', [Validators.required]],
    });
  }

  onContactSubmit(form: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.loaderShow = true;
      this.buttonDisable = true;
      if (form.valid) {
        const data = {
          ...form.value,
          source_url: window.location.hostname,
        };
        this.api.setContactForm(data).subscribe(
          (result: any) => {
            if (isPlatformBrowser(this.platformId)) {
              webengage.user.setAttribute('we_email', form.value.email);
              webengage.user.setAttribute(
                'we_phone',
                form.value.mobile_no.toString()
              );
              webengage.user.setAttribute(
                'we_first_name',
                form.value.full_name
              );
              if (form.value.purpose == 'New Inquiry') {
                webengage.user.setAttribute('Type of Query', true);
              } else {
                webengage.user.setAttribute('Type of Query', false);
              }
            }
            this.toastr.success('Thank you, we will connect with you!');
            this.resetForm();
          },
          (error) => {
            console.log(error.error);
            let error_text = '';
            for (const [key, value] of Object.entries(error.error)) {
              key;
              error_text = error_text + '\n' + value;
            }
            this.toastr.warning(error_text);
            this.buttonDisable = false;
            this.loaderShow = false;
          }
        );
        this.loaderShow = false;
      } else {
        this.toastr.warning('Please Fill And Submit!');
        this.buttonDisable = false;
        this.loaderShow = false;
      }
    }
  }
}
