import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { GetDataService } from 'src/app/core/services/get-data.service';

@Component({
  selector: 'stp-vertical-tab',
  templateUrl: './vertical-tab.component.html',
  styleUrls: ['./vertical-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerticalTabComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() navData: any;
  @Input() tabView: boolean = true;
  @Input() faqPageRouting: boolean = false;
  appConstants = new AppConstants();
  selectedIndex: number = 0;
  activeItem: string = 'vtab_faq_id_0';
  faqQuestionAnswer: any;
  subCatId: any;
  showReadMore: boolean = true;
  numberOfDataFetch: number = 5;

  constructor(
    public getDataService: GetDataService,
    public cdr: ChangeDetectorRef,
    public router: Router,
    public activeRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['navData'] && changes['navData'].currentValue) {
      let intId: number = parseInt(this.navData[0]?.id);
      this.subCatId = intId;
      this.getFaq(intId, this.numberOfDataFetch, 0);
    }
  }

  ngAfterViewInit(): void {
    // if (this.navData) {
    //   let intId: number = parseInt(this.navData[0].id);
    //   this.getFaq(intId, 3, 0);
    // } else {
    //   setTimeout(() => {
    //     let intId: number = parseInt(this.navData[0].id);
    //     this.getFaq(intId, 3, 0);
    //   }, 300);
    // }
  }

  displaFaq(item: any,index:number) {
    this.selectedIndex = index;
    this.faqQuestionAnswer = [];
    let intId: number = parseInt(item?.id);
    this.subCatId = intId;
    this.getFaq(intId, this.numberOfDataFetch, 0);
  }

  readMore() {
    let dataRead = this.faqQuestionAnswer;
    if (dataRead.length >= 10 && this.faqPageRouting) {
      this.router.navigate([this.appConstants.faqUrl]);
    } else {
      this.getFaq(this.subCatId, this.numberOfDataFetch, dataRead?.length);
    }
  }

  getFaq(subcategoryid: number, limit: number, offSet: number) {
    let queryTo = `query getQuestionAnswer($variable1: Int!, $variable2: Int!, $variable3: Int!) {
      faq(subcategoryid: $variable1, limit: $variable2, offset:$variable3) {
        question
        answer
      }
    }`;

    this.getDataService
      .getDataWithThreeVariable(queryTo, 'qa', subcategoryid, limit, offSet)
      .then((res: any) => {
        let data = res?.data?.faq;

        if (this.faqQuestionAnswer && this.faqQuestionAnswer?.length) {
          let oldData = this.faqQuestionAnswer;
          this.faqQuestionAnswer = [...oldData, ...data];
        } else {
          this.faqQuestionAnswer = data;
        }

        if (data && data.length >= this.numberOfDataFetch) {
          this.showReadMore = true;
        } else {
          this.showReadMore = false;
        }

        this.cdr.detectChanges();
      })
      .catch((error) => {
        this.showReadMore = false;
        console.log(error);
        this.cdr.detectChanges();
      });
  }
}
