import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  inject,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AppConstants } from 'src/app/app.constant';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { DateHandlingService } from 'src/app/core/services/date-handling.service';
import { DiyAssistanceService } from 'src/app/core/services/diy-assistance.service';
import { GetDataService } from 'src/app/core/services/get-data.service';
import { SignInService } from 'src/app/core/services/sign-in.service';
import { environment } from 'src/environments/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { CookieStorageService } from 'src/app/core/services/cookie-storage.service';
import { B2bAgentService } from 'src/app/core/services/b2b-agent.service';
const LOGIN_CAROUSEL_DATA_KEY = makeStateKey<any>('carouselImgData');
declare var webengage: any; // Declare the webengage variable
declare var fbq: any; // Declare the FB pixel variable
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, AfterViewInit {
  @Input() carouselImage: any = [];
  @Input() dataPassByModal: any = [];
  @Input() isModal: boolean = false;
  appConstants = new AppConstants();
  otpGenerationForm: any = FormGroup;
  otpVariForm: any = FormGroup;
  private modalService = inject(NgbModal);
  userActivity: any;
  timeToShow: any;
  timeIntervalSet: any;
  isOtpGenerated: boolean = false;
  isRegistered: boolean = true;
  isSignUp: boolean = false;
  showPrefix = true;
  passedPhone: any;
  isLoading: boolean = false;
  otpGenerationLoading: boolean = false;
  isDateRange: boolean = false;
  isStatusShow: boolean = false;
  isLoggedInCheckCmplete: boolean = false;
  selectedDateRange: any;
  paramsData: any;
  queryParamToPass: any;
  curDateSet: any;
  checkDiyOrVisaPro!: string | null;
  userId: string = '';
  userDetails: any;
  isVisaPro: boolean = false;
  showVisaPro: boolean = false;
  isEmail: boolean = false;
  isLoginSuccess: boolean = false;
  showCarousel: boolean = true;
  allowedConsent: boolean = true;
  visaProPaxNumber: number = 1;
  visaProPrice: any = 0;
  processingTimeColtn: any;
  envSiteUrl: string = environment?.siteUrl;
  memoryVal: string = '';
  memoryElem: any = null;
  // './assets/images/signup_image_1.webp',
  // ' ./assets/images/loginPage.webp',

  //  #endregion FI FOREX CARD
  // ./assets/images/signup_image_1.png
  //  #endregion FI FOREX CARD

  // './assets/images/signup_image_2.webp',
  // carouselImage: any[] = [
  //   './assets/images/carousel_1.png',
  //   './assets/images/carousel_2.png',
  //   './assets/images/carousel_3.png',
  // ];
  priceSummaryData: any;
  domains: string[] = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'icloud.com',
    'rediffmail.com',
  ];
  domainsToShow: string[] = [];
  otpVariEnblToProcd: boolean = false;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private toastr: ToastrService,
    public authService: AuthService,
    public signInService: SignInService,
    private activeRoute: ActivatedRoute,
    private getDataService: GetDataService,
    public cdr: ChangeDetectorRef,
    public authStateService: AuthStateService,
    public dateHandling: DateHandlingService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private diyAssiService: DiyAssistanceService,
    private transferState: TransferState,
    private ckieStrgService: CookieStorageService,
    private agentService: B2bAgentService
  ) {
    this.isOtpGenerated = false;
    this.isDateRange = false;
    this.isStatusShow = false;
    this.passedPhone = this.router
      ?.getCurrentNavigation()
      ?.extras?.state?.['phone'].toString();

    this.otpGenerationForm = this.fb.group({
      phone_no: [
        this.passedPhone ? this.passedPhone : null,
        [Validators.required, Validators.pattern(/^(?:\+91|0)?[5-9]\d{9}$/)],
      ],
    });

    this.otpVariForm = this.fb.group({
      otpFormArray: this.fb.array([
        this.fb.control('', Validators.required),
        this.fb.control('', Validators.required),
        this.fb.control('', Validators.required),
        this.fb.control('', Validators.required),
      ]),
    });

    //Start:- Idle logout is here Dont delete
    // this.userInactive.subscribe((res) => {

    // });
    //End:- Idle logout is here Dont delete
  }

  ngOnInit(): void {
    this.isOtpGenerated = false;
    this.isDateRange = false;
    this.isStatusShow = false;
    this.getDataFromUrl();
    // this.setInterval();
    if (isPlatformBrowser(this.platformId)) {
      // setTimeout(() => {
      //   this.removeScript();
      // }, 1500);
      setTimeout(() => {
        this.isLoggedCheck();
      }, 300);
    }
  }

  ngAfterViewInit() {
    // if (isPlatformBrowser(this.platformId)) {
    //   const rightCalendar = document.getElementsByClassName('calendar right');
    //   if (rightCalendar && rightCalendar.item(0)) {
    //     rightCalendar?.item(0)?.remove();
    //   }
    // }
    // this.initialFocus();
  }

  domainSelector(ctrlName: string) {
    let term = this.otpGenerationForm.controls[ctrlName].value;
    // this.emailTerm = term
    if (term?.includes('@')) {
      let spliData = term.split('@');
      if (!this.domainsToShow[0] && spliData[1]?.length == 0) {
        this.domainsToShow = this.domains.map((item) => `${term}${item}`);
        return;
      }

      if (spliData[1]) {
        this.domainsToShow = this.domains
          .filter(
            (v) => v.toLowerCase().indexOf(spliData[1]?.toLowerCase()) > -1
          )
          .map((dom) => spliData[0] + '@' + dom);
        if (this.domainsToShow[0]?.toLowerCase() === term) {
          this.domainsToShow = [];
        }
        // this.domainsToShow = this.domains.filter((v) => v.toLowerCase().indexOf(spliData[1].toLowerCase()) > -1).slice(0, 10).map((res)=>spliData[0]+'@'+res);
        // for(let i=0;i<this.domainsToShow?.length;i++){
        //   if(this.domainsToShow[0].toLocaleLowerCase()===term){
        //     this.domainsToShow=[]
        //   }
        // }
      }
    } else {
      this.domainsToShow = [];
    }
  }

  insertAtAfter(selectedDomain: string, index: number, contrlName: string) {
    let inVal = this.otpGenerationForm.controls[contrlName].value;
    let spliData = inVal.split('@');
    if (spliData[0]) {
      //this.form.controls[this.field?.name].setValue(spliData[0] + '@' + selectedDomain);
      this.otpGenerationForm.controls[contrlName].setValue(
        this.domainsToShow[index]
      );
      this.domainsToShow = [];
    }
  }

  initialFocus() {
    if (isPlatformBrowser(this.platformId)) {
      let phoneId = document.getElementById('signin_phone_id');
      let emailId = document.getElementById('signin_email_id');
      if (!this.isEmail) {
        phoneId?.focus();
      } else {
        emailId?.focus();
      }
    }
  }

  useEmail() {
    this.isEmail = !this.isEmail;
    if (this.isEmail) {
      this.otpGenerationForm.addControl(
        'phone_email',
        new FormControl('', [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ])
      );
      this.otpGenerationForm.removeControl('phone_no');
    } else {
      this.otpGenerationForm.addControl(
        'phone_no',
        new FormControl(this.passedPhone ? this.passedPhone : null, [
          Validators.required,
          Validators.pattern('^(?:\\+91|0)?[5-9]\\d{9}$'),
        ])
      );
      this.otpGenerationForm.removeControl('phone_email');
    }
    this.otpGenerationForm.reset();
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.initialFocus();
      }, 300);
    }
  }

  setInterval() {
    setTimeout(() => {
      this.goToPassport();
    }, 10000);
  }

  getPriceDetailsToShow() {
    let dataTopass = {
      ...{ primary_customer_id: this.queryParamToPass?.customer_id },
      ...this.queryParamToPass,
      ...{ count: this.visaProPaxNumber },
    };
    dataTopass.customer_id = undefined;
    this.diyAssiService.userDetailSubmission(dataTopass).subscribe((res) => {
      this.priceSummaryData = res;
    });
  }

  gotoPrivacyPolicy() {
    window.open(
      environment.siteUrl + this.appConstants.privacyPolicyUrl,
      '_self'
    );
  }

  gotoUserAgreement() {
    window.open(environment.siteUrl + this.appConstants.userAgreement, '_self');
  }

  gotoTandC() {
    window.open(
      environment.siteUrl + this.appConstants.termsConditions,
      '_self'
    );
  }

  mobilePaymentView(mblPaymeDetailsModal: TemplateRef<any>) {
    if (isPlatformBrowser(this.platformId)) {
      this.modalService
        .open(mblPaymeDetailsModal, {
          ariaLabelledBy: 'modal-basic-title',
          centered: true,
        })
        .result.then(
          (result) => {
            //  console.log(result);
          },
          (reason) => {
            // console.log(reason);
          }
        );
    }
  }

  close() {
    this.modalService.dismissAll();
  }

  // closeModal() {
  //   this.activeModal.close('close');
  // }

  // addScript() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const script = this.renderer.createElement('script');
  //     script.text = `
  //     !function(e,t,a){var c=e.head||e.getElementsByTagName("head")[0],n=e.createElement("script");n.async=!0,n.defer=!0, n.type="text/javascript",n.src=t+"&config="+JSON.stringify(a),c.appendChild(n)}(document,"https://conversation.creyoface.com/files/?file=creyoface_widget.js",{bot_key:"NDEyNDcwNjIyNzUyMDcxNzQyOA==",pos:"right",icon:"https://creyoface-data.s3.ap-south-1.amazonaws.com/Production/412/data/botdata/launchicon.jpg?1709888321766",open:"true",delay:"10000",buttonLabel:false,label:"Chat with us"});
  //     `;
  //     this.renderer.appendChild(document.head, script);
  //   }
  // }
  // removeScript() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     const scriptElements = document.querySelectorAll(
  //       'script[src*="creyoface_widget.js"]'
  //     );
  //     scriptElements.forEach((script) => {
  //       script.remove();
  //       const chatBubble = document.getElementById('chat-bubble');
  //       const chatBoxed = document.getElementById('chat-boxed');
  //       if (chatBubble) {
  //         chatBubble.remove();
  //       }
  //       if (chatBoxed) {
  //         chatBoxed.remove();
  //       }
  //     });
  //   }
  // }

  curDate(date: string) {
    if (date) {
      let dateSpltd = this.splitDate(date);
      this.curDateSet = dateSpltd.y + '-' + dateSpltd.m + '-' + dateSpltd.d;
    } else {
      this.curDateSet = this.dateHandling.curDateSetting();
    }
    this.cdr.detectChanges();
  }

  splitDate(date: string) {
    let splittedDate = date.split('-');
    // let day = splittedDate[0];
    let sepDate = {
      d: splittedDate[0],
      m: splittedDate[1],
      y: splittedDate[2],
    };
    return sepDate;
  }

  get otpInputs(): FormArray {
    return this.otpVariForm.get('otpFormArray') as FormArray;
  }

  nextElem(event: any, i: number) {
    let cur = event.target;
    let val = event.target.value;
    let next = event.target.nextElementSibling;
    let prev = event.target.previousElementSibling;

    if (val.length > 1) {
      if (this.memoryElem == cur && this.memoryVal) {
        cur.value = val.replace(this.memoryVal, '');
      }
      this.otpVariForm
        .get('otpFormArray')
        .get(i.toString())
        .setValue(cur.value);

      // let lastVal = cur.value.charAt(cur.value.length - 1);

      // if (lastVal) {
      //   cur.value = lastVal;
      // } else {
      //   cur.value = '';
      // }
      next ? next.focus() : cur.focus();
    } else if (next != null && val.length == 1) {
      if (val) next.focus();
    } else if (val.length < 1 && prev) {
      prev.focus();
    } else if (val.length < 1 && !prev) {
      cur.focus();
    } else if (isPlatformBrowser(this.platformId)) {
      const proceedButton = document.querySelector('#proceed-button');
      (proceedButton as HTMLElement).focus();
    }
    this.otpValidatCheck();
  }

  curFocunHandl(event: any, i: number) {
    let curElem = event.target;
    this.memoryElem = curElem;
    if (curElem.value) {
      this.memoryVal = curElem.value;
    }
    // curElem.value='';
  }

  otpValidatCheck() {
    const otpFormArray = this.otpVariForm.get('otpFormArray') as FormArray;
    if (otpFormArray.controls.every((control) => control?.value?.toString())) {
      this.otpVariEnblToProcd = true;
    } else {
      this.otpVariEnblToProcd = false;
    }
  }

  // curElemFocus(event:any) {
  //   event.target.value = ''
  // }

  onPasteOtp(event: ClipboardEvent) {
    event.preventDefault();
    let clipboardData = event.clipboardData;
    if (clipboardData) {
      let pastedText = clipboardData.getData('text');
      let spltText = pastedText.split('');
      this.otpVariForm.reset();
      for (
        let otpInputsIndex = 0;
        otpInputsIndex < this.otpInputs?.controls?.length;
        otpInputsIndex++
      ) {
        this.otpVariForm
          .get('otpFormArray')
          .get(otpInputsIndex.toString())
          .setValue(spltText[otpInputsIndex]);
        let inputElement = document.getElementById(
          'otpNum' + otpInputsIndex
        ) as HTMLInputElement;
        if (inputElement) {
          inputElement.blur();
        }
      }
    }

    if (this.otpVariForm.valid) {
      this.otpVariEnblToProcd = true;
    } else {
      this.otpVariEnblToProcd = false;
    }
    let proceedButton = document.querySelector('#proceed-button');
    (proceedButton as HTMLElement).focus();
  }

  timer(minute: any) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.timeToShow = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(timer);
      }
    }, 1000);

    return timer;
  }

  isLoggedCheck() {
    let isSign = this.authService.isLoggedIn();
    if (isSign) {
      this.isDateRange = true;
    }
    this.isLoggedInCheckCmplete = true;
    if (!this.carouselImage.length) {
      this.getCarouselData();
    }
  }

  getCarouselData() {
    this.carouselImage = [];
    this.carouselImage = this.transferState.get<any>(
      LOGIN_CAROUSEL_DATA_KEY,
      null
    );
    if (this.carouselImage) {
      return;
    }
    const queryTo = `
        query carousel($variable: String!) {
          carousel(type: $variable) {
            id
            image
          }
        }
      `;

    this.getDataService
      .getDataWithVariable(queryTo, 'home-page', 'Login')
      .then((res: any) => {
        this.carouselImage = res?.data?.carousel;
        this.transferState.set(LOGIN_CAROUSEL_DATA_KEY, this.carouselImage);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  generateOtp() {
    this.otpGenerationLoading = true;
    if (this.isEmail) {
      let dataTosend: any = {};
      dataTosend.phone_email =
        this.otpGenerationForm.value.phone_email.toString();
      dataTosend.is_mail = this.isEmail;
      this.userId = dataTosend.phone_email;
      // if (this.validateEmailId(dataTosend.phone_email)) {
      this.otpGenerAuthCall(dataTosend);
      // } else {
      //   this.toastr.warning('Enter valid Email ID!');
      // }
    } else {
      let dataTosend: any = {};
      dataTosend.phone_email = this.otpGenerationForm.value.phone_no.toString();
      dataTosend.is_mail = this.isEmail;
      this.userId = dataTosend.phone_email;
      if (this.validateMobileNumber(dataTosend.phone_email)) {
        this.otpGenerAuthCall(dataTosend);
      } else {
        this.toastr.warning('Enter valid mobile number!');
      }
    }
  }

  otpGenerAuthCall(data: any) {
    this.authService.emailPhoneOtpGeneration(data).subscribe(
      (res: any) => {
        if (this.timeToShow == null || this.timeToShow == undefined) {
          this.timeIntervalSet = this.timer(res.interval_time);
        } else {
          clearInterval(this.timeIntervalSet);
          this.timeIntervalSet = this.timer(res.interval_time);
        }
        this.isOtpGenerated = true;
        this.isStatusShow = false;
        this.showVisaPro = false;
        this.isDateRange = false;

        this.otpGenerationLoading = false;
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
            const proceedButton = document.querySelector('#otpNum0');
            (proceedButton as HTMLElement).focus();
          }
        }, 500);

        webengage.track('Send/Resend OTP', {
          'Phone Number': this.otpGenerationForm.value.phone_no,
        });
      },
      (err) => {
        console.log(err);
        this.isLoading = false;
        this.otpGenerationLoading = false;
        this.toastr.error('Please try again');
        this.otpGenerationForm.reset();
      }
    );
  }

  resendOtp() {
    let curFormData = this.otpGenerationForm.value;
    let dataToSend: any = {};
    if (this.isEmail) {
      dataToSend.phone_email = curFormData?.phone_email?.toString();
    } else {
      dataToSend.phone_no = curFormData?.phone_no?.toString();
    }
    webengage.track('Resend otp clicked', { user_detail: dataToSend });
    clearInterval(this.timeIntervalSet);
    this.otpVariForm.reset();
    this.generateOtp();
  }

  logIntoHome() {
    this.router.navigate([this.appConstants.home]);
  }

  emailPhoneOtpVerify() {
    let dataToSend: any = {};
    let otp: any = this.otpInputs.value.join('');
    dataToSend.phone_email = this.userId;
    dataToSend.otp = otp;

    this.authService.emailPhoneOtpVerification(dataToSend).subscribe(
      (res: any) => {
        this.userDetails = res;
        this.ckieStrgService.removeItem('isB2b');
        if (res.is_b2b) {
          this.ckieStrgService.setItem('isB2b', 'true');
          this.agentService.setIsB2B(true);
        } else {
          this.ckieStrgService.setItem('isB2b', 'false');
          this.agentService.setIsB2B(false);
        }

        if (!res.new_user) {
          this.isRegistered = true;
          this.showCarousel = false;
          this.isSignUp = false;
          this.showCarousel = true;
          this.removeControlForSignIn();
          this.isLoading = false;
          this.signUpOrSignInHandling(res.new_user);

          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              if (res?.token) {
                localStorage.setItem('user_details', JSON.stringify(res));
                localStorage.setItem('token', JSON.stringify(res?.token));
                this.authStateService.setAuthenticated(true);
              } else {
                this.authStateService.setAuthenticated(false);
              }
            }, 300);

            if (this.paramsData.productId) {
              this.isDateRange = true;
            } else {
              // this.goToPage();
              // this.isLoginSuccess = true;
              this.close();
            }

            webengage.user.login(res.phone);
            webengage.user.setAttribute('user_id', res?.phone);
            webengage.user.setAttribute('email', res?.email);
            webengage.user.setAttribute('phone', res?.phone);
            webengage.user.setAttribute('user', res?.user);
            webengage.user.setAttribute('name', res?.user);
            webengage.user.setAttribute('first_name', res?.user);
            webengage.user.setAttribute('last_name', '');
          }

          this.cdr.detectChanges();
          // if (isPlatformBrowser(this.platformId)) {
          //   const rightCalendar =
          //     document.getElementsByClassName('calendar right');
          //   if (rightCalendar && rightCalendar.item(0)) {
          //     rightCalendar?.item(0)?.remove();
          //   }
          // }
        } else {
          this.isRegistered = false;
          this.addControlForSignUP();
          this.showCarousel = false;
          this.isSignUp = true;
          this.showCarousel = true;
          this.isLoading = false;

          this.isOtpGenerated = false;
          this.isDateRange = false;
          this.isStatusShow = false;
          this.showVisaPro = false;

          if (this.allowedConsent) {
            webengage.user.setAttribute({
              we_email_opt_in: true,
              we_sms_opt_in: true,
              we_whatsapp_opt_in: true,
            });
          } else {
            webengage.user.setAttribute({
              we_email_opt_in: false,
              we_sms_opt_in: false,
              we_whatsapp_opt_in: false,
            });
          }

          return;
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              localStorage.setItem('user_details', JSON.stringify(res));
              localStorage.setItem('token', JSON.stringify(res?.token));
              this.authStateService.setAuthenticated(true);
              if (this.paramsData.productId) {
                this.isDateRange = true;
                this.isOtpGenerated = false;
                this.isStatusShow = false;
                this.showVisaPro = false;
                this.dateLimitForCurPro();
                this.cdr.detectChanges();
                const rightCalendar =
                  document.getElementsByClassName('calendar right');
                if (rightCalendar && rightCalendar.item(0)) {
                  rightCalendar?.item(0)?.remove();
                }
              } else {
                this.goToPage();
              }
            }, 300);
          }

          webengage.user.setAttribute({
            we_email: res?.email,
            we_first_name: res?.user,
            we_phone: res?.phone,
            email: res?.email,
            first_name: res?.user,
            phone: res?.phone,
            we_email_opt_in: true,
            we_sms_opt_in: true,
            we_whatsapp_opt_in: true,
          });

          webengage.user.setAttribute('new_user_email', res?.email);
          webengage.user.setAttribute('new_user_phone', res?.phone);
          webengage.user.setAttribute('new_user', res?.user);
          webengage.user.setAttribute('new_user_first_name', res?.user);

          webengage.track('Verify OTP', {
            'Phone Number': this.otpGenerationForm.value.phone_no,
          });
          webengage.user.setAttribute(
            'Signup',
            this.otpGenerationForm.value.phone_no
          );
          webengage.track('Signup', {
            'Phone Number': this.otpGenerationForm.value.phone_no,
            new_user_email: res?.email,
            new_user: res?.user,
          });
        }
        let strWebEvent = {
          user_id: res?.phone,
          email: res?.email,
          phone: res?.phone,
          first_name: res?.user,
        };
        localStorage.setItem(
          'webengage_user_details',
          JSON.stringify(strWebEvent)
        );
      },
      (err: any) => {
        console.log(err);
        this.isLoading = false;
        this.toastr.error(err ? err : 'Try Again!');
        this.otpVariForm.reset();
      }
    );
  }

  consentCheck(event: any) {
    // alert('testing')
    const inputElement = event.target as HTMLInputElement;
    this.allowedConsent = inputElement.checked;
    if (this.allowedConsent) {
      webengage.user.setAttribute({
        we_email_opt_in: true,
        we_sms_opt_in: true,
        we_whatsapp_opt_in: true,
      });
    } else {
      webengage.user.setAttribute({
        we_email_opt_in: false,
        we_sms_opt_in: false,
        we_whatsapp_opt_in: false,
      });
    }
  }

  otpVerify() {
    this.isLoading = true;
    this.emailPhoneOtpVerify();
    return;

    if (this.isSignUp) {
      let signUPData = this.otpGenerationForm.value;
      let otp: any = this.otpInputs.value.join('');
      let dataTosend = { ...signUPData, ...{ otp: otp } };

      this.signInService.verifyNewUser(dataTosend).subscribe(
        (res: any) => {
          this.isLoading = false;

          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              localStorage.setItem('user_details', JSON.stringify(res));
              localStorage.setItem('token', JSON.stringify(res?.token));
              this.authStateService.setAuthenticated(true);
              if (this.paramsData.productId) {
                this.isDateRange = true;
                this.isOtpGenerated = false;
                this.isStatusShow = false;
                this.showVisaPro = false;
                this.dateLimitForCurPro();
                this.cdr.detectChanges();
                const rightCalendar =
                  document.getElementsByClassName('calendar right');
                if (rightCalendar && rightCalendar.item(0)) {
                  rightCalendar?.item(0)?.remove();
                }
              } else {
                this.goToPage();
              }
            }, 300);
          }

          webengage.user.setAttribute({
            we_email: res?.email,
            we_first_name: res?.user,
            we_phone: res?.phone,
            email: res?.email,
            first_name: res?.user,
            phone: res?.phone,
            we_email_opt_in: true,
            we_sms_opt_in: true,
            we_whatsapp_opt_in: true,
          });

          webengage.user.setAttribute('new_user_email', res?.email);
          webengage.user.setAttribute('new_user_phone', res?.phone);
          webengage.user.setAttribute('new_user', res?.user);
          webengage.user.setAttribute('new_user_first_name', res?.user);

          webengage.track('Verify OTP', {
            'Phone Number': this.otpGenerationForm.value.phone_no,
          });
          webengage.user.setAttribute(
            'Signup',
            this.otpGenerationForm.value.phone_no
          );
          webengage.track('Signup', {
            'Phone Number': this.otpGenerationForm.value.phone_no,
            new_user_email: res?.email,
            new_user: res?.user,
          });
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
          this.toastr.error(err ? err : 'Try Again!');
          this.otpVariForm.reset();
        }
      );
    } else {
      let phone = this.otpGenerationForm.value.phone_no;
      let otp: any = this.otpInputs.value.join('');
      let dataTosend = { phone: phone, otp: otp };
      this.authService.loginOtpForVerify(dataTosend).subscribe(
        (res: any) => {
          this.isLoading = false;
          if (isPlatformBrowser(this.platformId)) {
            // alert(1);
            setTimeout(() => {
              localStorage.setItem('user_details', JSON.stringify(res));
              localStorage.setItem('token', JSON.stringify(res?.token));
              this.authStateService.setAuthenticated(true);
            }, 300);

            if (this.paramsData.productId) {
              this.isDateRange = true;
            } else {
              // this.goToPage();
              this.isLoginSuccess = true;
            }

            webengage.user.login(phone);
            webengage.user.setAttribute('email', res?.email);
            webengage.user.setAttribute('phone', res?.phone);
            webengage.user.setAttribute('user', res?.user);
            webengage.user.setAttribute('name', res?.user);
            webengage.user.setAttribute('first_name', res?.user);
            webengage.user.setAttribute('last_name', '');
          }

          this.cdr.detectChanges();
          if (isPlatformBrowser(this.platformId)) {
            const rightCalendar =
              document.getElementsByClassName('calendar right');
            if (rightCalendar && rightCalendar.item(0)) {
              rightCalendar?.item(0)?.remove();
            }
          }
        },
        (err) => {
          console.log(err);
          this.isLoading = false;
          this.toastr.error(err ? err : 'Try Again!');
          this.otpVariForm.reset();
        }
      );
    }
  }

  dateLimitForCurPro() {
    if (this.paramsData?.productId) {
      this.signInService.getDateLimit(this.paramsData?.productId).subscribe(
        (res: any) => {
          this.processingTimeColtn = res;
          this.curDate(res?.start_date);
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  goToPage() {
    this.router.navigate([this.appConstants.home]);
  }

  signUp() {
    let curFormData = this.otpGenerationForm.value;

    let dataToSend: any = {};

    if (!this.isEmail) {
      dataToSend.mail_no = curFormData.email.toString();
    } else {
      dataToSend.mail_no = curFormData.phone.toString();
    }
    dataToSend.phone_email = this.userDetails.phone_email;
    dataToSend.name = curFormData.name;
    dataToSend.login_type = this.userDetails.login_type;
    this.authService.emailPhoneSignUp(dataToSend).subscribe(
      (res: any) => {
        this.isLoginSuccess = true;
        webengage.track('sign up', {
          status: 'Success',
          email: res?.email,
          phone: res?.phone,
          first_name: res?.user,
        });
        webengage.user.setAttribute('user_id', res?.phone);
        webengage.user.setAttribute('email', res?.email);
        webengage.user.setAttribute('phone', res?.phone);
        webengage.user.setAttribute('user', res?.user);
        webengage.user.setAttribute('name', res?.user);
        webengage.user.setAttribute('first_name', res?.user);
        fbq(
          'trackCustom',
          'CompleteRegistration',
          JSON.stringify({
            user_id: res?.phone,
            email: res?.email,
            phone: res?.phone,
            user: res?.user,
          })
        );

        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('user_details', JSON.stringify(res));
          localStorage.setItem('token', JSON.stringify(res?.token));
          this.authStateService.setAuthenticated(true);

          setTimeout(() => {
            this.close();
          }, 5000);
        }
      },
      (err) => {
        console.log(err);
        webengage.track('sign up', {
          status: 'Failed',
        });
        this.isLoading = false;
        this.toastr.error('Please try again');
      }
    );
    // return

    // if (this.otpGenerationForm.valid) {
    //   this.signInService.generateOtpForSignUp(dataToSend).subscribe(
    //     (res: any) => {
    //       if (this.timeToShow == null || this.timeToShow == undefined) {
    //         this.timeIntervalSet = this.timer(res.interval_time);
    //       } else {
    //         clearInterval(this.timeIntervalSet);
    //         this.timeIntervalSet = this.timer(res.interval_time);
    //       }
    //       this.isOtpGenerated = true;
    //       this.isLoading = false;
    //       setTimeout(() => {
    //         if (isPlatformBrowser(this.platformId)) {
    //           const proceedButton = document.querySelector('#otpNum0');
    //           (proceedButton as HTMLElement).focus();
    //         }
    //       }, 500);
    //     },
    //     (err) => {
    //       console.log(err);
    //       this.isLoading = false;
    //       this.toastr.error('Please try again');
    //     }
    //   );
    // } else {
    //   this.toastr.warning('Please Enter Valid Data!');
    // }
  }

  // signUp() {
  //   if (this.otpGenerationForm.value.phone_no) {
  //     const state: NavigationExtras = {
  //       state: {
  //         phone: this.otpGenerationForm.value.phone_no
  //       }
  //     };
  //     this.router.navigate([this.appConstants.signUp], state);
  //   } else {
  //     this.router.navigate([this.appConstants.signUp]);
  //   }
  // }

  // #region :- IDLE CASE LOGOUT FUNCTIONALITY.

  // setTimeout() {
  //   this.userActivity = setTimeout(
  //     () => this.userInactive.next('sign-out trigger'),
  //     300000
  //   );
  // }

  // @HostListener('window:mousemove') refreshUserStateWhenmouse() {
  //   clearTimeout(this.userActivity);
  //   this.setTimeout();
  // }

  // @HostListener('window:keyup') refreshUserStateWhenKey() {
  //   clearTimeout(this.userActivity);
  //   this.setTimeout();
  // }

  // #endregion :- IDLE CASE LOGOUT FUNCTIONALITY.

  addControlForSignUP() {
    this.otpGenerationForm.addControl(
      'name',
      this.fb.control('', [Validators.required, Validators.minLength(3)])
    );
    if (this.isEmail) {
      this.otpGenerationForm.addControl(
        'phone',
        this.fb.control('', [
          Validators.required,
          Validators.pattern(/^(?:\+91|0)?[5-9]\d{9}$/),
        ])
      );
    } else {
      this.otpGenerationForm.addControl(
        'email',
        this.fb.control('', [Validators.required])
      );
    }
  }

  removeControlForSignIn() {
    if (this.isControlExistsInOTPGenerationForm('name')) {
      this.otpGenerationForm.removeControl('name');
      this.otpGenerationForm.removeControl('email');
    }
  }

  isControlExistsInOTPGenerationForm(controlName: string): boolean {
    return this.otpGenerationForm.contains(controlName);
  }

  signUpOrSignInHandling(isSignUpConfirm: boolean) {
    if (isSignUpConfirm) {
      this.isRegistered = false;
      this.addControlForSignUP();
      this.showCarousel = false;
      this.isSignUp = true;
      this.showCarousel = true;
      this.isLoading = false;
    } else {
      this.isRegistered = true;
      this.showCarousel = false;
      this.showCarousel = false;
      this.isSignUp = false;
      this.showCarousel = true;
      this.showCarousel = true;
      this.removeControlForSignIn();
    }
  }

  // mobileNumVerify() {
  //   let num = this.otpGenerationForm.value?.phone_no;
  //   if (Math.floor(Math.log10(Math.abs(num)) + 1) >= 10) {
  //     if (this.validateMobileNumber(num)) {
  //       this.authService.phoneNumberVerify(num).subscribe(
  //         (res: any) => {
  //           if (res.is_registered == false) {
  //             this.isRegistered = false;
  //             this.addControlForSignUP();
  //             this.isSignUp = true;
  //             this.isLoading = false;
  //           } else {
  //             this.isRegistered = true;
  //             this.isSignUp = false;
  //             this.removeControlForSignIn();
  //           }
  //         },
  //         (err) => {
  //           console.log(err);
  //         }
  //       );
  //     } else {
  //       this.toastr.warning('Enter valid number!');
  //     }
  //   }
  // }

  validateMobileNumber(mobile: any): boolean {
    const indianMobileNumberRegex = /^(?:\+91|0)?[5-9]\d{9}$/;
    return indianMobileNumberRegex.test(mobile);
  }

  validateEmailId(email: any): boolean {
    let result = email.includes('.com');
    return result;
  }

  choosedDate(event: any) {
    const dateRange = event.chosenLabel;
    const [startDate, endDate] = dateRange.split(' - ');
    this.selectedDateRange = { startDate: startDate, endDate: endDate };
    if (this.selectedDateRange.startDate == this.selectedDateRange.endDate) {
      // alert()
      this.toastr.clear();
      this.toastr.warning('Your arrival and departure dates are the same.');
    }
  }

  dateRangeSubmit() {
    let dataToSend: any = {
      dot_from: this.selectedDateRange?.startDate,
      dot_to: this.selectedDateRange?.endDate,
      product_id: this.paramsData?.productId,
      diy: this.paramsData?.diy,
    };

    if (!this.selectedDateRange) {
      this.toastr.warning(
        'Please select both your arrival and departure dates.'
      );
      return;
    }

    if (!this.selectedDateRange?.endDate) {
      this.toastr.warning('Please select your departure date.');
      return;
    }

    this.signInService.postPrimaryCustomer(dataToSend).subscribe((res: any) => {
      let queryParams = {
        customer_id: res?.visa_process_id,
        product_id: this.paramsData.productId,
        product_heading: this.paramsData.prodHeading,
        country: this.paramsData.country,
        countryId: this.paramsData?.countryId,
        diy: this.paramsData.diy,
        dot_from: this.selectedDateRange.startDate,
        dot_to: this.selectedDateRange.endDate,
        category: this.paramsData.category,
      };
      this.queryParamToPass = queryParams;
      if (this.paramsData.productId) {
        this.isStatusShow = true;
        this.isDateRange = false;
        this.isOtpGenerated = false;
        this.showVisaPro = false;
        this.cdr.detectChanges();
        // this.router.navigate([this.appConstants.initialDataCollection, queryParams]);
      } else {
        this.toastr.warning('Please Select Valid Product');
        this.goToPage();
      }
      const userPhone = localStorage.getItem('user_details')
        ? JSON.parse(localStorage.getItem('user_details') || '')
        : '';
      const webEngageData = {
        product_heading: this.paramsData.prodHeading,
        country: this.paramsData.country,
        diy: this.paramsData.diy,
        dot_from: this.selectedDateRange.startDate,
        dot_to: this.selectedDateRange.endDate,
        'Phone Number': userPhone?.phone,
      };
      webengage.track('Submit date range', webEngageData);
    });
  }

  goToPassport() {
    if (this.isVisaPro) {
      this.showVisaPro = true;
      this.isDateRange = false;
      this.isOtpGenerated = false;
      this.isStatusShow = false;
      this.updateVisaProService();
    } else {
      if (this.queryParamToPass?.customer_id) {
        this.router.navigate([
          this.appConstants.initialDataCollection,
          this.queryParamToPass,
        ]);
      } else {
        this.toastr.error('Technical error');
      }
    }
    this.getPriceDetailsToShow();
  }

  visproPaxRemove() {
    if (this.visaProPaxNumber != 1) {
      this.visaProPaxNumber = this.visaProPaxNumber - 1;
      this.updateVisaProService();
    }
  }

  visproPaxAdd() {
    this.visaProPaxNumber = this.visaProPaxNumber + 1;
    this.updateVisaProService();
  }

  updateVisaProService() {
    this.signInService
      .getPaxCountUpdationRespons(
        this.visaProPaxNumber,
        this.paramsData.productId
      )
      .subscribe(
        (res: any) => {
          this.visaProPrice = res?.price;
          webengage.track('We are on track clicked');
        },
        (err) => console.log(err)
      );
  }

  uploadVisProPax() {
    // this.queryParamToPass.customer_id=undefined
    let queryDta = {
      ...this.queryParamToPass,
      ...{ count: this.visaProPaxNumber },
    };

    this.router.navigate([this.appConstants.addOnUrl, queryDta]);
  }

  getDataFromUrl() {
    this.activeRoute.paramMap.subscribe((params) => {
      if (
        params != null &&
        params != undefined &&
        Object.keys(params).length != 0
      ) {
        this.paramsData = {
          diy: params.get('diy') == 'true',
          productId: params.get('productId'),
          prodHeading: params.get('heading'),
          country: params.get('country'),
          countryId: params.get('countryId'),
          category: params.get('category'),
        };
        this.isVisaPro = !this.paramsData.diy;
        this.checkDiyOrVisaPro = params.get('diy');
        this.dateLimitForCurPro();
      } else {
        this.toastr.error('THis PRODUCT DOES NOT EXIST');
      }
    });

    // this.paramsData = {
    //   diy: this.dataPassByModal.diy,
    //   productId: this.dataPassByModal.productId,
    //   prodHeading: this.dataPassByModal.heading,
    //   country: this.dataPassByModal.country,
    //   countryId: this.dataPassByModal.countryId,
    //   category: this.dataPassByModal.category,
    // };
  }

  goBackToPhoneEnter() {
    this.isOtpGenerated = false;
    this.isDateRange = false;
  }

  ngOnDestroy() {
    // if (isPlatformBrowser(this.platformId)) {
    //   setTimeout(() => {
    //     this.addScript();
    //   }, 1000);
    // }
  }
}
