import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-url',
  templateUrl: './url.component.html',
  styleUrls: ['./url.component.scss']
})
export class URLComponent implements OnInit {

  componentName: string = 'URLComponent';
  headerData: any;

  constructor(public router: Router,) {
    this.componentName = this.componentName;
  }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }
}
