import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import { Route, UrlSegment, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { ToastrService } from "ngx-toastr";
import { AppConstants } from "src/app/app.constant";
import { isPlatformBrowser } from "@angular/common";


@Injectable({ providedIn: 'root' })

export class AuthGuard {

  appConstants = new AppConstants();

  constructor(public authService: AuthService, private toastr: ToastrService, private router: Router,@Inject(PLATFORM_ID) private platformId: Object) { }



  canLoad(route: Route, segments: UrlSegment[]): any {
    var isAuthenticated = true;

    isAuthenticated = this.authService.isLoggedIn();
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        if (!isAuthenticated) {
          this.router.navigate([this.appConstants.home]);
          this.toastr.clear();
          this.toastr.info('Please login to continue this Journey');
  
        }
      }, 300);
    }
   
    return isAuthenticated;
  }
}