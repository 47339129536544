import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  headers: any
  constructor(private http: HttpClient) {

  }

  generate404() {
    let data = '404'
    // const timestamp = new Date().getTime();
    return this.http.post(`${environment.adminApiUrl}/error-page/`, data);
    // return this.http.post(`${environment.adminApiUrl}/payment-info/`, data, { headers: this.headers }).pipe(retry(2));
  }



}
