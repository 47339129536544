import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { AppConstants } from 'src/app/app.constant';
import { GetDataService } from 'src/app/core/services/get-data.service';
import * as GraphQlConsta from 'src/app/pages/home/home-constants';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { NgbDropdownModule, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/core/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AuthStateService } from 'src/app/core/services/auth-state.service';
// import { SignInComponent } from 'src/app/pages/sign-in/sign-in.component';
import { environment } from 'src/environments/environment';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SignInModalModule } from 'src/app/pages/business-home/sign-in-modal/sign-in-modal.module';
import { SignInComponent } from 'src/app/pages/sign-in/sign-in.component';
import { B2bAgentService } from 'src/app/core/services/b2b-agent.service';
import { CookieStorageService } from 'src/app/core/services/cookie-storage.service';
declare var webengage: any; // Declare the webengage variable
const ALL_MENU_ITEMS_KEY = makeStateKey<any>('menuItemsData');
const LOGIN_CAROUSEL_DATA_KEY = makeStateKey<any>('loginCarouselData');
@Component({
  selector: 'stp-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    FormsModule,
    SignInModalModule,
    ReactiveFormsModule,
    NgbDropdownModule,
  ],
})
export class MainMenuComponent implements OnInit {
  @ViewChild('content', { static: false }) subModal: ElementRef | undefined;
  @Input() componentName: string = '';
  @Input() showLoginBtn: boolean = true;
  appConstants = new AppConstants();
  showMenu: boolean = false;
  redirectionUrl: string = environment._siteUrl;
  consultantServ: string = this.appConstants.consultantUsUrl;
  myVisaRouting: string = this.appConstants.myVisaRoutingUrl;
  myBookingRouting: string = this.appConstants.myBookingRoutingUrl;
  myProfileUrl: string = this.appConstants.myProfile;
  isBrowser: boolean = false;

  toggleSearch: boolean = false;
  isLogin: boolean = false;
  countryList: any;
  selectedCountryUrl: string = '';
  userName: string = '';
  token: any = '';
  loginCarouselData: any = [];
  menuItems: any = [];
  currentPath: string = '';
  businessPath: string = this.appConstants?.businessHomeUrl;
  // headerElem: any;
  // marginElem: any;

  constructor(
    private getDataService: GetDataService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    public authService: AuthService,
    public authStateService: AuthStateService,
    private toastr: ToastrService,
    private transferState: TransferState,
    private agentService: B2bAgentService,
    public cdr: ChangeDetectorRef,
    private ckieStrgService: CookieStorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    console.log(this.route.snapshot.url.join('/'));
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.url;
        // if (this.currentPath === this.businessPath) {
        //   this.agentService.setIsB2B(true);
        // } else {
        //   // this.ckieStrgService.removeItem('isB2b');
        //   let ckie = this.ckieStrgService.getItem('isB2b');
        //   if (ckie == 'true') {
        //     this.agentService.setIsB2B(true);
        //   } else {
        //     this.agentService.setIsB2B(false);
        //   }
        //   // this.agentService.setIsB2B(this.currentPath === this.businessPath);
        // }
      }
    });
    this.getmenuItem();
    this.getToken();

    this.getCountry();
    this.loginCheck();
    this.getUserData();
    this.getLoginCarouselDetails();

    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
      // this.headerElem = document.querySelector('.header') as HTMLElement;
      // this.marginElem = document.getElementById('header_dynamic_mgn_set') as HTMLElement;
      // this.showMenu = true;
    }
    this.authStateService.isAuthenticated$.subscribe((authenticated) => {
      this.getToken();
      // console.log(authenticated);
      
      if (authenticated) {
        this.getUserData();
        this.isLogin = true;
      } else {
        this.isLogin = false;
        this.userName = '';
      }
    });
  }

  getmenuItem() {
    this.menuItems = [];
    this.menuItems = this.transferState.get<any>(ALL_MENU_ITEMS_KEY, null);
    if (this.menuItems) {
      // this.transferState.remove(USEFUL_MEDIAS_KEY)
      return;
    }

    const queryTo = `
      query {
        headerService{
          id,
          name,
          router
          position
          isExternal
        }
      }
    `;

    this.getDataService
      .getDataWithoutVariable(queryTo)
      .then((res: any) => {
        this.menuItems = res?.data?.headerService;
        this.transferState.set(ALL_MENU_ITEMS_KEY, this.menuItems);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getToken() {
    if (isPlatformBrowser(this.platformId)) {
      this.token = localStorage.getItem('token')
        ? localStorage.getItem('token')
        : '';
    }
  }

  closeMenu() {
    this.removeMenuWidth();
    this.showMenu = false;
  }

  displayMenu() {
    this.addMenuWidth();
    this.showMenu = true;
  }

  goToLogin() {
    // this.router.navigate([this.appConstants.signIn])
    this.closeMenu();
    // const modalComponent =
    //   this.currentPath === '/business-home'
    //     ? SignInModalComponent
    //     : SignInComponent;

    const modalRef = this.modalService.open(SignInComponent, {
      scrollable: true,
      size: 'lg',
      fullscreen: this.isMobile(),
      // windowClass: 'custom_modal_sign_in',
      // keyboard: false,
      backdrop: 'static',
      centered: true,
    });
    modalRef.componentInstance.carouselImage = this.loginCarouselData;
    modalRef.componentInstance.isModal = true;
    modalRef.result.then(
      (result) => {
        console.log(this.isMobile());
      },
      (reason) => {}
    );
  }

  getLoginCarouselDetails() {
    this.loginCarouselData = [];
    this.loginCarouselData = this.transferState.get<any>(
      LOGIN_CAROUSEL_DATA_KEY,
      null
    );
    if (this.loginCarouselData) {
      return;
    }
    const queryTo = `
      query carousel($variable: String!) {
        carousel(type: $variable) {
          id
          image
        }
      }
    `;

    this.getDataService
      .getDataWithVariable(queryTo, 'home-page', 'Login')
      .then((res: any) => {
        this.loginCarouselData = res?.data?.carousel;
        this.transferState.set(LOGIN_CAROUSEL_DATA_KEY, this.loginCarouselData);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  isMobile(): any {
    if (isPlatformBrowser(this.platformId)) {
      if (window.innerWidth <= 768) {
        return true;
      } else {
        return false;
      }
    }
  }

  getUserData() {
    if (isPlatformBrowser(this.platformId)) {
      let stringUserData = localStorage.getItem('user_details');
      let userData: any;
      if (stringUserData) {
        userData = JSON.parse(stringUserData);
        this.userName = userData.user;
      }
    }
  }

  loginCheck() {
    if (isPlatformBrowser(this.platformId)) {
      let token = localStorage.getItem('token');
      if (token) {
        this.isLogin = true;
        this.authStateService.setAuthenticated(true);
      } else {
        this.isLogin = false;
        this.authStateService.setAuthenticated(false);
      }
    }
  }

  homeTrigger() {
    if (isPlatformBrowser(this.platformId)) {
      webengage.track('Home', {
        'Button Clicked': true,
      });

      window.scrollTo(0, 0);
    }
    // this.router.navigate([this.appConstants.home]);
  }

  aboutUsTrigger() {
    if (isPlatformBrowser(this.platformId)) {
      webengage.track('About Us', {
        'Button Clicked': true,
      });
    }
    // this.router.navigate([this.appConstants.aboutUsUrl]);
  }

  contactUsTrigger() {
    if (isPlatformBrowser(this.platformId)) {
      webengage.track('Contact Us', {
        'Button Clicked': true,
      });
      // window.open('https://api.whatsapp.com/send/?phone=919076133777&text=Hello&type=phone_number&app_absent=0', '_blank')
      // https://wa.me/919370566863?text=Hello
    }
    // this.router.navigate([this.appConstants.contactUsUrl]);
  }

  // airportSrvc() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     window.open('https://stampthepassport.airportzo.com/', '_blank');
  //   }
  // }

  // tripPlannerServc() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     window.open('https://holidays.stampthepassport.com/', '_blank');
  //   }
  // }

  Logout() {
    this.authService.logout().subscribe(
      (res: any) => {
        if (isPlatformBrowser(this.platformId)) {
          webengage.user.logout();
        }
        // this.router.navigate(['/visa']);
        this.ckieStrgService.setItem('isB2b', 'false');
        this.agentService.setIsB2B(false);
        this.authStateService.setAuthenticated(false);

        if (isPlatformBrowser(this.platformId)) {
          this.userName = '';
          this.isLogin = false;
          localStorage.setItem('token', '');
          localStorage.setItem('user_details', '');
          this.toastr.success('You are successfully logged out');
          // location.reload();
          this.router.navigate([this.appConstants.home]);
        }
      },
      (err) => {
        // this condition is included for unauthorised token request
        if (err.status == 401) {
          this.authStateService.setAuthenticated(false);
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('token', '');
            localStorage.setItem('user_details', '');
            this.userName = '';
            this.isLogin = false;
            this.toastr.success('You are successfully logged out');
          }
        }
        console.log(err);
      }
    );
  }

  visaProWebEngageTrig() {
    if (isPlatformBrowser(this.platformId)) {
      webengage.track('Visa Pro Button Clicked');
    }
  }

  addMenuWidth() {
    if (isPlatformBrowser(this.platformId)) {
      let elem = document.getElementById('menu');
      if (elem?.classList.contains('menu_width_none')) {
        elem.classList.remove('menu_width_none');
        elem.classList.add('menu_width');
      }
    }
  }

  removeMenuWidth() {
    if (isPlatformBrowser(this.platformId)) {
      let elem = document.getElementById('menu');
      if (elem?.classList.contains('menu_width')) {
        elem.classList.remove('menu_width');
        elem.classList.add('menu_width_none');
      }
    }
  }

  getCountry() {
    if (isPlatformBrowser(this.platformId)) {
      if (isPlatformBrowser(this.platformId)) {
        let countryStringData = localStorage.getItem('countryData');

        if (countryStringData) {
          this.countryList = JSON.parse(countryStringData);
        } else {
          this.getDataService
            .getData(GraphQlConsta.GET_COUNTRY_LIST, 'menu')
            .then((res: any) => {
              let data = res.data.country;
              this.countryList = data;
            });
        }
      }
    }
  }

  countryOnchange() {
    this.getCountry;
  }

  search() {
    if (!this.toggleSearch) {
      this.toggleSearch = true;
    } else {
      if (this.nullCheck(this.selectedCountryUrl)) {
        if (isPlatformBrowser(this.platformId)) {
          let stringData = JSON.stringify(this.selectedCountryUrl);
          localStorage.setItem('selectedCountryId', stringData);
          this.router
            .navigateByUrl('/', { skipLocationChange: true })
            .then(() =>
              this.router.navigate(
                [`visa/${this.selectedCountryUrl}-visa-online`],
                { queryParamsHandling: 'preserve' }
              )
            );
          this.toggleSearch = false;
        }
      } else {
        this.open();
      }
    }
  }

  searchMob() {
    if (this.nullCheck(this.selectedCountryUrl)) {
      if (isPlatformBrowser(this.platformId)) {
        let stringData = JSON.stringify(this.selectedCountryUrl);
        localStorage.setItem('selectedCountryId', stringData);
        this.removeMenuWidth();
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() =>
            this.router.navigate(
              [`visa/${this.selectedCountryUrl}-visa-online`],
              { queryParamsHandling: 'preserve' }
            )
          );
      }
    } else {
      this.open();
    }
  }

  nullCheck(vari: any) {
    if (vari != undefined && vari != null && vari != '') {
      return true;
    } else {
      return false;
    }
  }

  open() {
    this.modalService.open(this.subModal, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'open-menu-modal',
      centered: true,
    });
  }

  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll() {
  //   if (isPlatformBrowser(this.platformId)) {
  //     if (window.pageYOffset > this.headerElem.clientHeight) {
  //       this.headerElem.classList.add('header-inverse');
  //       this.marginElem.style.marginBottom = "50px";
  //     } else {
  //       this.headerElem.classList.remove('header-inverse');
  //       this.marginElem.style.marginBottom = "0px";
  //     }
  //   }

  // }

  @HostListener('document: tokenUpdated')
  onDragStart(ev: Event) {
    this.loginCheck();
    this.getUserData();
  }
}
