import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieStorageService } from '../services/cookie-storage.service';
import { isPlatformBrowser } from '@angular/common';
import { B2bAgentService } from '../services/b2b-agent.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private ckieStrgService: CookieStorageService,
    private agentService: B2bAgentService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // let isB2B = this.b2bService.getIsB2B();
    // let isBtbPage = this.agentService.isB2BPageObservable
    let isBtbPage
    this.agentService.isB2BPageObservable.subscribe((val: boolean) => {
      isBtbPage = val;
    })

    let isB2B = this.ckieStrgService.getItem('isB2b') == 'true';
    if (isPlatformBrowser(this.platformId) && !isBtbPage) {
      let userDetails: any = localStorage.getItem('user_details');
      if (userDetails) {
        userDetails = JSON.parse(userDetails);
      }
      if (userDetails) {
        isB2B = userDetails?.is_b2b;
      }
    }
    if (isBtbPage) {
      isB2B = true;
    }
    // console.log('cookie storage service',isB2B);
    // console.log('b2bSevice',this.b2bService.getIsB2B());
    const clonedReq = req.clone({
      headers: req.headers.set('Source', isB2B ? 'b2b' : 'stp-web'),
    });
    return next.handle(clonedReq);
  }
}
