

export class AppConstants {

    home = '';
    error = '**';
    signIn = '/sign-in';
    visaDate = '/visa-date';
    initialDataCollection = '/passport-details';
    signUp = '/sign-up';
    countryRoutingUrl = 'visa-online';
    categoryRoutingUrl = 'visa-online-india';
    productRoutingUrl = 'visa-apply-online';
    aboutUsUrl: string = '/about-us';
    consultantUsUrl: string = '/consultant';
    testimonialUrl: string = 'testimonial';
    diyAssistance: string = '/diy-assistance';
    faqUrl: string = 'faq';
    privacyPolicyUrl: string = 'privacy-policy';
    termsAndConditions: string = '/cordelia-visapolicy';
    termsAndConditionsUrl: string = 'assets/pdf/Privacy-Policy.pdf';
    myVisaRoutingUrl: string = '/my-visa';
    myBookingRoutingUrl: string = '/my-bookings';
    congratulationServ: string = '/my-visa/congratulation';
    contactUsUrl: string = '/contact-us';
    blogUrl: string = '/blog';
    newsUrl: string = '/news';
    mediaUrl: string = '/#';
    flightUrl: string = '/#';
    hotelUrl: string = '/#';
    authorUrl: string = '/author';
    specificBlogUrl: string = '/blog/specific-blog';
    specificNewsUrl: string = '/news/specific-news';
    addOnUrl: string = 'diy-assistance/add-on';
    userAgreement: string = 'user-agreement';
    termsConditions: string = 'terms-conditions';
    myProfile: string = '/my-profile';
    homeMobBannerImg: string = '/home-banner-mob.webp';
    homeWebBannerImg: string = '/home-banner-web.webp';
    businessHomeUrl: string = '/travel-agents';
    businessPolicyUrl: string = '/travel-agents-policy';
}