import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input, PLATFORM_ID } from '@angular/core';
declare var webengage: any; // Declare the webengage variable

@Component({
  selector: 'stp-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FaqComponent  {

  @Input() componentData: any = [];
 
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {

  }
  
//   <div class="posistio-relative">
//   <div class="seo_accordion_content">
//       <p class="mb-0" [innerHTML]="faq?.answer" *ngIf="faq?.answer && faq?.description"></p>
//       <p class="mb-0" [innerHTML]="faq?.answer" *ngIf="faq?.answer && !faq?.description"></p>
//       <p class="mb-0" [innerHTML]="faq?.description" *ngIf="faq?.description && !faq?.answer"></p>
//   </div>
// </div>
  

  faqWebengageTrigger() {
    if (isPlatformBrowser(this.platformId)) {
      webengage.track('FAQs  Clicked');
    }
  }

}

