import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { countryUrlCheck, productUrlCheck, countryAllUrlCheck } from './routing';
// import { categoryUrlCheck, countryUrlCheck, productUrlCheck } from './routing';


const routes: Routes = [

  {
    matcher: countryUrlCheck,
    loadChildren: () => import('./country-landing/country-landing.module').then(m => m.CountryLandingModule)
  },
  // {
  //   matcher: categoryUrlCheck,
  //   loadChildren: () => import('./category/category.module').then(m => m.CategoryModule)
  // },
  {
    matcher: productUrlCheck,
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule)
  },

  {
    matcher: countryAllUrlCheck,
    loadChildren: () => import('./country-details/country-details.module').then(m => m.CountryDetailsModule)
  }

]


@NgModule({
  imports: [RouterModule.forChild(routes)
  ],
  exports: []
})
export class PagesRoutingModule {
  constructor() {
  }
}
