import {
  Component,
  HostListener,
  Inject,
  makeStateKey,
  OnInit,
  PLATFORM_ID,
  TransferState,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppConstants } from 'src/app/app.constant';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { GetDataService } from 'src/app/core/services/get-data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubmitService } from 'src/app/core/services/submit.service';
import { ToastrService } from "ngx-toastr";
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
declare var webengage: any; // Declare the webengage variable
const COUNTRY_DATA_KEY = makeStateKey<any>('countryList');
@Component({
  selector: 'stp-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  appConstants = new AppConstants();
  aboutUs: string = this.appConstants.aboutUsUrl;

  subscribeForm: any = FormGroup;

  businessVisa = [];
  touristVisa = [];

  loaderShow: boolean = false;
  buttonDisable: boolean = false;

  isShowApply: boolean = false;
  topPosToStartShowing = 200;
  footerCountryList: any;
  limit = 15;
  siteUrl: string = environment.siteUrl;
  constructor(
    private fb: FormBuilder,
    private api: SubmitService,
    private toastr: ToastrService,
    public router: Router,
    private modalService: NgbModal,
    public getDataService: GetDataService,
    private transferState: TransferState,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }
  ngOnInit(): void {
    this.isShowApply = true;
    // this.checkApplyScroll();
    // this.getFooter(false);
    // this.getFooter(true);
    //this.getCountry();
    this.resetForm();
  }

  airportSrvc() {
    if (isPlatformBrowser(this.platformId)) {
      window.open('https://stampthepassport.airportzo.com/', '_blank');
    }
  }

  tripPlannerServc() {
    if (isPlatformBrowser(this.platformId)) {
      window.open('https://holidays.stampthepassport.com/', '_blank');
    }
  }

  getCountryWithLimit(limit: any) {
    if (this.footerCountryList)
      return this.footerCountryList.slice(0, limit);
  }

  reloadCountryPage() {
    // location.reload();
    var event = new CustomEvent('reloadCountryPage');
    if (isPlatformBrowser(this.platformId)) {
      document.dispatchEvent(event);
    }

  }

  @HostListener('window:scroll', ['$event']) 
  onScroll(event:any) {
    // Handle scroll event
    let checkCountryList:boolean=false 
   checkCountryList=localStorage.getItem('countryData')?true:false
   if(!checkCountryList) 
   {
    this.getCountry()
   }else{
    this.footerCountryList=JSON.parse(localStorage.getItem('countryData')||'')
   }
  }
  

  getCountry() {
    this.footerCountryList = this.transferState.get<any>(COUNTRY_DATA_KEY, null);
    if (this.footerCountryList) {
      return
    }

    const GET_COUNTRY_LIST = `
    query {
        country{
          id
          name
          countryUrl
          isStarred
          code
          searchKeyWords
        }
      }
    `;
    this.getDataService.getData(GET_COUNTRY_LIST, 'footer').then((res: any) => {
      let data = res.data.country;
      this.footerCountryList = data;
    })
  }

  applyNowModal() {
    const modalRef = this.modalService.open(ContactModalComponent, {
      size: 'xl',
      scrollable: true,
      windowClass: 'modal-big-lg',
      // keyboard: false,
      backdrop: 'static',
      centered:true
    });

    modalRef.result.then(
      (result) => { },
      (reason) => { }
    );
  }

  // getFooter(isTourist: boolean) {
  //   let category: string = '';

  //   if (isTourist) {
  //     category = 'Tourist Visa';

  //   } else {
  //     category = 'Business Visa';
  //   }

  //   const queryTo = `
  //     query footer($variable: String!) {
  //       footer(category: $variable) 
  //     }
  //   `;

  //   this.getDataService
  //     .getDataWithVariable(queryTo, 'Footer', category).then((res: any) => {
  //       if (isTourist) {
  //         this.touristVisa = res?.data?.footer;
  //       } else {
  //         this.businessVisa = res?.data?.footer;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  resetForm(data?: any) {
    this.subscribeForm = this.fb.group({
      email: [data ? data.email : "", [Validators.required,Validators.email]],
    });
  }

  get formControls(){
    return this.subscribeForm?.controls
  }

  onSubscribeSubmit(form?: any) {
    this.loaderShow = true;
    this.buttonDisable = true;
    if (form.valid) {

      if (isPlatformBrowser(this.platformId)) {
        // webengage.user.setAttribute("Newsletter Subscribed", {
        //   "Email ": form.value.email,
        // });
        webengage.track('Newsletter Subscribed', {
          email: form.value.email
        });

      }

      this.api.setSubscription(form.value).subscribe(
        (result: any) => {
          this.toastr.success("Newsletter Subscribed Successfully!");
          this.resetForm();
        }, error => {
          console.log(error.error);
          let error_text = '';
          for (const [key, value] of Object.entries(error.error)) {
            key;
            error_text = error_text + '\n' + value;
          }
          this.toastr.warning(error_text);
          this.buttonDisable = false;
          this.loaderShow = false;
        });
      this.loaderShow = false;
    } else {
      this.toastr.warning("Please Fill And Subscribe!");
      this.buttonDisable = false;
      this.loaderShow = false;
    }

  }

  socialButtonClicked(socialMedia: string) {
    webengage.track('Social Button Clicked', {
      Platform: socialMedia
    });
  }


  // @HostListener('window:scroll')
  // checkApplyScroll() {
  //   const scrollPosition =
  //     window.pageYOffset ||
  //     document.documentElement.scrollTop ||
  //     document.body.scrollTop ||
  //     0;

  //   if (scrollPosition >= this.topPosToStartShowing) {
  //     this.isShowApply = true;
  //   } else {
  //     this.isShowApply = false;
  //   }
  // }
}
