<stp-menu></stp-menu>


<div class="congratulation_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <img src="./assets/images/congratulation_icon.svg" class="img-fluid" alt="">
                <div class="heading_h2">Congratulations !!</div>
                <p class="mb-1">You're on your way to your destination already!</p>
                <div>{{applicationNo}}</div>
                <div class="img_display_section d-flex align-items-center justify-content-center">
                    <div class="position-relative">
                        <img src="https://stp-data-bucket.s3.amazonaws.com/cms_live/documents/Visapro-5_1_6_11zon_16_11zon_PcskJYF.webp"
                            alt="" class="con_img_size">
                        <div class="commissionerflair_semibold converted_font_14 black_color">Pay Online</div>
                        <div class="con_tick_align d-flex">
                            <img src="./assets/images/con_tick.jpg" width="16px" alt="">
                        </div>
                    </div>

                    <img src="./assets/images/con_arrow.jpg" height="10px" alt="">

                    <div class="position-relative">
                        <img src="https://stp-data-bucket.s3.amazonaws.com/cms_live/documents/Endtoendassistance_1_4_11zon_8_11zon_E95pwTH_PkPXNjh.webp"
                            alt="" class="con_img_size">
                        <div class="commissionerflair_semibold converted_font_14 black_color">Submit Application</div>
                    </div>

                    <img src="./assets/images/con_arrow.jpg" height="10px" alt="">

                    <div class="position-relative">
                        <img src="https://stp-data-bucket.s3.amazonaws.com/cms_live/documents/Reapplication_1_2_11zon_10_11zon_GknnhTp_EzWFIia.webp"
                            alt="" class="con_img_size">
                        <div class="commissionerflair_semibold converted_font_14 black_color">Expert Verification</div>
                    </div>

                    <img src="./assets/images/con_arrow.jpg" height="10px" alt="">

                    <div class="position-relative">
                        <img src="https://stp-data-bucket.s3.amazonaws.com/cms_live/documents/STP_illustration_Hotel_wS1Q2ox_U7fMbmM-1.webp"
                            alt="" class="con_img_size">
                        <div class="commissionerflair_semibold converted_font_14 black_color">Get Your Visa</div>
                    </div>
                </div>
                <button class="btn btn-primary primary_bg_color rounded-0" type="button" (click)="goToMyBooking()">Start Application Form</button>
            </div>
        </div>
    </div>
</div>


<stp-footer class="position_relative"></stp-footer>