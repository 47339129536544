<div class="footer_section rv_blue_bg position-relative"
  [ngStyle]="{background: 'url('+ '../../../assets/images/rvmp/footer_bg.svg'+ ')' + 'no-repeat center bottom / contain,#082226'}">
  <div class="container">

    <div class="row">
      <div class="col-md-7">
        <div class="text-start margin_bottom_24">
          <img src="/assets/images/logo.webp" loading="lazy" class="img-fluid footer_logo" width="330" alt="STP" />
        </div>
      </div>
      <div class="col-md-5 d-flex">
        <div class="margin_bottom_24 mt-auto">
          <a class="me-3 me-md-2" href="https://www.linkedin.com/company/stampthepassport/?viewAsMember=true"
            target="_blank" (click)="socialButtonClicked('linkedin')">
            <img src="./assets/icons/rvmp/social_media/mdi_linkedin.svg" loading="lazy" class="icon_wb_mb" alt="" />
          </a>
          <a class="me-3 me-md-2"
            href="https://www.instagram.com/p/Cuy04MSIpq6/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA=="
            target="_blank" (click)="socialButtonClicked('instagram')">
            <img src="./assets/icons/rvmp/social_media/ri_instagram-fill.svg" loading="lazy" class="icon_wb_mb"
              alt="" />
          </a>
          <a class="me-3 me-md-2" href="https://twitter.com/stampthepasport/status/1681608026921521152?s=20"
            target="_blank" (click)="socialButtonClicked('twitter')">
            <img src="./assets/../assets/icons/rvmp/social_media/mage_x.svg" loading="lazy" class="icon_wb_mb" alt="" />
          </a>
          <a class="me-3 me-md-2" href="https://fb.watch/lMFnoaZZa0/?mibextid=RUbZ1f" target="_blank"
            (click)="socialButtonClicked('fb')">
            <img src="./assets/../assets/icons/rvmp/social_media/ic_baseline-facebook.svg" loading="lazy"
              class="icon_wb_mb" alt="" />
          </a>
          <a class="me-3 me-md-2" href="#" target="_blank">
            <img src="./assets/../assets/icons/rvmp/social_media/bi_youtube.svg" loading="lazy" class="icon_wb_mb"
              alt="" />
          </a>


        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-7 order-md-1 order-2">
        <div class="row list-data d-md-flex">

          <div class="col-md-6">
            <div class="row">
              <!--Start:- Company -->
              <ul class="col-6">
                <li class="white_color">
                  <div class="white_color commissionerflair_semibold fs-4">Company</div>
                </li>
                <li class="pointer">
                  <a [routerLink]="[aboutUs]" class="text-reset text-decoration-none">
                    About Us
                  </a>
                </li>
                <!-- <li class="pointer" [routerLink]="['']">Online Application</li> -->

                <li class="pointer">
                  <a [routerLink]="[appConstants.home]" class="text-reset text-decoration-none">Partners</a>
                  <!-- fragment="partnersSection" -->
                </li>
                <li class="pointer">
                  <a [routerLink]="[appConstants.blogUrl]" class="text-reset text-decoration-none">Blog</a>
                </li>
                <li class="pointer">
                  <a [routerLink]="[appConstants.newsUrl]" class="text-reset text-decoration-none">
                    News
                  </a>
                </li>

              </ul>
              <!--End:- Company -->

              <!--Start Help -->
              <ul class="col-6">
                <li>
                  <div class="white_color commissionerflair_semibold fs-4">Help</div>
                </li>
                <li class="pointer">
                  <a href="{{ siteUrl + appConstants.termsConditions }}" target="_blank"
                    class="text-reset text-decoration-none">Terms</a>
                </li>
                <li class="pointer">
                  <a href="{{ siteUrl + appConstants.privacyPolicyUrl }}" target="_blank"
                    class="text-reset text-decoration-none">Privacy Policy</a>
                </li>
                <li class="pointer">
                  <a href="{{ siteUrl + appConstants.userAgreement }}" target="_blank"
                    class="text-reset text-decoration-none">User Agreement</a>
                </li>
                <li class="pointer">
                  <a [routerLink]="[appConstants.contactUsUrl]" class="text-reset text-decoration-none">
                    Contact Us
                  </a>

                </li>
                <li class="pointer">
                  <a [routerLink]="[appConstants.faqUrl]" class="text-reset text-decoration-none">
                    FAQs
                  </a>
                </li>
              </ul>
              <!--End Help -->
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <!-- Start:- Country -->
              <div class="col-6">
                <div class="row">
                  <div class="col-12">
                    <ul class="px-0 mb-0">
                      <li class="white_color">
                        <div class="white_color commissionerflair_semibold fs-4">Country</div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <ul class="country_names">
                      <li class=" pointer" *ngFor="let country of getCountryWithLimit(limit)">
                        <a class="text-reset text-decoration-none"
                          [routerLink]="['/visa/' + country.countryUrl + '-visa-online']" (click)="reloadCountryPage()">
                          {{ country.name }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="mt-2 color_a1 pointer" *ngIf="footerCountryList?.length > limit && limit == 6"
                  (click)="limit = footerCountryList?.length">
                  View More
                </div>
                <div class="mt-2 color_a1 pointer" *ngIf="footerCountryList?.length == limit && limit > 6"
                  (click)="limit = 6">
                  View Less
                </div>
              </div>
              <!-- End:- Country -->

              <!--Start Services -->
              <ul class="col-6" *ngIf="serviceDatas">
                <li>
                  <div class="white_color commissionerflair_semibold fs-4">Services</div>
                </li>

                <div class="service_listing_limit">
                  <li class="pointer" *ngFor="let service of serviceDatas;let i = index">
                    <ng-container *ngIf="i < serviceLimit">
                      <a [href]="[redirectionUrl+'/'+service?.link]" class="text-reset text-decoration-none"
                        *ngIf="!service?.isExternal">
                        {{service?.name}}
                      </a>
                      <a [href]="[service?.link]" class="text-reset text-decoration-none" *ngIf="service?.isExternal"
                        target="_blank">
                        {{service?.name}}
                      </a>
                    </ng-container>
                  </li>
                </div>
                <div class="mt-2 color_a1 pointer" *ngIf="serviceDatas?.length > serviceLimit && serviceLimit == 6"
                  (click)="serviceLimit = footerCountryList?.length">
                  View More
                </div>
                <div class="mt-2 color_a1 pointer" *ngIf="serviceDatas?.length == serviceLimit && serviceLimit > 6"
                  (click)="serviceLimit = 6">
                  View Less
                </div>
              </ul>
              <!--End Services -->
            </div>
          </div>

        </div>
      </div>
      <div class="col-md-5 order-md-2 order-1">
        <!-- <div class="col-md-12">
          
        </div> -->
        <div class="col-md-12 row d-block d-md-none list-data custom-accordion">
          <!-- <ngb-accordion #acc="ngbAccordion">
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <b>COUNTRY</b>
              </ng-template>
              <ng-template ngbPanelContent>
                <ul class="col-md-3 g-0 country_names">
                  <li class="pointer" *ngFor="let country of getCountryWithLimit(limit)">

                    <a [routerLink]="['/visa/' + country.countryUrl + '-visa-online']"
                      class="text-reset text-decoration-none">
                      {{ country.name }}
                    </a>

                  </li>
                  <div class="mt-2 color_a1 pointer" *ngIf="footerCountryList?.length > 15 && limit == 15"
                    (click)="limit = footerCountryList?.length">
                    Read More
                  </div>
                </ul>
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <b>COMPANY</b>
              </ng-template>
              <ng-template ngbPanelContent>
                <ul class="col-md-3 g-0">
                  <li class="pointer">
                    <a [routerLink]="[aboutUs]" class="text-reset text-decoration-none">
                      About Us
                    </a>
                  </li>
                  <li class="pointer">
                    <a [routerLink]="[appConstants.consultantUsUrl]" class="text-reset text-decoration-none">
                      Visa Pro
                    </a>
                  </li>
                  <li class="pointer">
                    <a [routerLink]="[appConstants.home]" class="text-reset text-decoration-none">Partners</a>
                  </li>
                  <li class="pointer">
                    <a [routerLink]="[appConstants.blogUrl]" class="text-reset text-decoration-none">
                      Blog
                    </a>

                  </li>
                  <li class="pointer" [routerLink]="[appConstants.newsUrl]">
                    <a [routerLink]="[appConstants.blogUrl]" class="text-reset text-decoration-none">
                      News
                    </a>
                  </li>
                  <li class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase">
                    <a href="https://holidays.stampthepassport.com/" class="text-reset text-decoration-none"
                      target="_blank">
                      TRIP PLANNER
                    </a>
                  </li>
                  <li class="padding_lr_15 pointer margin_lr_3 commissionerflair_medium text-uppercase">
                    <a href="https://stampthepassport.airportzo.com/" class="text-reset text-decoration-none"
                      target="_blank">
                      AIRPORT SERVICES
                    </a>
                  </li>
                </ul>
              </ng-template>
            </ngb-panel>
            <ngb-panel>
              <ng-template ngbPanelTitle>
                <b>HELP</b>
              </ng-template>
              <ng-template ngbPanelContent>
                <ul class="col-md-3 g-0">
                  <li class="pointer">
                    <a href="{{ siteUrl + appConstants.termsConditions }}" target="_blank">Terms</a>
                  </li>
                  <li class="pointer">
                    <a href="{{ siteUrl + appConstants.privacyPolicyUrl }}" target="_blank">Privacy Policy</a>
                  </li>
                  <li class="pointer">
                    <a href="{{ siteUrl + appConstants.userAgreement }}" target="_blank">User Agreement</a>
                  </li>
                  <li class="pointer" [routerLink]="[appConstants.contactUsUrl]">
                    Contact Us
                  </li>
                  <li class="pointer" [routerLink]="[appConstants.faqUrl]">
                    FAQs
                  </li>
                </ul>
              </ng-template>
            </ngb-panel>
          </ngb-accordion> -->
        </div>
        <div class="col-md-12">
          <div class="fs-4 commissionerflair_semibold white_color  email_head_padi_adj">
            Want to hear more from us?
          </div>
          <p class="content commissionerflair_regular gray_color">
            Important updates, exciting offers and the occasional inspiration to help you plan your next trip.
          </p>
          <form [formGroup]="subscribeForm" (ngSubmit)="onSubscribeSubmit(subscribeForm)">
            <div class="mail-submit w-100 position-relative">
              <input type="email" class="form-control mail_submit bg-white border-0 input_box_height"
                placeholder="Enter Your Email ID" formControlName="email" />
              <!-- <button [disabled]="buttonDisable || formControls?.email?.invalid" type="submit"
                class="btn btn-primary btn-with-loader bg-white">
                SUBSCRIBE
                <div class="ms-2 me-0 position-static" *ngIf="loaderShow">
                  <img class="img-fluid" src="./assets/images/loading-gif.gif" alt="" />
                </div>
              </button> -->
            </div>
            <div>
              <small class="text-danger" *ngIf="
              formControls?.email?.errors?.email &&
              !formControls?.email?.errors?.required
            ">
                Invalid email
              </small>
            </div>

            <!-- <div class="mb-4">
              I agree to receive email updates from StampThePassport
            </div> -->
            <button
              class="btn btn-primary padding_top_12 commissionerflair_bold fs-6 rounded-0 margin_bottom_40 text_trns_unset"
              [disabled]="buttonDisable || formControls?.email?.invalid" type="submit">
              <span class="text_trns_unset">Keep me Updated</span>
              <div class="ms-2 me-0 position-static" *ngIf="loaderShow">
                <img class="img-fluid" src="./assets/images/loading-gif.gif" alt="" />
              </div>
            </button>
          </form>


        </div>
      </div>

    </div>
  </div>
</div>