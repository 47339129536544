import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class ErrorGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree {
    const url = state.url;
    if (url === '/visa') {
      // Redirect to the home page
      return this.router.parseUrl('');
    } else if (url.startsWith('/visa/')) {
      // Redirect to the "Visa" page
      return true;
    } else {
      // Redirect to the 404 page
      return this.router.parseUrl('/404');
    }
  }
}