import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../services/auth.service';
import { AuthStateService } from '../services/auth-state.service';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class ErrorCatchingInterceptor implements HttpInterceptor {
  constructor(public toastr: ToastrService, public authService: AuthService, public authStateService: AuthStateService, @Inject(PLATFORM_ID) private platformId: Object) { }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        let errorMsg = '';
        if (isPlatformBrowser(this.platformId)) {
          if (error.error instanceof ErrorEvent) {
            // console.log('This is client side error');
            errorMsg = `Error: ${error?.error?.message}`;
          } else {
            // console.log('-->This is server side error', error);
            errorMsg = `${(error && error.error && error.error.message) ? error.error.message : error?.message
              }`;
          }
          if (error.status == 401) {
            this.authService.tokenClear();
            this.authStateService.setAuthenticated(false);
          }
          console.log(errorMsg);
        }
        return throwError(errorMsg);
      })
    );
  }
}
