
// import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Component, Inject, OnInit, Optional, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/app.constant';
import { ErrorService } from 'src/app/core/services/error.service';
import { Response } from 'express';

@Component({
  selector: 'stp-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {
  homeRouterLink: string = 'home';

  componentName: string = 'ErrorComponent';
  headerData: any;
  timer: number = 10;
  clearTimer: any;
  appConstants = new AppConstants();


  constructor(public router: Router, public errorSrvc: ErrorService, private titleService: Title, @Inject(PLATFORM_ID) private platformId: any, @Optional() @Inject(Response) private response: Response, @Inject(DOCUMENT) private document: Document, private renderer: Renderer2) {
    this.componentName = this.componentName;

    const meta = this.document.createElement('meta');
    meta.name = 'prerender-status-code';
    meta.content = '404';
    this.document.head.appendChild(meta);

    if (isPlatformServer(this.platformId)) {

      this.response?.status(404)

    }

  }
  ngOnInit(): void {
    this.clearTimer = this.startTimer(this.timer);

    this.titleService.setTitle('Page Not Found');

  }

  ngOnDestroy() {
    clearInterval(this.clearTimer);
    if (isPlatformServer(this.platformId)) {
      const metaTag = this.document.querySelector('meta[name="prerender-status-code"]');
      if (metaTag) {
        this.renderer.removeChild(this.document.head, metaTag);
      }
    }
  }


  startTimer(duration: number,) {
    let timer = duration;
    let intervel = setInterval(() => {
      timer = timer - 1;
      this.timer = timer;
      if (timer == 0) {
        clearInterval(intervel);
        this.returnToHome();
      }
    }, 1000);
    return intervel;
  }

  returnToHome() {
    this.router.navigate([this.appConstants.home]);
  }

}
