import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApolloModule } from 'apollo-angular';
import { RouterModule, Routes } from '@angular/router';
import { SignInModalComponent } from './sign-in-modal.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { SharedModule } from 'src/app/components/shared.module';
import { LoginCarouselModalComponent } from './login-carousel-modal/login-carousel-modal.component';


const routes: Routes = [

];

@NgModule({
  declarations: [
    SignInModalComponent,
    LoginCarouselModalComponent
  ],
  imports: [
    CommonModule,
    ApolloModule,
    CarouselModule,
    FormsModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgxDaterangepickerMd.forRoot()
  ],
  exports:[
  ],
  providers: [],
})
export class SignInModalModule {}
