import { CommonModule } from '@angular/common';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbNavConfig, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

import { MenuComponent } from './menu/menu.component';
import { Carousel3by1Component } from './carousels/carousel3by1/carousel3by1.component';
import { HeaderComponent } from './header/header.component';
import { CardComponent } from './card/card.component';
import { HowToApplyComponent } from './how-to-apply/how-to-apply.component';
import { FooterComponent } from './footer/footer.component';
import { VisaCategoryComponent } from './visa-category/visa-category.component';
import { FaqComponent } from './faq/faq.component';
import { UsefulMediaComponent } from './useful-media/useful-media.component';
import { HeaderCtrlCarousel } from './carousels/header-ctrl-carousel/header-ctrl-carousel.component';
import { ThreeImageComponent } from './three-image/three-image.component';
import { AsideCtrlCarousel } from './carousels/aside-ctrl-carousel/aside-ctrl-carousel.component';
import { PartnersComponent } from './partners/partners.component';
import { RouterModule } from '@angular/router';
import { TwoSideImgComponent } from './two-side-img/two-side-img.component';
import { VerticalTabComponent } from './vertical-tab/vertical-tab.component';
import { VisaImgDescriptionComponent } from './visa-img-description/visa-img-description.component';
import { ImageCardComponent } from './image-card/image-card.component';
import { StampPassportComponent } from './stamp-passport/stamp-passport.component';
import { VoucherComponent } from './voucher/voucher.component';
import { HomeService } from '../core/services/home.service';
import { GetDataService } from '../core/services/get-data.service';
import { HrVrTabComponent } from './hr-vr-tab/hr-vr-tab.component';
import { BannerCarouselComponent } from './carousels/banner-carousel/banner-carousel.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TokenVerifyModalComponent } from '../pages/modal/token-verify/token-verify-modal.component';
import { HomeBannerComponent } from './carousels/home-banner/home-banner.component';
import { CountryTabComponent } from './country-tab/country-tab.component';
import { CountryTabDataComponent } from './country-tab-data/country-tab-data.component';
import { ApplicationStepsCarouselsComponent } from './carousels/application-steps-carousels/application-steps-carousels.component';
import { DocAccordionComponent } from './doc-accordion/doc-accordion.component';
import { AddTravelerComponent } from '../pages/modal/add-traveler/add-traveler.component';
import { StpVAccordionDirective } from '../core/directives/stp-v-panel';
import { ProductAccordionComponent } from './product-accordion/product-accordion.component';
import { BlogCarouselComponent } from './carousels/blog-carousel/blog-carousel.component';
import { InsideErrorComponent } from './inside-error/inside-error.component';
import { NumberDirective } from '../core/directives/number-only.directive';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { ConfirmationService } from './confirmation/confirmation.service';

import { MatIconModule } from '@angular/material/icon';
import { DateAsMonth } from '../core/pipes/date-as-month.pipe';
import { PartnersLogoComponent } from './partners-logo/partners-logo.component';
import { InsidePageSearchComponent } from './inside-page-search/inside-page-search.component';
import { CountryVerticalTabComponent } from './country-vertical-tab/country-vertical-tab.component';
import { ContactModalComponent } from './contact-modal/contact-modal.component';

@NgModule({
    declarations: [
        MenuComponent,
        Carousel3by1Component,
        HeaderComponent,
        CardComponent,
        HowToApplyComponent,
        FooterComponent,
        VisaCategoryComponent,
        FaqComponent,
        UsefulMediaComponent,
        HeaderCtrlCarousel,
        ThreeImageComponent,
        AsideCtrlCarousel,
        PartnersComponent,
        TwoSideImgComponent,
        VisaImgDescriptionComponent,
        ImageCardComponent,
        StampPassportComponent,
        VoucherComponent,
        HrVrTabComponent,
        VerticalTabComponent,
        CountryVerticalTabComponent,
        ContactModalComponent,
        BannerCarouselComponent,
        TokenVerifyModalComponent,
        HomeBannerComponent,
        CountryTabComponent,
        CountryTabDataComponent,
        ApplicationStepsCarouselsComponent,
        DocAccordionComponent,
        AddTravelerComponent,
        StpVAccordionDirective,
        ProductAccordionComponent,
        BlogCarouselComponent,
        InsideErrorComponent,
        ConfirmationComponent,
        NumberDirective,
        PartnersLogoComponent,
        DateAsMonth,
        InsidePageSearchComponent
    ],
    imports: [
        CommonModule,
        CarouselModule,
        NgbAccordionModule,
        NgbNavModule,
        RouterModule,
        NgbModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule
    ],
    providers: [NgbNavConfig, HomeService, GetDataService, ConfirmationService],
    exports: [
        MenuComponent,
        Carousel3by1Component,
        HeaderComponent,
        CardComponent,
        HowToApplyComponent,
        FooterComponent,
        VisaCategoryComponent,
        // CardCarouselComponent,
        FaqComponent,
        UsefulMediaComponent,
        HeaderCtrlCarousel,
        ThreeImageComponent,
        AsideCtrlCarousel,
        PartnersComponent,
        TwoSideImgComponent,
        VerticalTabComponent,
        CountryVerticalTabComponent,
        VisaImgDescriptionComponent,
        ImageCardComponent,
        StampPassportComponent,
        VoucherComponent,
        HomeBannerComponent,
        HrVrTabComponent,
        // TabCarouselComponent,
        // NgbTabCarouselComponent,
        CountryTabComponent,
        CountryTabDataComponent,
        ApplicationStepsCarouselsComponent,
        DocAccordionComponent,
        AddTravelerComponent,
        HomeBannerComponent,
        StpVAccordionDirective,
        BlogCarouselComponent,
        InsideErrorComponent,
        ConfirmationComponent,
        NumberDirective,
        PartnersLogoComponent,
        DateAsMonth,
        InsidePageSearchComponent
    ]
})
export class SharedModule {
}
